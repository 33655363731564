import { externalLinksSchema } from "components/Common/validation"
import CustomField from "components/Shared/CustomField"
import CustomLabel from "components/Shared/CustomLabel"
import FormButtons from "components/Shared/FormButtons"
import TopTitle from "components/Shared/TopTitle"
import { Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import {
  getAllGlobalSettings,
  updateGlobalSettings,
} from "store/actions/globalSettingsAction"
import { showAlert } from "store/reducers/alertReducer"

const initialValues = {
  electricityBillArLink: "",
  electricityBillEnLink: "",
  waterBillArLink: "",
  waterBillEnLink: "",
}

const ExternalLinks = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  document.title = t("External Links | Takween")

  const dispatch = useDispatch()

  const name = useSelector(state => state.Login.userProfile?.name) || ""
  const { loading, isLoading, globalSettings } = useSelector(
    state => state.GlobalSettings
  )

  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    fetchAllGlobalSettings()
  }, [])

  const fetchAllGlobalSettings = () => {
    const payload = {
      type: "externalLinks",
    }
    dispatch(getAllGlobalSettings(payload))
  }

  // Use effect to update the state when globalSettings changes
  useEffect(() => {
    if (globalSettings) {
      console.log(globalSettings)
      const { externalLinks } = globalSettings
      setFormValues({
        electricityBillArLink: externalLinks?.electricityBill?.arLink || "",
        electricityBillEnLink: externalLinks?.electricityBill?.enLink || "",
        waterBillArLink: externalLinks?.waterBill?.arLink || "",
        waterBillEnLink: externalLinks?.waterBill?.enLink || "",
      })
    }
  }, [globalSettings])

  const handleOnSubmit = values => {
    const {
      electricityBillArLink,
      electricityBillEnLink,
      waterBillArLink,
      waterBillEnLink,
    } = values

    const payload = {
      id: globalSettings?._id,
      type: "externalLinks",
      externalLinks: {
        electricityBill: {
          arLink: electricityBillArLink,
          enLink: electricityBillEnLink,
        },
        waterBill: {
          arLink: waterBillArLink,
          enLink: waterBillEnLink,
        },
      },
    }

    console.log(payload)

    dispatch(updateGlobalSettings(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("External Links")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={externalLinksSchema}
                      onSubmit={handleOnSubmit}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Row>
                            <h2 className="fs_18_700">
                              {t("Electricity Details")}
                            </h2>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="electricityBillEnLink"
                                  label={t("English Link")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="electricityBillEnLink"
                                  type="text"
                                  placeholder={t("English Link")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="electricityBillArLink"
                                  label={t("Arabic Link")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="electricityBillArLink"
                                  type="text"
                                  // isArabic={true}
                                  placeholder={t("Arabic Link")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col>
                              <hr />
                            </Col>
                            <h2 className="fs_18_700">{t("Water Details")}</h2>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="waterBillEnLink"
                                  label={t("English Link")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="waterBillEnLink"
                                  type="text"
                                  placeholder={t("English Link")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="waterBillArLink"
                                  label={t("Arabic Link")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="waterBillArLink"
                                  type="text"
                                  // isArabic={true}
                                  placeholder={t("Arabic Link")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <FormButtons
                                buttonText={t("Update")}
                                isLoading={isLoading}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ExternalLinks
