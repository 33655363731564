import React, { useEffect, useState } from "react"
import { CardBody, Col, Row } from "reactstrap"
import Select from "react-select"
import PropTypes from "prop-types"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useTranslation } from "react-i18next"
import calenderIcon from "assets/images/icons/calendar.svg"

const Filters = ({
  isGlobalFilter,
  SearchPlaceholder,
  keyword,
  setKeyword,
  filters,
  colSize = null,
  selectedFilters,
  handleFilterChange,
  isRangeDate = false,
  flatpickrRef,
  dateRange = {},
  setDateRange = {},
  isResetButton = true,
  handleReset = () => {},
  onInputChange = () => {},
}) => {
  const handleSelectChange = filterName => e => {
    handleFilterChange(e.target.value, filterName)
  }
  const {
    t,
    i18n: { language },
  } = useTranslation()
  return (
    <CardBody className="pt-0 pb-3 filters_div">
      <Row className="">
        {isGlobalFilter && (
          <DebouncedInput
            value={keyword ?? ""}
            colSize={colSize}
            onChange={value => setKeyword(value)}
            className="form-control search-box me-2 mb-2 d-inline-block"
            placeholder={t(SearchPlaceholder)}
          />
        )}
        {filters.map((filter, index) => (
          <Col
            key={index}
            xl={colSize ? colSize : null}
            className={colSize ? "mb-3" : null}
          >
            <div className="position-relative">
              {filter.type === "select" && (
                <>
                  <i className="mdi mdi-chevron-down set_right fs-3"></i>
                  <select
                    className="form-select"
                    value={selectedFilters[filter.name]}
                    onChange={handleSelectChange(filter.name)}
                    aria-label={filter.placeholder}
                  >
                    <option hidden>{filter.placeholder}</option>
                    {filter.options.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {filter.type === "multiSelect" && (
                <Select
                  value={selectedFilters[filter.name]}
                  isMulti={true}
                  onChange={selectedOption => {
                    handleFilterChange(selectedOption, filter.name)
                  }}
                  options={filter.options}
                  placeholder={filter.placeholder}
                  className="select2-selection"
                  onInputChange={onInputChange}
                  closeMenuOnSelect={false}
                />
              )}
            </div>
          </Col>
        ))}
        {isRangeDate && (
          <Col
            xl={colSize ? colSize : null}
            className={colSize ? "mb-3" : null}
          >
            <div className="position-relative" style={{ minWidth: 215 }}>
              <img
                src={calenderIcon}
                alt="calender icon"
                className="set_right"
              />
              <Flatpickr
                className="form-control d-block"
                placeholder={t("Date")}
                ref={flatpickrRef}
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                  defaultDate: [dateRange.min, dateRange.max],
                }}
                onChange={selectedDates => {
                  const formattedDates = selectedDates.map(
                    date =>
                      date
                        .toLocaleDateString("en-CA", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })
                        .split("/")
                        .reverse()
                        .join("-") // Format to "yyyy-mm-dd"
                  )
                  const minDate = formattedDates[0]
                  const maxDate = formattedDates[1]
                  setDateRange({
                    min: minDate,
                    max: maxDate,
                  })
                }}
              />
            </div>
          </Col>
        )}
        {isResetButton && (
          <Col style={{ maxWidth: 69 }}>
            <button className="reset_btn g_center" onClick={handleReset}>
              <i className="mdi mdi-reload "></i>
            </button>
          </Col>
        )}
      </Row>
    </CardBody>
  )
}

Filters.propTypes = {
  isGlobalFilter: PropTypes.bool,
  SearchPlaceholder: PropTypes.string,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
}

export default Filters

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  colSize,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <Col xl={colSize ? colSize : null}>
      <input
        {...props}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </Col>
  )
}
