import React, { useEffect, useState } from "react"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import BackButton from "components/Shared/BackButton"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import DetailsTitle from "components/Shared/DetailsTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getMaintenanceUserDetails } from "store/actions/maintenanceUsersAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import FormateDate from "components/Shared/FormateDate"
import classNames from "classnames"
import StarRatings from "react-star-ratings"
import RatingAndFeedback from "./RatingAndFeedback"
import AvailableSlots from "./AssignedSlots"
import UnAvailableSlots from "./UnAvailableSlots"
import AssignedSlots from "./AssignedSlots"
import DeviceInfo from "./DeviceInfo"

const MaintenanceUserDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Maintenance User Details | Takween")

  const dispatch = useDispatch()
  const { loading, maintenanceUserDetails } = useSelector(
    state => state.MaintenanceUsers
  )

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchMaintenanceUserDetails = () => {
      const payload = {
        id,
      }
      dispatch(getMaintenanceUserDetails(payload))
    }
    fetchMaintenanceUserDetails()
  }, [])

  const [activeTab, setActiveTab] = useState(1)

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Maintenance User Details")} />

                  <div>
                    {loading ? (
                      <ShimmerThumbnail
                        height={700}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(maintenanceUserDetails).length === 0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <>
                        <Row className="g_details">
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Id")}
                              value={maintenanceUserDetails._id}
                            />
                          </Col>{" "}
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Name")}
                              value={maintenanceUserDetails.name}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Phone Number")}
                              value={maintenanceUserDetails.phone}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Email")}
                              value={maintenanceUserDetails.email}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("National Id")}
                              value={maintenanceUserDetails.nationalId || "-"}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("National Doc")}
                              value={t("View")}
                              isPdfIcon={true}
                              isLink={true}
                              redirectLink={
                                maintenanceUserDetails.nationalDocUrl || "-"
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Serial Number")}
                              value={maintenanceUserDetails.serialNo || "-"}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Average Rating")}
                              value={
                                <StarRatings
                                  rating={maintenanceUserDetails.avgRating}
                                  starRatedColor="#F1B44C"
                                  starEmptyColor="#74788d"
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="18px"
                                  starSpacing="3px"
                                />
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Service Name")}
                              value={
                                language === "sa"
                                  ? maintenanceUserDetails?.serviceId?.nameAr
                                  : maintenanceUserDetails?.serviceId?.name ||
                                    "-"
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Status")}
                              value={
                                maintenanceUserDetails.status === 1
                                  ? t("Active")
                                  : t("Deactive")
                              }
                              valueClass={
                                maintenanceUserDetails.status === 1
                                  ? "active_color"
                                  : "danger_color"
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Is Nafath Authenticated")}
                              value={
                                maintenanceUserDetails?.isNafathAuthenticated
                                  ? t("Yes")
                                  : t("No")
                              }
                              valueClass={
                                maintenanceUserDetails?.isNafathAuthenticated
                                  ? "active_color"
                                  : "danger_color"
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Last Logged In")}
                              value={
                                FormateDate(
                                  maintenanceUserDetails.lastLoggedIn
                                ) || "-"
                              }
                            />
                          </Col>
                          <div className="mb-4"></div>
                          <hr />
                          <Col md={12}>
                            <h4 className="fs_24">
                              {t("Availability Details")}
                            </h4>
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Start Time")}
                              value={
                                maintenanceUserDetails?.availability
                                  ?.startTime || "-"
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("End Time")}
                              value={
                                maintenanceUserDetails?.availability?.endTime ||
                                "-"
                              }
                            />
                          </Col>
                          <Col md={6}>
                            <DetailsInnerDiv
                              label={t("Days")}
                              value={
                                maintenanceUserDetails?.availability?.days
                                  ?.length === 0
                                  ? "-"
                                  : maintenanceUserDetails?.availability?.days?.map(
                                      (item, index) =>
                                        `${item}${
                                          index ===
                                          maintenanceUserDetails?.availability
                                            ?.days?.length -
                                            1
                                            ? ""
                                            : ", "
                                        } `
                                    ) || "-"
                              }
                            />
                          </Col>
                          <div className="mb-3"></div>
                          <hr />
                          {maintenanceUserDetails?.isNafathAuthenticated && (
                            <>
                              <Col md={12}>
                                <h4 className="fs_24">{t("Nafath Details")}</h4>
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Last Sync From Nafath At")}
                                  value={
                                    FormateDate(
                                      maintenanceUserDetails.lastSyncFromNafathAt
                                    ) || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("First Name")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.firstName || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Second Name")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.secondName || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Third Name")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.thirdName || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Last Name")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.lastName || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Gender")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.gender || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Nationality")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.nationality || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Occupation Code")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.occupationCode || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t(
                                    "Total Number Of Current Dependents"
                                  )}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.totalNumberOfCurrentDependents || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Date Of Birth")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.dateOfBirthG || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Date Of Birth(Hijri)")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.dateOfBirthH || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Family Name")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.familyName || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Grand Father Name")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.grandFatherName || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Father Name")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.fatherName || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Street Name")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.nationalAddress?.streetName || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("City")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.nationalAddress?.city || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("District")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.nationalAddress?.district || "-"
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <DetailsInnerDiv
                                  label={t("Region")}
                                  value={
                                    maintenanceUserDetails?.nafathInfo
                                      ?.nationalAddress?.regionName || "-"
                                  }
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <Nav tabs className="nav-tabs-custom nav-justified px-2">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", padding: 20 }}
                      className={classNames({
                        active: activeTab === 1,
                      })}
                      onClick={() => {
                        toggle(1)
                      }}
                    >
                      <span>{t("Device Information")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", padding: 20 }}
                      className={classNames({
                        active: activeTab === 2,
                      })}
                      onClick={() => {
                        toggle(2)
                      }}
                    >
                      <span>{t("Ratings & Feedback")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", padding: 20 }}
                      className={classNames({
                        active: activeTab === 3,
                      })}
                      onClick={() => {
                        toggle(3)
                      }}
                    >
                      <span>{t("Assigned Slots")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", padding: 20 }}
                      className={classNames({
                        active: activeTab === 4,
                      })}
                      onClick={() => {
                        toggle(4)
                      }}
                    >
                      <span>{t("Unavailability Slots")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", padding: 20 }}
                      className={classNames({
                        active: activeTab === 5,
                      })}
                      onClick={() => {
                        toggle(5)
                      }}
                    >
                      <span>{t("Service Item")}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="text-muted mt-4">
                  <TabPane tabId={1}>
                    <DeviceInfo
                      id={id}
                      t={t}
                      maintenanceUserDetails={maintenanceUserDetails}
                    />
                  </TabPane>
                  <TabPane tabId={2}>
                    <RatingAndFeedback id={id} t={t} />
                  </TabPane>
                  <TabPane tabId={3}>
                    <AssignedSlots
                      id={id}
                      t={t}
                      maintenanceUserDetails={maintenanceUserDetails}
                    />
                  </TabPane>
                  <TabPane tabId={4}>
                    <UnAvailableSlots
                      id={id}
                      t={t}
                      maintenanceUserDetails={maintenanceUserDetails}
                    />
                  </TabPane>
                  <TabPane tabId={5}>
                    {maintenanceUserDetails?.serviceItemIds?.length === 0 ? (
                      <NoDataAvailable />
                    ) : (
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead className="full_th">
                            <tr>
                              <th>{t("#")}</th>
                              <th>{t("Name(English)")}</th>
                              <th>{t("Name(Arabic)")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {maintenanceUserDetails?.serviceItemIds?.map(
                              (serviceItem, index) => (
                                <tr key={serviceItem._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{serviceItem?.name}</td>
                                  <td>{serviceItem?.nameAr}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MaintenanceUserDetails
