import React from "react"
import logoLightPng from "../../assets/images/large-logo.svg"
import { Link } from "react-router-dom"

const PublicNavbar = () => {
  return (
    <div className="public_header">
      <Link to="/">
        <img src={logoLightPng} alt="Takween" width={180} height={52} />
      </Link>
    </div>
  )
}

export default PublicNavbar
