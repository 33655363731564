import { getImagePreview } from "components/Common/Utils"
import React from "react"

const VideoPreview = ({ url }) => {
  const previewUrl = getImagePreview(url)

  if (!previewUrl) return null

  return (
    <video
      src={previewUrl}
      className="img-thumbnail video-preview"
      controls
      width="200"
    >
      Your browser does not support the video tag.
    </video>
  )
}

export default VideoPreview
