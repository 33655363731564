import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import DetailsTitle from "components/Shared/DetailsTitle"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import FormateDate from "components/Shared/FormateDate"
import TopTitle from "components/Shared/TopTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import { getCustomerDetails } from "store/actions/customersAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { useLocation } from "react-router-dom"
import CustomerRegisteredInterest from "./CustomerDetails/CustomerRegisteredInterest"
import CustomerPaymentHistory from "./CustomerDetails/CustomerPaymentHistory"

const CustomerDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Customer Details | Takween")

  const dispatch = useDispatch()
  const { loading, customerDetails } = useSelector(state => state.Customers)
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  const [activeTab, setActiveTab] = useState(1)

  useEffect(() => {
    const fetchUserDetails = () => {
      const payload = {
        id,
      }
      dispatch(getCustomerDetails(payload))
    }
    fetchUserDetails()
  }, [])

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content customer_details">
        <Container fluid>
          <TopTitle
            title={t("Customer")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Customer service Portal!`,
              {
                name: name,
              }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Customer Details")} />
                  <div>
                    {loading ? (
                      <ShimmerThumbnail
                        height={500}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(customerDetails).length === 0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <>
                        <Row className="g_details">
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Id")}
                              value={customerDetails._id}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Customer Name")}
                              value={customerDetails.name || "-"}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("National Id")}
                              value={customerDetails.nationalId || "-"}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Customer Phone Number")}
                              value={customerDetails.phone || "-"}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Is Phone Number Verified")}
                              value={
                                customerDetails.isPhoneNoVerified
                                  ? t("Yes")
                                  : t("No")
                              }
                              valueClass={
                                customerDetails.isPhoneNoVerified
                                  ? "active_color"
                                  : "danger_color"
                              }
                            />
                          </Col>
                          {/* <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Is Email Verified")}
                              value={
                                customerDetails.isEmailVerified
                                  ? t("Yes")
                                  : t("No")
                              }
                              valueClass={
                                customerDetails.isEmailVerified
                                  ? "active_color"
                                  : "danger_color"
                              }
                            />
                          </Col> */}
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Status")}
                              value={
                                customerDetails.status === 1
                                  ? t("Active")
                                  : t("Deactive")
                              }
                              valueClass={
                                customerDetails.status === 1
                                  ? "active_color"
                                  : "danger_color"
                              }
                            />
                          </Col>
                          {/* <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Session Id")}
                              value={customerDetails.sessionId || "-"}
                            />
                          </Col> */}
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Referral Code")}
                              value={customerDetails.referralCode || "-"}
                            />
                          </Col>
                          {/* {customerDetails?.nationalDocUrl && (
                            <Col md={3}>
                              <DetailsInnerDiv
                                label={t("National Doc Url")}
                                value={t("View")}
                                isPdfIcon={true}
                                isLink={true}
                                redirectLink={customerDetails?.nationalDocUrl}
                              />
                            </Col>
                          )} */}
                          {customerDetails?.profileUrl && (
                            <Col md={3}>
                              <DetailsInnerDiv
                                label={t("Profile Url")}
                                value={t("View")}
                                isPdfIcon={true}
                                isLink={true}
                                redirectLink={customerDetails?.profileUrl}
                              />
                            </Col>
                          )}
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Join Date")}
                              value={FormateDate(customerDetails.createdAt)}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Updated At")}
                              value={FormateDate(customerDetails.updatedAt)}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Last Logged In")}
                              value={FormateDate(customerDetails.lastLoggedIn)}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            {loading ? null : (
              <>
                <Col lg={12}>
                  <Nav pills className="navtab-bg custom_nav_2">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={activeTab === 1 ? "active" : ""}
                        onClick={() => {
                          toggle(1)
                        }}
                      >
                        <span>{t("Device Information")}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={activeTab === 2 ? "active" : ""}
                        onClick={() => {
                          toggle(2)
                        }}
                      >
                        <span>{t("Registered Interests")}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={activeTab === 3 ? "active" : ""}
                        onClick={() => {
                          toggle(3)
                        }}
                      >
                        <span>{t("Payment History")}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col lg={12}>
                  <TabContent
                    activeTab={activeTab}
                    className="text-muted bg-white mt-3 b_r_12"
                    style={{ maxHeight: 500, overflowY: "auto" }}
                  >
                    <TabPane tabId={1}>
                      {/* {customerDetails?.deviceInfos?.length === 0 ? ( */}
                      {/* <NoDataAvailable />
                      ) : ( */}
                      <Table className="table mb-0 table_corner_radius">
                        <thead className="full_th">
                          <tr>
                            <th>{t("Device Id")}</th>
                            <th>{t("Language")}</th>
                            <th>{t("FCM Token")}</th>
                            <th>{t("App Version")}</th>
                            <th>{t("OS Version")}</th>
                            <th>{t("Platform")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {customerDetails?.deviceInfo?.deviceId || "-"}
                            </td>
                            <td>
                              {customerDetails?.deviceInfo?.language || "-"}
                            </td>
                            <td>
                              {customerDetails?.deviceInfo?.fcmToken || "-"}
                            </td>
                            <td>
                              {customerDetails?.deviceInfo?.appVersion || "-"}
                            </td>
                            <td>
                              {customerDetails?.deviceInfo?.osVersion || "-"}
                            </td>
                            <td>
                              {customerDetails?.deviceInfo?.platform || "-"}
                            </td>
                          </tr>
                        </tbody>
                        {/* <tbody>
                            {customerDetails?.deviceInfos?.map(
                              (item, index) => (
                                <tr key={item._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{item.deviceId || "-"}</td>
                                  <td>{item.language || "-"}</td>
                                  <td>{item.appVersion || "-"}</td>
                                  <td>{item.osVersion || "-"}</td>
                                  <td>{item.platform || "-"}</td>
                                </tr>
                              )
                            )}
                          </tbody> */}
                      </Table>
                      {/* )} */}
                    </TabPane>
                    <TabPane tabId={2}>
                      <CustomerRegisteredInterest
                        id={id}
                        activeTab={activeTab}
                      />
                    </TabPane>
                    <TabPane tabId={3}>
                      <CustomerPaymentHistory id={id} activeTab={activeTab} />
                      {/* {customerDetails?.paymentHistory?.length === 0 ? (
                    <NoDataAvailable />
                  ) : (
                    <Table className="table mb-0 table_corner_radius">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Project Name")}</th>
                          <th>{t("Unit Name")}</th>
                          <th>{t("Payment Method")}</th>
                          <th>{t("Date")}</th>
                          <th>{t("Status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerDetails?.paymentHistory?.map((item, index) => (
                          <tr key={item._id}>
                            <th scope="row">{index + 1}</th>
                            <td>{item.projectName}</td>
                            <td>{item.unit}</td>
                            <td>{item.paymentMethod}</td>
                            <td>{FormateDate(item.date)}</td>
                            <td>
                              {" "}
                              {item.status === 1 ? (
                                <p className="rended_status status_tag">
                                  {t("Completed")}
                                </p>
                              ) : item.status === 2 ? (
                                <p className="reject_status status_tag">
                                  {t("Failed")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )} */}
                    </TabPane>
                  </TabContent>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomerDetails
