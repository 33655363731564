import { createSlice } from "@reduxjs/toolkit"
import {
  deleteAdminNotification,
  deleteAllAdminNotification,
  getAdminNotificationDetails,
  getAdminNotificationList,
  getAdminNotifications,
  markAdminAllNotificationAsRead,
  markAdminNotificationAsRead,
} from "store/actions/adminNotificationAction"

const initialState = {
  loading: true,
  loadingList: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  totalReadCount: null,
  currentPage: null,
  adminNotifications: [],
  adminNotificationDetails: {},
  isDeletingAll: false,
  isReadingAll: false,
  notificationsList: [],
}
const adminNotificationsSlice = createSlice({
  name: "admin notification",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAdminNotifications.pending, state => {
        state.loading = true
      })
      .addCase(getAdminNotifications.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.adminNotifications = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAdminNotifications.rejected, (state, action) => {
        state.loading = false
      })

    // GET NAVBAR NOTIFICATION
    builder
      .addCase(getAdminNotificationList.pending, state => {
        state.loadingList = true
      })
      .addCase(getAdminNotificationList.fulfilled, (state, action) => {
        state.loadingList = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage, totalReadCount } =
            action.payload
          state.notificationsList = data
          // state.totalCount = totalCount
          // state.totalPages = totalPages
          // state.currentPage = currentPage
          state.totalReadCount = totalReadCount
        }
      })
      .addCase(getAdminNotificationList.rejected, (state, action) => {
        state.loadingList = false
      })

    // GET DETAILS
    builder
      .addCase(getAdminNotificationDetails.pending, state => {
        state.loading = true
        state.adminNotificationDetails = {}
      })
      .addCase(getAdminNotificationDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.adminNotificationDetails = data
        }
      })
      .addCase(getAdminNotificationDetails.rejected, (state, action) => {
        state.loading = false
      })

    // MARK AS READ
    builder
      .addCase(markAdminNotificationAsRead.pending, state => {})
      .addCase(markAdminNotificationAsRead.fulfilled, (state, action) => {
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.adminNotifications = state?.adminNotifications?.map(item =>
            item._id === id ? { ...item, status: 1 } : item
          )
          state.totalReadCount -= 1
        }
      })
      .addCase(markAdminNotificationAsRead.rejected, (state, action) => {})

    // MARK ALL AS READ
    builder
      .addCase(markAdminAllNotificationAsRead.pending, state => {
        state.isReadingAll = true
      })
      .addCase(markAdminAllNotificationAsRead.fulfilled, (state, action) => {
        state.isReadingAll = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.adminNotifications = state?.adminNotifications?.map(item => ({
            ...item,
            status: 1,
          }))
          state.totalReadCount = 0
        }
      })
      .addCase(markAdminAllNotificationAsRead.rejected, (state, action) => {
        state.isReadingAll = true
      })

    // DELETE
    builder
      .addCase(deleteAdminNotification.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteAdminNotification.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg

          // Find the notification by ID
          const notificationToDelete = state.adminNotifications.find(
            item => item._id === id
          )

          // Check the status and decrement totalReadCount if status is 0
          if (notificationToDelete && notificationToDelete.status === 0) {
            if (state.totalReadCount !== null) {
              state.totalReadCount -= 1
            }
          }
          // Remove the notification from the array
          state.adminNotifications = state.adminNotifications.filter(
            item => item._id !== id
          )

          // Decrement totalCount
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteAdminNotification.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE ALL
    builder
      .addCase(deleteAllAdminNotification.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteAllAdminNotification.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          state.adminNotifications = []
          state.totalCount = null
          state.totalPages = null
          state.currentPage = null
          state.totalReadCount = 0
        }
      })
      .addCase(deleteAllAdminNotification.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default adminNotificationsSlice.reducer
