import { createSlice } from "@reduxjs/toolkit"
import {
  getAllCustomersInterest,
  getCustomerInterestDetails,
} from "store/actions/customerInterestAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  customersInterest: [],
  customerInterestDetails: {}, //
}
const customerInterestSlice = createSlice({
  name: "common", //
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllCustomersInterest.pending, state => {
        state.loading = true
      })
      .addCase(getAllCustomersInterest.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.customersInterest = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllCustomersInterest.rejected, (state, action) => {
        state.loading = false
      })

    // GET DETAILS
    builder
      .addCase(getCustomerInterestDetails.pending, state => {
        state.isLoading = true
        state.customerInterestDetails = {}
      })
      .addCase(getCustomerInterestDetails.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.customerInterestDetails = data
        }
      })
      .addCase(getCustomerInterestDetails.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default customerInterestSlice.reducer
