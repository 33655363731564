import React, { useEffect, useState } from "react"
import { Button, Card, Col, Container, Modal, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import {
  baseFilterOptions,
  commonFilterOptions,
} from "components/Common/constants"
import SinglePartner from "./SinglePartner"
import DeleteModal from "components/Common/DeleteModal"
import {
  getDateRange,
  handleFileUpload,
  handleSquareFileUpload,
} from "components/Common/Utils"
import { ErrorMessage, Form, Formik } from "formik"
import ImgPreview from "components/Shared/ImgPreview"
import { createPartnerSchema } from "components/Common/validation"
import TopTitle from "components/Shared/TopTitle"
import Paginations from "components/Common/Pagination"
import CustomLabel from "components/Shared/CustomLabel"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import GalleryIcon from "assets/images/icons/gallery.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  createPartner,
  deletePartner,
  getAllPartners,
  getPartnerDetails,
  updatePartner,
  updatePartnerStatus,
} from "store/actions/partnersAction"
import Spinners from "components/Shared/Spinners"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import { showAlert } from "store/reducers/alertReducer"
import IsLoading from "components/Shared/IsLoading"
import FileUpload from "components/Shared/FileUpload"
import { partnerUploadMaxSize } from "components/Common/FileUploadSizeUtils"

const Partners = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Partners | Takween")

  const [isModal, setIsModal] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [image, setImage] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    isGettingDetails,
    partners,
    partnerDetails,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.Partners)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  useEffect(() => {
    if (partnerDetails && isModal && modalType === "UPDATE") {
      const { imageUrl } = partnerDetails
      setImage(imageUrl)
    } else {
      setImage("")
    }
  }, [partnerDetails, isModal])

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
  })

  useEffect(() => {
    fetchAllPartners()
  }, [])

  const fetchAllPartners = page => {
    const { status, orderBy } = filters
    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      keyword: "",
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllPartners(payload))
    setIsApiCalled(true)

    navigate(`/partners?page=${page || pageNumber}`)
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  // GET PARTNER DETAILS
  useEffect(() => {
    isModal && modalType === "UPDATE" && fetchPartnerDetails()
  }, [isModal])

  const fetchPartnerDetails = () => {
    const payload = {
      id: selectedId,
    }
    dispatch(getPartnerDetails(payload))
  }

  // SET PARTNER IMAGE
  useEffect(() => {
    // imageUrl
    if (partnerDetails) {
      const { imageUrl } = partnerDetails
      setImage(imageUrl)
    }
  }, [partnerDetails])

  const handleSearch = () => {
    fetchAllPartners(1)
  }

  const onPageChange = page => {
    fetchAllPartners(page)
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deletePartner(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleOnSubmit = (values, { resetForm }) => {
    const { image } = values
    let formData = new FormData()
    formData.append("image", image)

    if (modalType === "CREATE") {
      dispatch(createPartner(formData))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            setIsModal(false)
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (modalType === "UPDATE") {
      formData.append("id", selectedId)
      dispatch(updatePartner(formData))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            setIsModal(false)
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 1 ? "0" : "1",
    }
    dispatch(updatePartnerStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Partners")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
            createBtnText={t("Create Partner")}
            onCreateBtnClick={() => {
              setModalType("CREATE")
              setIsModal(true)
            }}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Partners List")} />
                <Filters
                  isGlobalFilter={false}
                  filters={commonFilterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            {loading ? (
              <Spinners />
            ) : partners.length === 0 ? (
              <NoDataAvailable />
            ) : (
              partners.map(item => (
                <SinglePartner
                  key={item.id}
                  item={item}
                  t={t}
                  handleStatusClick={handleStatusClick}
                  isLoading={isLoading}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  setShowDeleteModal={setShowDeleteModal}
                  setIsModal={setIsModal}
                  setModalType={setModalType}
                  setItemIdToDelete={setItemIdToDelete}
                />
              ))
            )}
            {!loading && partners.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("Partner"),
        })}
      />
      <Modal
        isOpen={isModal}
        toggle={() => {
          setIsModal(!isModal)
        }}
        className="g_modal w_600"
        centered
      >
        <h2 className="modal_title">
          {modalType === "CREATE" ? t("Create Partner") : t("Update Partner")}
        </h2>
        <button
          type="button"
          onClick={() => {
            setIsModal(!isModal)
          }}
          className="modal_close_btn"
        >
          <i className="mdi mdi-close"></i>
        </button>
        {modalType === "UPDATE" && isGettingDetails ? (
          <IsLoading />
        ) : (
          <div>
            <Formik
              initialValues={{ image }}
              onSubmit={handleOnSubmit}
              validationSchema={createPartnerSchema}
              enableReinitialize={true}
            >
              {({ setFieldValue, touched, errors, values }) => (
                <Form>
                  <Col lg={12}>
                    <div className="form_field">
                      <CustomLabel
                        htmlFor="image"
                        label={t("Upload Image")}
                        isRequired={true}
                      />
                      <FileUpload
                        name="image"
                        value={values.image}
                        touched={touched.image}
                        error={errors.image}
                        labelText={t("Upload Image")}
                        setFieldValue={setFieldValue}
                        handleFileUpload={event =>
                          handleSquareFileUpload(
                            event,
                            setFieldValue,
                            "image",
                            true,
                            partnerUploadMaxSize
                          )
                        }
                      />
                      <CustomErrorMessage name={"image"} />
                      <p className="fs_14_500 opacity-75">
                        {t(
                          "Recommended Image Size: Square (e.g., 540x540 px). Max size: {{maxSize}} KB",
                          { maxSize: partnerUploadMaxSize }
                        )}{" "}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <button
                      className="save_btn mt-4 ms-auto d-block"
                      type="submit"
                      disabled={isLoading}
                    >
                      {modalType === "CREATE" ? t("Create") : t("Update")}
                    </button>
                  </Col>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default Partners
