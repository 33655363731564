import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  text: "",
  type: "",
  isVisible: false,
}

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert(state, action) {
      const { text, type } = action.payload
      state.text = text
      state.type = type
      state.isVisible = true
    },
    hideAlert(state) {
      state.isVisible = false
    },
  },
})

export const { showAlert, hideAlert } = alertSlice.actions

export default alertSlice.reducer
