import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import {
  ADMIN_NOTIFICATION_DETAILS,
  ADMIN_NOTIFICATION_LIST,
  ADMIN_NOTIFICATIONS,
  DELETE_ADMIN_NOTIFICATION,
  DELETE_ALL_ADMIN_NOTIFICATIONS,
  MARK_ALL_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ,
} from "helpers/url_helper"

export const getAdminNotifications = createAsyncThunk(
  ADMIN_NOTIFICATIONS,
  async payload => {
    const response = await post(ADMIN_NOTIFICATIONS, payload)
    return response
  }
)

// FOR HEADER NOTIFICATION
export const getAdminNotificationList = createAsyncThunk(
  ADMIN_NOTIFICATION_LIST,
  async payload => {
    const response = await post(ADMIN_NOTIFICATION_LIST, payload)
    return response
  }
)

export const getAdminNotificationDetails = createAsyncThunk(
  ADMIN_NOTIFICATION_DETAILS,
  async payload => {
    const response = await post(ADMIN_NOTIFICATION_DETAILS, payload)
    return response
  }
)

export const deleteAdminNotification = createAsyncThunk(
  DELETE_ADMIN_NOTIFICATION,
  async payload => {
    const response = await del(DELETE_ADMIN_NOTIFICATION, payload)
    return response
  }
)

export const deleteAllAdminNotification = createAsyncThunk(
  DELETE_ALL_ADMIN_NOTIFICATIONS,
  async payload => {
    const response = await del(DELETE_ALL_ADMIN_NOTIFICATIONS, payload)
    return response
  }
)

export const markAdminNotificationAsRead = createAsyncThunk(
  MARK_NOTIFICATION_AS_READ,
  async payload => {
    const response = await patch(MARK_NOTIFICATION_AS_READ, payload)
    return response
  }
)

export const markAdminAllNotificationAsRead = createAsyncThunk(
  MARK_ALL_NOTIFICATION_AS_READ,
  async payload => {
    const response = await patch(MARK_ALL_NOTIFICATION_AS_READ, payload)
    return response
  }
)
