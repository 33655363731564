import React, { useEffect, useState } from "react"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import CustomField from "components/Shared/CustomField"
import CustomLabel from "components/Shared/CustomLabel"
import Spinners from "components/Shared/Spinners"
import TopTitle from "components/Shared/TopTitle"
import { Field, Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import FormButtons from "components/Shared/FormButtons"
import { createFaqSchema } from "components/Common/validation"
import { showAlert } from "store/reducers/alertReducer"

import {
  createFaq,
  getFaqDetails,
  updateFaq,
} from "store/actions/contentManagementAction"
import BackButton from "components/Shared/BackButton"

const createFaqValues = {
  title: "",
  titleAr: "",
  description: "",
  descriptionAr: "",
}
const CreateFaq = ({ mode }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title =
    mode === "create" ? t("Create FAQ | Takween") : t("Update FAQ | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { loading, isLoading, faqDetails } = useSelector(
    state => state.ContentManagements
  )

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [formValues, setFormValues] = useState(createFaqValues)

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getFaqDetails(payload))
    }
    mode === "update" && fetchDetails()
  }, [])

  useEffect(() => {
    if (faqDetails && mode === "update") {
      const { title, titleAr, description, descriptionAr } = faqDetails

      setFormValues(prev => ({
        title,
        titleAr,
        description,
        descriptionAr,
      }))
    }
  }, [mode, faqDetails])

  const handleOnSubmit = values => {
    if (mode === "create") {
      dispatch(createFaq(values))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/faq")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (mode === "update") {
      const updatedValues = { ...values, id }
      dispatch(updateFaq(updatedValues))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/faq")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {mode === "update" && loading ? (
          <Spinners />
        ) : (
          <Container fluid>
            <TopTitle
              title={t("FAQ")}
              subTitle={t(
                `Hi, {{name}}. Welcome back to your Marketing portal!`,
                { name: name }
              )}
            />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h2 className="fs_18_700 g_start">
                      <BackButton />
                      {mode === "create" ? t("Create FAQ") : t("Update FAQ")}
                    </h2>
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={createFaqSchema}
                      onSubmit={handleOnSubmit}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="title"
                                  label={t("Title(English)")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="title"
                                  type="text"
                                  placeholder={t("Title(English)")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="titleAr"
                                  label={t("Title(عربي)")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="titleAr"
                                  type="text"
                                  isArabic={true}
                                  placeholder={t("Title(عربي)")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="description"
                                  label={t("Description(English)")}
                                  isRequired={true}
                                />
                                <Field
                                  as="textarea"
                                  id="description"
                                  name="description"
                                  rows={5}
                                  placeholder={t("Description(English)")}
                                  className={`form-control b_r_12 ${
                                    errors.description && touched.description
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"description"} />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="descriptionAr"
                                  label={t("Description(عربي)")}
                                  isRequired={true}
                                />
                                <Field
                                  as="textarea"
                                  id="descriptionAr"
                                  name="descriptionAr"
                                  rows={5}
                                  placeholder={t("Description(عربي)")}
                                  className={`form-control b_r_12 ${
                                    errors.descriptionAr &&
                                    touched.descriptionAr
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"descriptionAr"} />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <FormButtons
                                buttonText={
                                  mode === "create" ? t("Create") : t("Update")
                                }
                                isLoading={isLoading}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default CreateFaq
