import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"

//redux
import { useNavigate } from "react-router-dom"
import { logout } from "store/actions/authAction"
import { useDispatch } from "react-redux"
import { showAlert } from "store/reducers/alertReducer"
import Cookies from "js-cookie"
import Spinners from "components/Shared/Spinners"

const Logout = () => {
  const history = useNavigate()
  const dispatch = useDispatch()

  const handleLogout = () => {
    const payload = {
      deviceId: Cookies.get("uniqueId") || "",
    }

    dispatch(logout(payload))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          localStorage.removeItem("authUser")
          localStorage.removeItem("isLoggedIn")
          localStorage.removeItem("accountType")
          localStorage.removeItem("isSentFCMToken")
          history("/login")
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(err => {})
  }

  useEffect(() => {
    handleLogout()
  }, [])

  return (
    <>
      <Spinners />
    </>
  )
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
