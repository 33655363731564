import React, { useEffect } from "react"
import { useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"
import ColorPicker from "components/Shared/ColorPicker"
import TopTitle from "components/Shared/TopTitle"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Switch from "components/Shared/Switch"
import { Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import CustomField from "components/Shared/CustomField"
import {
  bannerDurationSchema,
  contactInfoSchema,
} from "components/Common/validation"
import {
  getAllGlobalSettings,
  updateGlobalSettings,
} from "store/actions/globalSettingsAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { showAlert } from "store/reducers/alertReducer"

const contactInfoValues = { whatsappNumber: "", phoneNumber: "", email: "" }

const AppLookAndFeel = () => {
  
  const {
    t,
    i18n: { language },
  } = useTranslation()
  
  document.title = t("App Look And Feel | Takween")
  
  const dispatch = useDispatch()

  const name = useSelector(state => state.Login.userProfile?.name) || ""
  const { loading, isLoading, globalSettings } = useSelector(
    state => state.GlobalSettings
  )

  const defaultPrimaryColor = "#0C3553"
  const defaultSecondaryColor = "#D5B086"
  const defaultTextColor = "#393939"

  const [primaryColor, setPrimaryColor] = useState(defaultPrimaryColor)
  const [secondaryColor, setSecondaryColor] = useState(defaultSecondaryColor)
  const [textColor, setTextColor] = useState(defaultTextColor)

  const [maintenanceMode, setMaintenanceMode] = useState({
    iOSApp: true,
    androidApp: true,
    website: true,
  })

  const [formValues, setFormValues] = useState(contactInfoValues)

  const [autoScrollInSeconds, setAutoScrollInSeconds] = useState(null)

  useEffect(() => {
    fetchAllGlobalSettings()
  }, [])

  const fetchAllGlobalSettings = () => {
    const payload = {
      type: "all", //all,appTheme,maintenanceMode,homeScreen,contactInfo
    }
    dispatch(getAllGlobalSettings(payload))
  }

  // Use effect to update the state when globalSettings changes
  useEffect(() => {
    if (globalSettings) {
      // Update colors
      setPrimaryColor(globalSettings.appTheme?.primaryColor)
      setSecondaryColor(globalSettings.appTheme?.secondaryColor)
      setTextColor(globalSettings.appTheme?.textColor)

      // Update maintenance mode (with safeguard)
      setMaintenanceMode(
        globalSettings.maintenanceMode || {
          iOSApp: true,
          androidApp: true,
          website: true,
        }
      )

      // Update contact info
      setFormValues({
        whatsappNumber: globalSettings.contactInfo?.whatsappNumber || "",
        phoneNumber: globalSettings.contactInfo?.phoneNumber || "",
        email: globalSettings.contactInfo?.email || "",
      })

      // Update auto-scroll seconds
      setAutoScrollInSeconds(
        globalSettings.homeScreen?.banner?.autoScrollInSeconds || null
      )
    }
  }, [globalSettings])

  const handleMaintenanceModeChange = platform => {
    setMaintenanceMode(prevState => ({
      ...prevState,
      [platform]: !prevState[platform],
    }))
  }

  const commonGlobalSettings = (payload, isReset = false) => {
    dispatch(updateGlobalSettings(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          if (isReset) {
            setPrimaryColor(defaultPrimaryColor)
            setSecondaryColor(defaultSecondaryColor)
            setTextColor(defaultTextColor)
          }
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleAppTheme = () => {
    const payload = {
      id: globalSettings?._id,
      type: "appTheme", //appTheme,maintenanceMode,homeScreen,contactInfo
      appTheme: {
        primaryColor,
        secondaryColor,
        textColor,
      },
    }
    commonGlobalSettings(payload)
  }
  const handleResetAppTheme = () => {
    const payload = {
      id: globalSettings?._id,
      type: "appTheme",
      appTheme: {
        primaryColor: defaultPrimaryColor,
        secondaryColor: defaultSecondaryColor,
        textColor: defaultTextColor,
      },
    }
    commonGlobalSettings(payload, true) // Payload, IsReset
  }

  const handleMaintenanceModeSave = () => {
    const payload = {
      id: globalSettings?._id,
      type: "maintenanceMode",
      maintenanceMode,
    }
    commonGlobalSettings(payload)
  }

  const handleContactInfo = values => {
    const payload = {
      id: globalSettings?._id,
      type: "contactInfo",
      contactInfo: values,
    }
    commonGlobalSettings(payload)
  }

  const handleBannerDuration = values => {
    const { autoScrollInSeconds } = values
    const payload = {
      id: globalSettings?._id,
      type: "homeScreen",
      homeScreen: {
        banner: {
          autoScrollInSeconds,
        },
      },
    }
    commonGlobalSettings(payload)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("App Look & Feel")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          {loading ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={200} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={200} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row className="app_look_fill_row">
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h2 className="fs_18_700">{t("App Theme")}</h2>
                    <Row>
                      <Col lg={4}>
                        <ColorPicker
                          label={t("Primary Color")}
                          color={primaryColor}
                          onChange={color => setPrimaryColor(color.hex)}
                        />
                      </Col>
                      <Col lg={4}>
                        <ColorPicker
                          label={t("Secondary Color")}
                          color={secondaryColor}
                          onChange={color => setSecondaryColor(color.hex)}
                        />
                      </Col>
                      <Col lg={4}>
                        <ColorPicker
                          label={t("Text Color")}
                          color={textColor}
                          onChange={color => setTextColor(color.hex)}
                        />
                      </Col>
                      <Col lg={12}>
                        <div className="mt-4 d-flex align-items-center justify-content-end gap-3">
                          <button
                            className="cancel_btn d-block"
                            type="submit"
                            onClick={handleResetAppTheme}
                            disabled={isLoading}
                          >
                            {t("Reset")}
                          </button>
                          <button
                            className="primary_btn d-block"
                            type="submit"
                            onClick={handleAppTheme}
                            disabled={isLoading}
                          >
                            {t("Save")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6} className="maintenance_mode">
                <Card className="full_height_card">
                  <CardBody>
                    <Row>
                      <h2 className="fs_18_700">{t("Maintenance Mode")}</h2>
                      <Col lg={12}>
                        <div className="g_between">
                          <label className="fw-bold text-primary font-size-16">
                            {t("iOS App")}
                          </label>
                          <Switch
                            onClickHandler={() =>
                              handleMaintenanceModeChange("iOSApp")
                            }
                            isChecked={maintenanceMode.iOSApp}
                          />
                        </div>
                        <hr className="hr_100" />
                      </Col>
                      <Col lg={12}>
                        <div className="g_between">
                          <label className="fw-bold text-primary font-size-16">
                            {t("Android App")}
                          </label>
                          <Switch
                            onClickHandler={() =>
                              handleMaintenanceModeChange("androidApp")
                            }
                            isChecked={maintenanceMode.androidApp}
                          />
                        </div>
                        <hr className="hr_100" />
                      </Col>
                      <Col lg={12}>
                        <div className="g_between">
                          <label className="fw-bold text-primary font-size-16">
                            {t("Website")}
                          </label>
                          <Switch
                            onClickHandler={() =>
                              handleMaintenanceModeChange("website")
                            }
                            isChecked={maintenanceMode.website}
                          />
                        </div>
                        <hr className="hr_100" />
                      </Col>
                      <div>
                        <button
                          className="primary_btn d-block ms-auto mt-2"
                          type="submit"
                          onClick={handleMaintenanceModeSave}
                          disabled={isLoading}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <Row>
                      <h2 className="fs_18_700">{t("Contact Info")}</h2>
                    </Row>
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={contactInfoSchema}
                      onSubmit={handleContactInfo}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Row>
                            <Col lg={12}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="whatsappNumber"
                                  label={t("WhatsApp Number")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="whatsappNumber"
                                  type="text"
                                  placeholder={t("WhatsApp Number")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="phoneNumber"
                                  label={t("Phone Number")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="phoneNumber"
                                  type="text"
                                  placeholder={t("Phone Number")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="email"
                                  label={t("Email Address")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="email"
                                  type="email"
                                  placeholder={t("Email Address")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mt-2">
                                <button
                                  className="primary_btn d-block ms-auto"
                                  type="submit"
                                  disabled={isLoading}
                                >
                                  {t("Save")}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <Row>
                      <h2 className="fs_18_700">
                        {t("Home Banner Auto Scroll")}
                      </h2>
                      <Formik
                        initialValues={{ autoScrollInSeconds }}
                        enableReinitialize={true}
                        validationSchema={bannerDurationSchema}
                        onSubmit={handleBannerDuration}
                      >
                        {({ values, errors, touched, setFieldValue }) => (
                          <Form>
                            <Row>
                              <Col lg={12}>
                                <div className="form_field">
                                  <CustomLabel
                                    htmlFor="autoScrollInSeconds"
                                    label={t("Duration (In Seconds)")}
                                    isRequired={true}
                                  />
                                  <CustomField
                                    name="autoScrollInSeconds"
                                    type="number"
                                    placeholder={t("Duration (In Seconds)")}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="mt-2">
                                  <button
                                    className="primary_btn d-block ms-auto"
                                    type="submit"
                                    disabled={isLoading}
                                  >
                                    {t("Save")}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AppLookAndFeel
