import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
// Import scss
import "./assets/scss/theme.scss"
import "./custom.scss"
import "./global.scss"
import "./custom-rtl.scss"

import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route, useNavigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import SweatAlert from "components/Shared/SweatAlert"
import { getToken, onMessage } from "firebase/messaging"
import { messaging } from "./firebaseConfig"

import { v4 as uuidv4 } from "uuid"
import Cookies from "js-cookie"
import { changeDefaultLanguage } from "store/actions/authAction"
import { getAdminNotificationList } from "store/actions/adminNotificationAction"

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const Layout = getLayout(layoutType)
  const { isVisible, text, type } = useSelector(state => state.Alert)
  const { isLoggedIn } = useSelector(state => state.Login)

  useEffect(() => {
    const env = process.env.REACT_APP_ENV
    localStorage.setItem("env", env)
  }, [])

  const [permissionState, setPermissionState] = useState(
    typeof window !== "undefined" && "Notification" in window
      ? Notification.permission
      : "default"
  )

  useEffect(() => {
    const localStorageKey = "fcmToken"
    let fcmToken = localStorage.getItem(localStorageKey)

    const requestPermissionAndToken = async () => {
      console.log("Current permission state:", permissionState)

      if (permissionState === "default") {
        const permission = await Notification.requestPermission()
        setPermissionState(permission)
        if (permission === "granted") {
          await getTokenAndSet()
        }
      } else if (permissionState === "granted") {
        await getTokenAndSet()
      }
    }

    const getTokenAndSet = async () => {
      try {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        })

        if (token) {
          const isSentFCMToken = JSON.parse(
            localStorage.getItem("isSentFCMToken")
          )
          console.log("FCM Token:", token)
          localStorage.setItem(localStorageKey, token)
          if (!isSentFCMToken) {
            updateToken()
          }
          // Send token to the server to save for future notifications
        } else {
          console.log("No registration token available.")
        }
      } catch (error) {
        console.error("Error getting token:", error)
      }
    }

    !fcmToken && requestPermissionAndToken()

    const handlePermissionChange = () => {
      setPermissionState(Notification.permission)
    }

    // Check for permission changes every second
    const intervalId = setInterval(handlePermissionChange, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [permissionState])

  // UPDATE FCM TOKEN IF NOT SENT
  const updateToken = () => {
    const payload = {
      deviceId: Cookies.get("uniqueId") || "",
      language: localStorage.getItem("I18N_LANGUAGE") || "en",
      fcmToken: localStorage.getItem("fcmToken"),
      platform: "website",
    }
    dispatch(changeDefaultLanguage(payload))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          localStorage.setItem("isSentFCMToken", "true")
        }
      })
      .catch(err => {})
  }

  useEffect(() => {
    // Listen for messages while the app is open (foreground notifications)
    const unsubscribe = onMessage(messaging, payload => {
      console.log("Message received (Foreground):", payload)
      try {
        const isLoggedIn = localStorage.getItem("isLoggedIn") === "true"
        isLoggedIn && fetchAllNotifications()
        const { title, body, icon } = payload.data || {}
        const { actionType, bookingId, requestNo } = payload.data || {}

        // Display the notification
        const notification = new Notification(title, {
          body,
          icon,
        })

        // Map actionType to routes
        const routes = {
          23: `/rett-certificate?keyword=${bookingId}`,
          24: `/payment-contracts?keyword=${bookingId}`,
          25: `/delivery-letter?keyword=${bookingId}`,
          26: `/water-and-bill-submission?keyword=${bookingId}`,
          27: `/tax-payments?keyword=${bookingId}`,
          28: `/deed-request?keyword=${bookingId}`,
          1: `/service-request?keyword=${requestNo}&tab=0`,
          2: `/service-request?keyword=${requestNo}&tab=1`,
          3: `/service-request?keyword=${requestNo}&tab=1`,
          4: `/service-request?keyword=${requestNo}&tab=3`,
          5: `/service-request?keyword=${requestNo}&tab=4`,
        }

        const redirectRoute = routes[actionType] || `/`
        console.log(redirectRoute)

        // Add click handler for redirection
        notification.onclick = event => {
          event.preventDefault()
          // window.location.replace(redirectRoute)
          navigate(redirectRoute)
        }
      } catch (error) {
        console.error("Error displaying notification:", error)
      }
    })

    // Cleanup listener when component unmounts
    return () => {
      unsubscribe()
    }
  }, [])

  // SET UUID IN COOKIE IF DON'T HAVE

  useEffect(() => {
    const cookieName = "uniqueId"
    let uniqueId = Cookies.get(cookieName)

    // Check if the cookie already exists
    if (!uniqueId) {
      uniqueId = uuidv4()
      Cookies.set(cookieName, uniqueId, { expires: 365 }) // Set the cookie for 1 year
    }
  }, [])

  const fetchAllNotifications = () => {
    dispatch(getAdminNotificationList())
  }

  return (
    <React.Fragment>
      {isVisible && <SweatAlert text={text} type={type} />}

      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware allowedRoles={route.allowedRoles}>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
