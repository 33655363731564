import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import { GET_ALL_CUSTOMERS_INTEREST, GET_CUSTOMER_INTEREST_DETAILS } from "helpers/url_helper"

export const getAllCustomersInterest = createAsyncThunk(
  GET_ALL_CUSTOMERS_INTEREST,
  async payload => {
    const response = await post(GET_ALL_CUSTOMERS_INTEREST, payload)
    return response
  }
)

export const getCustomerInterestDetails = createAsyncThunk(
  GET_CUSTOMER_INTEREST_DETAILS,
  async payload => {
    const response = await post(GET_CUSTOMER_INTEREST_DETAILS, payload)
    return response
  }
)
