import React from "react"

const TopTitle = ({
  title,
  subTitle,
  createBtnText = null,
  onCreateBtnClick = null,
}) => {
  return (
    <div className="g_between">
      <div>
        <h1 className="main_title">{title}</h1>
        {subTitle && <p className="sub_title">{subTitle}</p>}
      </div>
      <div>
        {createBtnText && (
          <button className="create_btn" onClick={onCreateBtnClick}>
            {createBtnText}
          </button>
        )}
      </div>
    </div>
  )
}

export default TopTitle
