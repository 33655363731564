const changePasswordValues = {
  password: "",
  newPassword: "",
  confirmPassword: "",
}

const updateProfileValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
}

const createUserValues = {
  firstName: "",
  lastName: "",
  email: "",
  // phoneNumber: "",
  role: "",
}

export { changePasswordValues, updateProfileValues, createUserValues }
