import Paginations from "components/Common/Pagination"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Spinners from "components/Shared/Spinners"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import StarRatings from "react-star-ratings"
import { Table } from "reactstrap"
import { getAllRatingsFeedback } from "store/actions/maintenanceUsersAction"

const RatingAndFeedback = ({ id, t }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    ratingsAndFeedback,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.MaintenanceUsers)

  useEffect(() => {
    fetchAllRatingsAndFeedback()
  }, [])

  const fetchAllRatingsAndFeedback = page => {
    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      orderBy: 2,
      serviceRequest: [],
      customer: [],
      serviceProvider: [id],
      keyword: "",
      dateRange: {},
    }
    dispatch(getAllRatingsFeedback(payload))

    // navigate(`/ratings-feedback?page=${page || pageNumber}`)
  }

  const onPageChange = page => {
    fetchAllRatingsAndFeedback(page)
  }

  return loading ? (
    <Spinners />
  ) : ratingsAndFeedback.length === 0 ? (
    <NoDataAvailable />
  ) : (
    <>
      <div className="table-responsive">
        <Table className="table mb-0">
          <thead className="full_th">
            <tr>
              <th>{t("#")}</th>
              <th>{t("Request Number")}</th>
              <th>{t("Service Provider Name")}</th>
              <th>{t("Service Provider Phone Number")}</th>
              <th>{t("Customer Name")}</th>
              <th>{t("Customer Phone Number")}</th>
              <th>{t("Feedback")}</th>
              <th>{t("Platform")}</th>
              <th>{t("Rating")}</th>
            </tr>
          </thead>
          <tbody>
            {ratingsAndFeedback.map((item, index) => (
              <tr
                key={item._id}
                className="cursor-pointer"
                onClick={() => {
                  navigate(`/ratings-feedback/details?id=${item._id}`)
                }}
              >
                <th scope="row">{(currentPage - 1) * perPage + index + 1}</th>
                <td>{item?.serviceRequestData?.requestNo ?? "-"}</td>
                <td>{item?.serviceProviderData?.name ?? "-"}</td>
                <td>{item?.serviceProviderData?.phone ?? "-"}</td>
                <td>{item?.userData?.name ?? "-"}</td>
                <td>{item?.userData?.phone ?? "-"}</td>
                <td>{item?.feedback ?? "-"}</td>
                <td>{t(item?.platform) ?? "-"}</td>
                <td className="fit_content">
                  <StarRatings
                    rating={item?.rating}
                    starRatedColor="#F1B44C"
                    starEmptyColor="#74788d"
                    numberOfStars={5}
                    name="rating"
                    starDimension="18px"
                    starSpacing="3px"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {!loading && ratingsAndFeedback.length > 0 && (
        <div className="p-3">
          <Paginations
            currentPage={currentPage}
            totalPages={totalPages}
            totalCount={totalCount}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </>
  )
}

export default RatingAndFeedback
