import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom" // Import useLocation

const useFilters = (
  initialFilters,
  initialKeyword = "",
  initialDateRange = {}
) => {
  const queryParams = new URLSearchParams(window.location.search)
  const keywordFromUrl = queryParams.get("keyword") || ""
  const location = useLocation() 

  const [filters, setFilters] = useState(initialFilters)
  const [keyword, setKeyword] = useState(keywordFromUrl || initialKeyword)
  const [dateRange, setDateRange] = useState(initialDateRange)
  const [selectedId, setSelectedId] = useState(null)
  const [itemIdToDelete, setItemIdToDelete] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isApiCalled, setIsApiCalled] = useState(false)

  const flatpickrRef = useRef(null)

  const handleFilterChange = (selectedValue, filterName) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: selectedValue,
    }))
  }

  const resetFilters = () => {
    setFilters(initialFilters)
    setKeyword(initialKeyword)
    setDateRange(initialDateRange)
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear()
    }
  }

  useEffect(() => {
    // Extract query parameter 'keyword' from the URL
    const urlParams = new URLSearchParams(location.search)
    const keywordFromUrl = urlParams.get("keyword")

    // If there's a keyword in the URL, update the state
    if (keywordFromUrl && keywordFromUrl !== keyword) {
      setKeyword(keywordFromUrl)
    }
  }, [location.search])

  return [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ]
}

export default useFilters
