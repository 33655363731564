import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import DetailsTitle from "components/Shared/DetailsTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import FormateDate from "components/Shared/FormateDate"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { getBuyingPaymentDetails } from "store/actions/buyingPaymentsAction"
import { ShimmerThumbnail } from "react-shimmer-effects"

const HistoryDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("History Details | Takween")

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { isGettingDetails, buyingPaymentDetails } = useSelector(
    state => state.BuyingPayments
  )

  useEffect(() => {
    fetchPaymentDetails()
  }, [])

  const fetchPaymentDetails = () => {
    const payload = {
      id,
    }
    dispatch(getBuyingPaymentDetails(payload))
  }

  return (
    <React.Fragment>
      <div className="page-content history_details">
        <Container fluid>
          {isGettingDetails ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={800} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : Object.values(buyingPaymentDetails).length === 0 ? (
            <p className="text-center my-3">
              <NoDataAvailable />
            </p>
          ) : (
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <DetailsTitle title={t("History Details")} />
                    <Row>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Id")}
                          value={buyingPaymentDetails._id}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Customer Name")}
                          value={
                            buyingPaymentDetails?.userId?.name || "-"
                          }
                        />
                      </Col>
                      {/* <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Customer Last Name")}
                          value={
                            buyingPaymentDetails?.buyerDetails?.lastName || "-"
                          }
                        />
                      </Col> */}
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Customer Email")}
                          value={
                            buyingPaymentDetails?.buyerDetails?.email || "-"
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Unit Name")}
                          value={
                            language === "sa"
                              ? buyingPaymentDetails?.unitId?.nameAr
                              : buyingPaymentDetails?.unitId?.name || "-"
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Created At")}
                          value={FormateDate(buyingPaymentDetails.createdAt)}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Updated At")}
                          value={FormateDate(buyingPaymentDetails.updatedAt)}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Booking Number")}
                          value={buyingPaymentDetails?.bookingNo || "-"}
                        />
                      </Col>
                      <hr />

                      <Col md={12}>
                        <h4 className="fs_24 mt-2">{t("Payment Details")}</h4>
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Initiate Payment")}
                          value={t(
                            buyingPaymentDetails?.paymentDetails
                              ?.initiatePayment || "-"
                          )}
                          valueClass="text-capitalize"
                        />
                      </Col>
                      {buyingPaymentDetails?.paymentDetails?.initiatePayment ===
                        "downPayment" && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Down Payment Amount")}
                            value={`${
                              buyingPaymentDetails?.paymentDetails
                                ?.downPaymentAmount ?? "-"
                            } ${t("SAR")}`}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.paymentDetails?.initiatePayment ===
                        "downPayment" && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Down Payment Method")}
                            value={t(
                              buyingPaymentDetails?.invoices?.find(
                                invoice => invoice?.type === "downPayment"
                              )?.paymentMethod
                            )}
                            valueClass="text-capitalize"
                          />
                        </Col>
                      )}
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Remaining Amount")}
                          value={`${
                            buyingPaymentDetails?.paymentDetails?.remaining ??
                            "-"
                          } ${t("SAR")}`}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Full Payment Method")}
                          value={t(
                            buyingPaymentDetails?.invoices?.find(
                              invoice => invoice?.type === "fullPayment"
                            )?.paymentMethod
                          )}
                          valueClass="text-capitalize"
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Total Amount")}
                          value={`${
                            buyingPaymentDetails?.paymentDetails?.total ?? "-"
                          } ${t("SAR")}`}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Is Full Payment Done")}
                          value={
                            buyingPaymentDetails?.paymentDetails
                              ?.isFullPaymentDone
                              ? t("Yes")
                              : t("No")
                          }
                        />
                      </Col>
                      {buyingPaymentDetails?.paymentDetails
                        ?.downPaymentApprovedAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Down Payment Approved At")}
                            value={FormateDate(
                              buyingPaymentDetails?.paymentDetails
                                ?.downPaymentApprovedAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.paymentDetails
                        ?.downPaymentRejectedAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Down Payment Rejected At")}
                            value={FormateDate(
                              buyingPaymentDetails?.paymentDetails
                                ?.downPaymentRejectedAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.paymentDetails
                        ?.fullPaymentDeadline && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Full Payment Deadline")}
                            value={FormateDate(
                              buyingPaymentDetails?.paymentDetails
                                ?.fullPaymentDeadline
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.paymentDetails
                        ?.fullPaymentApprovedAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Full Payment Approved At")}
                            value={FormateDate(
                              buyingPaymentDetails?.paymentDetails
                                ?.fullPaymentApprovedAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.paymentDetails
                        ?.fullPaymentRejectedAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Full Payment Rejected At")}
                            value={FormateDate(
                              buyingPaymentDetails?.paymentDetails
                                ?.fullPaymentRejectedAt
                            )}
                          />
                        </Col>
                      )}
                      <hr />
                      {/* ########### Buying Payment Details ########### */}
                      {/* ########### CONTRACT DETAILS ########### */}
                      <Col md={12}>
                        <h4 className="fs_24 mt-2">
                          {t("Payment Contract Details")}
                        </h4>
                      </Col>
                      {buyingPaymentDetails?.paymentContractDetails
                        ?.uploadAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Uploaded At")}
                            value={FormateDate(
                              buyingPaymentDetails?.paymentContractDetails
                                ?.uploadAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.paymentContractDetails
                        ?.acceptedByCustomerAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Accepted By Customer At")}
                            value={FormateDate(
                              buyingPaymentDetails?.paymentContractDetails
                                ?.acceptedByCustomerAt
                            )}
                          />
                        </Col>
                      )}
                      {/* <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Contents")}
                          value={
                            buyingPaymentDetails?.paymentContractDetails
                              ?.contents || "-"
                          }
                        />
                      </Col> */}
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Attachments")}
                          value={t("View")}
                          isPdfIcon={true}
                          isLink={true}
                          redirectLink={
                            buyingPaymentDetails?.paymentContractDetails
                              ?.attachment
                          }
                        />
                      </Col>

                      <hr />
                      {/* ########### CONTRACT DETAILS ########### */}

                      {/* ########### DELIVERY LETTER Details ########### */}
                      <Col md={12}>
                        <h4 className="fs_24 mt-2">
                          {t("Delivery Letter Details")}
                        </h4>
                      </Col>
                      {buyingPaymentDetails?.deliveryLetterDetails
                        ?.uploadAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Uploaded At")}
                            value={FormateDate(
                              buyingPaymentDetails?.deliveryLetterDetails
                                ?.uploadAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.deliveryLetterDetails
                        ?.acceptedByCustomerAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Accepted By Customer At")}
                            value={FormateDate(
                              buyingPaymentDetails?.deliveryLetterDetails
                                ?.acceptedByCustomerAt
                            )}
                          />
                        </Col>
                      )}
                      {/* <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Contents")}
                          value={
                            buyingPaymentDetails?.deliveryLetterDetails
                              ?.contents || "-"
                          }
                        />
                      </Col> */}

                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Attachments")}
                          value={t("View")}
                          isPdfIcon={true}
                          isLink={true}
                          redirectLink={
                            buyingPaymentDetails?.deliveryLetterDetails
                              ?.attachment
                          }
                        />
                      </Col>
                      {buyingPaymentDetails?.deliveryLetterDetails
                        ?.confirmDeliveryBook && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Confirm Delivery Book")}
                            value={
                              buyingPaymentDetails?.deliveryLetterDetails
                                ?.confirmDeliveryBook || "-"
                            }
                          />
                        </Col>
                      )}
                      <hr />
                      {/* ########### DELIVERY LETTER Details ########### */}

                      {/* ########### TAX EXEMPTION Details ########### */}
                      <Col md={12}>
                        <h4 className="fs_24 mt-2">
                          {t("Tax Exemption Details")}
                        </h4>
                      </Col>
                      {buyingPaymentDetails?.taxExemptionDetails?.uploadAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Uploaded At")}
                            value={FormateDate(
                              buyingPaymentDetails?.taxExemptionDetails
                                ?.uploadAt
                            )}
                          />
                        </Col>
                      )}
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Is Eligible For Tax Exemption")}
                          value={
                            buyingPaymentDetails?.taxExemptionDetails
                              ?.isEligibleForTaxExemption
                              ? t("Yes")
                              : t("No")
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Tax Exemption")}
                          value={`${
                            buyingPaymentDetails?.taxExemptionDetails
                              ?.taxExemption ?? "-"
                          } ${t("SAR")}`}
                        />
                      </Col>
                      {buyingPaymentDetails?.taxExemptionDetails
                        ?.documentApprovedAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Document Approved At")}
                            value={FormateDate(
                              buyingPaymentDetails?.taxExemptionDetails
                                ?.documentApprovedAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.taxExemptionDetails
                        ?.documentRejectedAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Document Rejected At")}
                            value={FormateDate(
                              buyingPaymentDetails?.taxExemptionDetails
                                ?.documentRejectedAt
                            )}
                          />
                        </Col>
                      )}

                      {buyingPaymentDetails?.taxExemptionDetails
                        ?.paidByCustomerAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Paid By Customer At")}
                            value={FormateDate(
                              buyingPaymentDetails?.taxExemptionDetails
                                ?.paidByCustomerAt
                            )}
                          />
                        </Col>
                      )}

                      {buyingPaymentDetails?.taxExemptionDetails
                        ?.paymentApprovedAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Payment Approved At")}
                            value={FormateDate(
                              buyingPaymentDetails?.taxExemptionDetails
                                ?.paymentApprovedAt
                            )}
                          />
                        </Col>
                      )}

                      {buyingPaymentDetails?.taxExemptionDetails
                        ?.paymentRejectedAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Payment Rejected At")}
                            value={FormateDate(
                              buyingPaymentDetails?.taxExemptionDetails
                                ?.paymentRejectedAt
                            )}
                          />
                        </Col>
                      )}

                      {buyingPaymentDetails?.taxExemptionDetails
                        ?.attachment && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Attachments")}
                            value={t("View")}
                            isPdfIcon={true}
                            isLink={true}
                            redirectLink={
                              buyingPaymentDetails?.taxExemptionDetails
                                ?.attachment
                            }
                          />
                        </Col>
                      )}

                      <hr />
                      {/* ########### TAX EXEMPTION Details ########### */}

                        {/* ########### OWNER SHIP DOCUMENTS DETAILS ########### */}
                        <Col md={12}>
                        <h4 className="fs_24 mt-2">
                          {t("Ownership Documents Details")}
                        </h4>
                      </Col>
                      {buyingPaymentDetails?.ownershipDocumentsDetails
                        ?.uploadAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Z")}
                            value={FormateDate(
                              buyingPaymentDetails?.ownershipDocumentsDetails
                                ?.uploadAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.ownershipDocumentsDetails
                        ?.approvedAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Approved At")}
                            value={FormateDate(
                              buyingPaymentDetails?.ownershipDocumentsDetails
                                ?.approvedAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.ownershipDocumentsDetails
                        ?.rejectedAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Rejected At")}
                            value={FormateDate(
                              buyingPaymentDetails?.ownershipDocumentsDetails
                                ?.rejectedAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.ownershipDocumentsDetails
                        ?.electricityBill && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Electricity Bill")}
                            value={t("View")}
                            isPdfIcon={true}
                            isLink={true}
                            redirectLink={
                              buyingPaymentDetails?.ownershipDocumentsDetails
                                ?.electricityBill
                            }
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.ownershipDocumentsDetails
                        ?.waterBill && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Water Bill")}
                            value={t("View")}
                            isPdfIcon={true}
                            isLink={true}
                            redirectLink={
                              buyingPaymentDetails?.ownershipDocumentsDetails
                                ?.waterBill
                            }
                          />
                        </Col>
                      )}
                      <hr />
                      {/* ########### OWNER SHIP DOCUMENTS DETAILS ########### */}


                      {/* ########### RETT CERTIFICATE Details ########### */}
                      <Col md={12}>
                        <h4 className="fs_24 mt-2">
                          {t("RETT Certificate Details")}
                        </h4>
                      </Col>
                      {buyingPaymentDetails?.RETTCertificate?.uploadAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Uploaded At")}
                            value={FormateDate(
                              buyingPaymentDetails?.RETTCertificate?.uploadAt
                            )}
                          />
                        </Col>
                      )}

                      {buyingPaymentDetails?.RETTCertificate?.attachment && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("RETT Certificate")}
                            value={t("View")}
                            isPdfIcon={true}
                            isLink={true}
                            redirectLink={
                              buyingPaymentDetails?.RETTCertificate?.attachment
                            }
                          />
                        </Col>
                      )}

                      <hr />
                      {/* ########### RETT CERTIFICATE Details ########### */}

                      {/* ########### DEED DETAILS Details ########### */}
                      <Col md={12}>
                        <h4 className="fs_24 mt-2">
                          {t("Deed Documents Details")}
                        </h4>
                      </Col>
                      {buyingPaymentDetails?.deedDocumentDetails?.uploadAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Uploaded At")}
                            value={FormateDate(
                              buyingPaymentDetails?.deedDocumentDetails
                                ?.uploadAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.deedDocumentDetails
                        ?.requestedByCustomerAt && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Requested By Customer At")}
                            value={FormateDate(
                              buyingPaymentDetails?.deedDocumentDetails
                                ?.requestedByCustomerAt
                            )}
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.deedDocumentDetails?.deedDoc && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Deed Document")}
                            value={t("View")}
                            isPdfIcon={true}
                            isLink={true}
                            redirectLink={
                              buyingPaymentDetails?.deedDocumentDetails?.deedDoc
                            }
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.deedDocumentDetails
                        ?.warrantiesDoc && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Warranties Document")}
                            value={t("View")}
                            isPdfIcon={true}
                            isLink={true}
                            redirectLink={
                              buyingPaymentDetails?.deedDocumentDetails
                                ?.warrantiesDoc
                            }
                          />
                        </Col>
                      )}
                      {buyingPaymentDetails?.deedDocumentDetails
                        ?.paymentReceipts && (
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Payment Receipts")}
                            value={t("View")}
                            isPdfIcon={true}
                            isLink={true}
                            redirectLink={
                              buyingPaymentDetails?.deedDocumentDetails
                                ?.paymentReceipts
                            }
                          />
                        </Col>
                      )}

                      <hr />
                      {/* ########### DEED DETAILS Details ########### */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HistoryDetails
