import { createSlice } from "@reduxjs/toolkit"
import {
  createRoomType,
  deleteRoomType,
  getAllRoomTypes,
  getRoomTypeDetails,
  getRoomTypesList,
  updateRoomType,
  updateRoomTypeStatus,
} from "store/actions/roomTypesAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  roomTypes: [],
  roomTypeDetails: {},
  roomTypesList: [],
  isGettingDetails: false,
}
const roomTypesSlice = createSlice({
  name: "room types",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllRoomTypes.pending, state => {
        state.loading = true
      })
      .addCase(getAllRoomTypes.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.roomTypes = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllRoomTypes.rejected, (state, action) => {
        state.loading = false
      })
    // GET LIST
    builder
      .addCase(getRoomTypesList.pending, state => {})
      .addCase(getRoomTypesList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.roomTypesList = data
        }
      })
      .addCase(getRoomTypesList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getRoomTypeDetails.pending, state => {
        state.isGettingDetails = true
      })
      .addCase(getRoomTypeDetails.fulfilled, (state, action) => {
        state.isGettingDetails = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.roomTypeDetails = data
        }
      })
      .addCase(getRoomTypeDetails.rejected, (state, action) => {
        state.isGettingDetails = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateRoomTypeStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateRoomTypeStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.roomTypes = state.roomTypes.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateRoomTypeStatus.rejected, (state, action) => {
        state.isLoading = false
      })
    // DELETE
    builder
      .addCase(deleteRoomType.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteRoomType.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.roomTypes = state.roomTypes.filter(item => item._id !== id) 
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteRoomType.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createRoomType.pending, state => {
        state.isLoading = true
      })
      .addCase(createRoomType.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          state.roomTypes = [data, ...state.roomTypes]
          if (state.totalCount !== null) {
            state.totalCount += 1
          }
        }
      })
      .addCase(createRoomType.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateRoomType.pending, state => {
        state.isLoading = true
      })
      .addCase(updateRoomType.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          state.roomTypes = state?.roomTypes?.map(item =>
            item._id === data._id ? data : item
          )
        }
      })
      .addCase(updateRoomType.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default roomTypesSlice.reducer
