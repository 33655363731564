import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import {
  CREATE_FAQ,
  DELETE_FAQ,
  GET_ALL_FAQS,
  GET_FAQ_DETAILS,
  GET_PAGE_CONTENT_BY_TYPE,
  UPDATE_FAQ,
  UPDATE_FAQ_STATUS,
  UPDATE_PAGE_CONTENT_BY_TYPE,
} from "helpers/url_helper"

export const getAllFAQs = createAsyncThunk(GET_ALL_FAQS, async payload => {
  const response = await post(GET_ALL_FAQS, payload)
  return response
})

export const getFaqDetails = createAsyncThunk(
  GET_FAQ_DETAILS,
  async payload => {
    const response = await post(GET_FAQ_DETAILS, payload)
    return response
  }
)

export const updateFaqStatus = createAsyncThunk(
  UPDATE_FAQ_STATUS,
  async payload => {
    const response = await patch(UPDATE_FAQ_STATUS, payload)
    return response
  }
)

export const deleteFaq = createAsyncThunk(DELETE_FAQ, async payload => {
  const response = await del(DELETE_FAQ, payload)
  return response
})

export const createFaq = createAsyncThunk(CREATE_FAQ, async payload => {
  const response = await post(CREATE_FAQ, payload)
  return response
})

export const updateFaq = createAsyncThunk(UPDATE_FAQ, async payload => {
  const response = await put(UPDATE_FAQ, payload)
  return response
})

export const getPageContentByType = createAsyncThunk(
  GET_PAGE_CONTENT_BY_TYPE,
  async payload => {
    const response = await post(GET_PAGE_CONTENT_BY_TYPE, payload)
    return response
  }
)

export const updatePageContentByType = createAsyncThunk(
  UPDATE_PAGE_CONTENT_BY_TYPE,
  async payload => {
    const response = await post(UPDATE_PAGE_CONTENT_BY_TYPE, payload)
    return response
  }
)
