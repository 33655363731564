import { FormateOnlyDate } from "components/Shared/FormateDate"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import React from "react"
import { Table } from "reactstrap"

const UnAvailableSlots = ({ id, t, maintenanceUserDetails }) => {
  const unavailableDates = [
    {
      date: "2024-11-26T00:00:00.000+00:00",
      isFullDay: false,
      timePeriods: [
        {
          startTime: "09:00 AM",
          endTime: "10:00 PM",
        },
        {
          startTime: "10:00 AM",
          endTime: "11:00 PM",
        },
        {
          startTime: "11:00 AM",
          endTime: "12:30 PM",
        },
      ],
    },
    {
      date: "2024-11-26T00:00:00.000+00:00",
      isFullDay: true,
      timePeriods: [],
    },
  ]

  return (
    <div>
      {maintenanceUserDetails?.unavailableDates?.length === 0 ? (
        <NoDataAvailable />
      ) : (
        <div className="table-responsive">
          <Table className="table mb-0">
            <thead className="full_th">
              <tr>
                <th className="">{t("Start Time")}</th>
                <th className="px-0">{t("End Time")}</th>
                <th className="px-0">{t("Date")}</th>
              </tr>
            </thead>
            {maintenanceUserDetails?.unavailableDates?.map(
              (unavailableDate, dateIndex) => {
                // If full day, render a single row
                if (unavailableDate?.isFullDay) {
                  return (
                    <tr
                      key={`${dateIndex}-full-day`}
                      style={{ background: "gainsboro" }}
                    >
                      <td colSpan="2" className="py-2">
                        {t("Full Day Off")}
                      </td>
                      <td>{FormateOnlyDate(unavailableDate?.date)}</td>
                    </tr>
                  )
                }

                // If timePeriods has items, render them
                return unavailableDate?.timePeriods?.map(
                  (period, periodIndex) => (
                    <tr key={`${dateIndex}-${periodIndex}`}>
                      <td className="py-2">{period.startTime}</td>
                      <td>{period.endTime}</td>
                      <td>{FormateOnlyDate(unavailableDate?.date)}</td>
                    </tr>
                  )
                )
              }
            )}
          </Table>
        </div>
      )}
    </div>
  )
}

export default UnAvailableSlots
