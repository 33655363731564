import React from "react"
import { Field, ErrorMessage } from "formik"

const CustomField = ({
  name,
  type = "text",
  placeholder,
  className,
  as = "input", // Default element type is input, but can be overridden by 'textarea' or others
  touched = {},
  errors = {},
  isArabic = false,
  isReadOnly = false,
}) => {
  // console.log(touched)
  return (
    <div>
      <Field
        name={name}
        type={type}
        id={name}
        as={as}
        readOnly={isReadOnly}
        placeholder={placeholder}
        className={`form-control ${isArabic ? "rtl" : ""} ${
          touched[name] && errors[name] ? "is-invalid" : ""
        } ${className}`}
      />
      <ErrorMessage name={name} component="div" className="text-danger" />
    </div>
  )
}

export default CustomField
