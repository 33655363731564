// GET UNIT STATUS
const getUnitStatus = statusType => {
  const statusMapping = {
    0: { className: "reject_status", label: "Deactive" },
    1: { className: "sold_status", label: "Coming Soon" },
    2: { className: "rended_status", label: "Available for Sale" },
    3: { className: "reject_status", label: "Sold" },
    4: { className: "reject_status", label: "Reserved" },
  }

  return statusMapping[statusType] || { className: "", label: "-" }
}

// GET PROJECT STATUS
const getProjectStatus = statusType => {
  const statusMapping = {
    0: { className: "reject_status", label: "Deactive" },
    1: { className: "sold_status", label: "Coming Soon" },
    2: { className: "rended_status", label: "Available for Sale" },
    3: { className: "reject_status", label: "Sold" },
  }

  return statusMapping[statusType] || { className: "", label: "-" }
}

export { getUnitStatus, getProjectStatus }
