import React from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Row } from "reactstrap"
import MaintenanceUserIcon from "assets/images/icons/admin/maintenanceUser.svg"
import UserIcon from "assets/images/icons/admin/user.svg"
import { Link } from "react-router-dom"

const AdminDashboardTopBar = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const salesDashboardTopBar = [
    {
      id: 1,
      imgUrl: MaintenanceUserIcon,
      text: t("Maintenance Users"),
      redirectLink: "/maintenance-users",
    },
    {
      id: 2,
      imgUrl: UserIcon,
      text: t("Users"),
      redirectLink: "/users",
    },
  ]

  return (
    <Card className="dashboard_top_bar">
      <CardBody>
        <Row>
          <h2>{t("What's going on with the app?")}</h2>
          <p className="mt-2">{t("Mange app notifications, content offers and many more.")}</p>
          {salesDashboardTopBar &&
            salesDashboardTopBar.map(item => (
              <Col lg={3} key={item.id}>
                <Link to={item.redirectLink}>
                  <div className="inner_section">
                    <img src={item.imgUrl} alt="unit icon" />
                    <h3>{t(item.text)}</h3>
                  </div>
                </Link>
              </Col>
            ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default AdminDashboardTopBar
