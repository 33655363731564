import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import TopTitle from "components/Shared/TopTitle"
import classNames from "classnames"
import GeneralDetails from "./GeneralDetails"
import FacilityAndServices from "./FacilityAndServices"
import NearbyLandmarks from "./NearbyLandmarks"
import Gallery from "./Gallery"
import Video from "./Video"
import { useDispatch, useSelector } from "react-redux"
import { getProjectDetails } from "store/actions/projectsAction"
import Spinners from "components/Shared/Spinners"
import BackButton from "components/Shared/BackButton"

const UpdateProject = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Update Project | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const [activeTab, setActiveTab] = useState(1)

  const { loading, isLoading, projectDetails } = useSelector(
    state => state.Projects
  )

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getProjectDetails(payload))
    }
    fetchDetails()
  }, [])

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content update_project">
        {loading ? (
          <Spinners />
        ) : (
          <Container fluid>
            <TopTitle
              title={t("Project")}
              subTitle={t(
                `Hi, {{name}}. Welcome back to your Marketing portal!`,
                { name: name }
              )}
            />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h2 className="fs_18_700 g_start">
                      <BackButton />
                      {t("Update Project")}
                    </h2>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({ active: activeTab === 1 })}
                          onClick={() => {
                            toggle(1)
                          }}
                        >
                          <span>{t("General Details")}</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({ active: activeTab === 2 })}
                          onClick={() => {
                            toggle(2)
                          }}
                        >
                          <span>{t("Facilities And Services")}</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({ active: activeTab === 3 })}
                          onClick={() => {
                            toggle(3)
                          }}
                        >
                          <span>{t("Nearby Landmarks")}</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({ active: activeTab === 4 })}
                          onClick={() => {
                            toggle(4)
                          }}
                        >
                          <span>{t("Gallery")}</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({ active: activeTab === 5 })}
                          onClick={() => {
                            toggle(5)
                          }}
                        >
                          <span>{t("Video")}</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={activeTab}
                      className="text-muted mt-4"
                    >
                      <TabPane tabId={1}>
                        <GeneralDetails id={id} />
                      </TabPane>
                      <TabPane tabId={2}>
                        <FacilityAndServices actionType={"PROJECT"} id={id} />
                      </TabPane>
                      <TabPane tabId={3}>
                        <NearbyLandmarks actionType={"PROJECT"} id={id} />
                      </TabPane>
                      <TabPane tabId={4}>
                        <Gallery actionType={"PROJECT"} id={id} />
                      </TabPane>
                      <TabPane tabId={5}>
                        <Video id={id} />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default UpdateProject
