import { getImagePreview } from "components/Common/Utils"
import React from "react"

const ImgPreview = ({ image }) => {
  return (
    <img
      src={getImagePreview(image)}
      alt="Preview"
      className="py-2 img-preview"
    />
  )
}

export default ImgPreview
