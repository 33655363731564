import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  del,
  formDataPost,
  formDataPut,
  get,
  patch,
  post,
  put,
} from "helpers/api_helper"
import {
  CREATE_PROJECT,
  DELETE_PROJECT,
  GET_ALL_PROJECTS,
  GET_PROJECT_DETAILS,
  GET_PROJECTS_LIST,
  UPDATE_PROJECT,
  UPDATE_PROJECT_STATUS,
  UPLOAD_PROJECT_GALLERY,
  UPLOAD_PROJECT_VIDEO,
} from "helpers/url_helper"

export const getAllProjects = createAsyncThunk(
  GET_ALL_PROJECTS,
  async payload => {
    const response = await post(GET_ALL_PROJECTS, payload)
    return response
  }
)

export const getProjectsList = createAsyncThunk(
  GET_PROJECTS_LIST,
  async queryParams => {
    const updatedUrl = queryParams
      ? `${GET_PROJECTS_LIST}${queryParams}`
      : GET_PROJECTS_LIST
    const response = await get(updatedUrl)
    return response
  }
)

export const updateProjectStatus = createAsyncThunk(
  UPDATE_PROJECT_STATUS,
  async payload => {
    const response = await patch(UPDATE_PROJECT_STATUS, payload)
    return response
  }
)

export const deleteProject = createAsyncThunk(DELETE_PROJECT, async payload => {
  const response = await del(DELETE_PROJECT, payload)
  return response
})

export const createProject = createAsyncThunk(CREATE_PROJECT, async payload => {
  const response = await formDataPost(CREATE_PROJECT, payload)
  return response
})

export const getProjectDetails = createAsyncThunk(
  GET_PROJECT_DETAILS,
  async payload => {
    const response = await post(GET_PROJECT_DETAILS, payload)
    return response
  }
)

export const updateProject = createAsyncThunk(UPDATE_PROJECT, async payload => {
  const response = await formDataPut(UPDATE_PROJECT, payload)
  return response
})

export const uploadProjectGallery = createAsyncThunk(
  UPLOAD_PROJECT_GALLERY,
  async payload => {
    const response = await formDataPost(UPLOAD_PROJECT_GALLERY, payload)
    return response
  }
)

export const uploadProjectVideo = createAsyncThunk(
  UPLOAD_PROJECT_VIDEO,
  async payload => {
    const response = await formDataPost(UPLOAD_PROJECT_VIDEO, payload)
    return response
  }
)
