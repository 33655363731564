import { createSlice } from "@reduxjs/toolkit"
import {
  deleteCustomer,
  getAllCustomers,
  getCustomerDetails,
  getCustomersList,
  updateCustomerStatus,
} from "store/actions/customersAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  customers: [],
  customersList: [],
  customerDetails: {},
}
const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllCustomers.pending, state => {
        state.loading = true
      })
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.customers = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllCustomers.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getCustomersList.pending, state => {})
      .addCase(getCustomersList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.customersList = data
        }
      })
      .addCase(getCustomersList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getCustomerDetails.pending, state => {
        state.loading = true
        state.customerDetails = {}
      })
      .addCase(getCustomerDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.customerDetails = data
        }
      })
      .addCase(getCustomerDetails.rejected, (state, action) => {
        state.loading = false
      })

      // UPDATE STATUS
      // builder
      .addCase(updateCustomerStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateCustomerStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.customers = state.customers.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateCustomerStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteCustomer.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.customers = state.customers.filter(item => item._id !== id) //
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default customersSlice.reducer
