// TITLE FOR DETAIL PAGE
import React from "react"
import BackButton from "./BackButton"
import { CardTitle } from "reactstrap"

const DetailsTitle = ({ title, isBackButton = true }) => {
  return (
    <div>
      <div className="d-flex align-items-center">
        {isBackButton && <BackButton />}
        <CardTitle className="mb-0">{title}</CardTitle>
      </div>
      <hr className="hr_100" />
    </div>
  )
}

export default DetailsTitle
