import { createAsyncThunk } from "@reduxjs/toolkit"
import { formDataPost, post } from "helpers/api_helper"
import {
  APPROVE_EDAAT_PAYMENT,
  APPROVE_REJECT_OWNERSHIP_DETAILS,
  APPROVE_REJECT_TAX_EXEMPTION,
  CANCEL_BOOKING,
  GET_ALL_BUYING_PAYMENTS,
  GET_ALL_DEED_RECORDS,
  GET_BUYING_PAYMENT_DETAILS,
  HOLD_UN_HOLD_PAYMENT,
  UPLOAD_DEED_DOCUMENTS,
  UPLOAD_DELIVERY_LETTER,
  UPLOAD_PAYMENT_CONTRACT,
  UPLOAD_PAYMENT_RECEIPTS,
  UPLOAD_RETT_CERTIFICATE,
} from "helpers/url_helper"

export const getAllBuyingPayments = createAsyncThunk(
  GET_ALL_BUYING_PAYMENTS,
  async payload => {
    const response = await post(GET_ALL_BUYING_PAYMENTS, payload)
    return response
  }
)

export const getAllDeedRecords = createAsyncThunk(
  GET_ALL_DEED_RECORDS,
  async payload => {
    const response = await post(GET_ALL_DEED_RECORDS, payload)
    return response
  }
)

export const holdUnHoldPayment = createAsyncThunk(
  HOLD_UN_HOLD_PAYMENT,
  async payload => {
    const response = await post(HOLD_UN_HOLD_PAYMENT, payload)
    return response
  }
)

export const cancelBooking = createAsyncThunk(CANCEL_BOOKING, async payload => {
  const response = await post(CANCEL_BOOKING, payload)
  return response
})

export const getBuyingPaymentDetails = createAsyncThunk(
  GET_BUYING_PAYMENT_DETAILS,
  async payload => {
    const response = await post(GET_BUYING_PAYMENT_DETAILS, payload)
    return response
  }
)

export const uploadPaymentContract = createAsyncThunk(
  UPLOAD_PAYMENT_CONTRACT,
  async ({ data, id }) => {
    const response = await formDataPost(UPLOAD_PAYMENT_CONTRACT, data)
    return { ...response, id }
  }
)

export const uploadDeliveryLetter = createAsyncThunk(
  UPLOAD_DELIVERY_LETTER,
  async ({ data, id }) => {
    const response = await formDataPost(UPLOAD_DELIVERY_LETTER, data)
    return { ...response, id }
  }
)

export const uploadDeedDocuments = createAsyncThunk(
  UPLOAD_DEED_DOCUMENTS,
  async ({ data, id }) => {
    const response = await formDataPost(UPLOAD_DEED_DOCUMENTS, data)
    return { ...response, id }
  }
)

export const approveRejectOwnerShipDetails = createAsyncThunk(
  APPROVE_REJECT_OWNERSHIP_DETAILS,
  async payload => {
    const response = await post(APPROVE_REJECT_OWNERSHIP_DETAILS, payload)
    return response
  }
)

export const approveRejectTaxExemption = createAsyncThunk(
  APPROVE_REJECT_TAX_EXEMPTION,
  async payload => {
    const response = await post(APPROVE_REJECT_TAX_EXEMPTION, payload)
    return response
  }
)

export const approveEdaatPayment = createAsyncThunk(
  APPROVE_EDAAT_PAYMENT,
  async ({ payload, type }) => {
    const response = await post(APPROVE_EDAAT_PAYMENT, payload)
    return { ...response, type }
  }
)

export const uploadRETTCertificate = createAsyncThunk(
  UPLOAD_RETT_CERTIFICATE,
  async ({ data, id }) => {
    const response = await formDataPost(UPLOAD_RETT_CERTIFICATE, data)
    return { ...response, id }
  }
)

export const uploadPaymentReceipt = createAsyncThunk(
  UPLOAD_PAYMENT_RECEIPTS,
  async ({ data, id }) => {
    const response = await formDataPost(UPLOAD_PAYMENT_RECEIPTS, data)
    return { ...response, id }
  }
)
