import React from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

const Authmiddleware = props => {
  const userType = useSelector(state => state.Login.userType)

  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }
  if (props.allowedRoles && !props.allowedRoles.includes(userType) && userType !== "superadmin") {
    return <Navigate to="/dashboard" />;
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
