import { fileAccept, handleFileUpload } from "components/Common/Utils"
import { uploadDeedSchema } from "components/Common/validation"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import CustomField from "components/Shared/CustomField"
import CustomLabel from "components/Shared/CustomLabel"
import FileUpload from "components/Shared/FileUpload"
import FormButtons from "components/Shared/FormButtons"
import IsLoading from "components/Shared/IsLoading"
import { Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
import { updateUnit } from "store/actions/unitsAction"
import { showAlert } from "store/reducers/alertReducer"

const initialValues = {
  deedNumber: "",
  document: "",
  waterBillSubscriptionNo: "",
  waterBillOrderNumber: "",
  electricityBillSubscriptionNo: "",
  electricityBillOrderNumber: "",
}

const DeedDetails = ({ mode, id }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { isLoading, unitDetails } = useSelector(state => state.Units)

  const [formValues, setFormValues] = useState(initialValues)
  const dispatch = useDispatch()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (unitDetails) {
      const { deedNumber, document } = unitDetails?.deedDetails || {}
      const { waterBill, electricityBill } =
        unitDetails?.oldOwnershipDetails || {}
        
      setFormValues(prev => ({
        ...prev,
        deedNumber,
        document,
        waterBillSubscriptionNo: waterBill?.subscriptionNo,
        waterBillOrderNumber: waterBill?.orderNumber,
        electricityBillSubscriptionNo: electricityBill?.subscriptionNo,
        electricityBillOrderNumber: electricityBill?.orderNumber,
      }))

      setIsLoaded(true)
    }
  }, [unitDetails])

  const handleOnSubmit = values => {
    const {
      deedNumber,
      document,
      waterBillSubscriptionNo,
      waterBillOrderNumber,
      electricityBillSubscriptionNo,
      electricityBillOrderNumber,
    } = values

    let formData = new FormData()
    formData.append("id", id)
    formData.append("deedNumber", deedNumber)
    formData.append("document", document)
    formData.append(
      "ownershipDetails[waterBill][subscriptionNo]",
      waterBillSubscriptionNo
    )
    formData.append(
      "ownershipDetails[waterBill][orderNumber]",
      waterBillOrderNumber
    )
    formData.append(
      "ownershipDetails[electricityBill][subscriptionNo]",
      electricityBillSubscriptionNo
    )
    formData.append(
      "ownershipDetails[electricityBill][orderNumber]",
      electricityBillOrderNumber
    )

    dispatch(updateUnit(formData))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <div>
      {" "}
      {!isLoaded ? (
        <IsLoading />
      ) : (
        <Formik
          initialValues={formValues}
          validationSchema={uploadDeedSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <Row>
                <h2 className="fs_24">{t("Deed Details")}</h2>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="deedNumber"
                      label={t("Deed Number")}
                      isRequired={true}
                    />
                    <CustomField
                      name="deedNumber"
                      type="text"
                      placeholder={t("Deed Number")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="document"
                      label={t("Upload Deed Document")}
                      isRequired={true}
                    />
                    <FileUpload
                      name="document"
                      value={values.document}
                      touched={touched.document}
                      error={errors.document}
                      labelText={t("Upload Deed Document")}
                      setFieldValue={setFieldValue}
                      accept={fileAccept}
                      customClasses="h_46"
                      showNameOnly={true}
                      showPreviewLink={true}
                      handleFileUpload={event => {
                        handleFileUpload(event, setFieldValue, "document")
                      }}
                    />
                    <CustomErrorMessage name={"document"} />
                  </div>
                </Col>
              </Row>
              <div className="pt-3"></div>
              <hr />
              <Row>
                <h2 className="fs_24">{t("Ownership Details")}</h2>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="waterBillSubscriptionNo"
                      label={t("Water Bill Subscription Number")}
                      isRequired={true}
                    />
                    <CustomField
                      name="waterBillSubscriptionNo"
                      type="text"
                      placeholder={t("Water Bill Subscription Number")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="waterBillOrderNumber"
                      label={t("Water Bill Order Number")}
                      isRequired={true}
                    />
                    <CustomField
                      name="waterBillOrderNumber"
                      type="text"
                      placeholder={t("Water Bill Order Number")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="electricityBillSubscriptionNo"
                      label={t("Electricity Subscription Number")}
                      isRequired={true}
                    />
                    <CustomField
                      name="electricityBillSubscriptionNo"
                      type="text"
                      placeholder={t("Electricity Subscription Number")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="electricityBillOrderNumber"
                      label={t("Electricity Order Number")}
                      isRequired={true}
                    />
                    <CustomField
                      name="electricityBillOrderNumber"
                      type="text"
                      placeholder={t("Electricity Order Number")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FormButtons buttonText={t("Update")} isLoading={isLoading} />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default DeedDetails
