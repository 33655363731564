import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Spinner,
} from "reactstrap"
import SimpleBar from "simplebar-react"

import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import NotificationIcon from "assets/images/icons/notificationIcon.svg"
import NotificationBlueIcon from "assets/images/icons/notificationBlue.svg"

import { handleNotificationClick } from "components/Common/NotificationUtils"
import { getTimeAgo } from "components/Common/Utils"
import {
  getAdminNotificationList,
  getAdminNotifications,
  markAdminNotificationAsRead,
} from "store/actions/adminNotificationAction"

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false)
  const { userType } = useSelector(state => state.Login)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loadingList, totalCount, totalReadCount, notificationsList } =
    useSelector(state => state.AdminNotifications)

  useEffect(() => {
    fetchAllNotifications()
  }, [menu])

  const fetchAllNotifications = () => {
    dispatch(getAdminNotificationList())
  }

  const handleSingleNotificationClick = (id, status) => {
    if (status === 1) {
      navigate(`/notifications/details?id=${id}`)
      setMenu(!menu)
    } else {
      const payload = {
        id: id,
        status: "1",
      }
      dispatch(markAdminNotificationAsRead(payload))
        .then(action => {})
        .catch(error => {})
      setMenu(!menu)
      navigate(`/notifications/details?id=${id}`)
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block custom_notification"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item position-relative "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <div className="notification_bar g_center">
            <img src={NotificationIcon} alt="" width={19} height={19} />
            {/* <i className="bx bx-bell bx-tada" /> */}
            {/* {!loadingList && ( */}
            <span className="notification_count g_center">
              {/* {Math.max(totalCount - totalReadCount, 0)} */}
              {totalReadCount}
            </span>
            {/* )} */}
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 notification_dropdown">
          {loadingList ? (
            <div className="p-4 text-center">
              <Spinner></Spinner>
            </div>
          ) : (
            <>
              <SimpleBar>
                {notificationsList?.length === 0 ? (
                  <h6 className="text-center my-3">
                    {props.t("No notifications")}
                  </h6>
                ) : (
                  notificationsList?.map(item => (
                    <div
                      key={item._id}
                      className="text-reset notification-item notification_inner cursor-pointer"
                      onClick={() => {
                        // handleNotificationClick(
                        //   navigate,
                        //   item.paymentType,
                        //   item.bookingId
                        // )
                        handleSingleNotificationClick(item._id, item.status)
                      }}
                    >
                      <div
                        className={`d-flex ${
                          item.status === 0 ? "unread" : "read"
                        }`}
                      >
                        <div className="avatar g_center me-3">
                          <img
                            src={NotificationBlueIcon}
                            alt=""
                            width={13}
                            height={13}
                          />
                        </div>
                        <div className="msg_div" style={{ minWidth: 242 }}>
                          <h6 className="">{item?.title?.en}</h6>
                          <p className="mb-0">{getTimeAgo(item.createdAt)}</p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </SimpleBar>
              {notificationsList?.length >= 10 && (
                <div className="p-2 border-top d-grid">
                  <button
                    className="btn btn-sm btn-link font-size-14 text-center"
                    onClick={() => {
                      setMenu(!menu)
                      navigate("/notifications")
                    }}
                  >
                    <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                    <span key="t-view-more">{props.t("View More..")}</span>
                  </button>
                </div>
              )}
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
