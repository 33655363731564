import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import {
  ascDescOptions,
  commonFilterOptions,
  customerSupportChannelOptions,
  customerSupportPriorityOptions,
  customerSupportStatusOptions,
  customerSupportSubjectOptions,
} from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import { complaintsStatusUpdateSchema } from "components/Common/validation"
import { Field, Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import Paginations from "components/Common/Pagination"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteCustomerSupportSubject,
  getAllCustomerSupport,
  getAllCustomerSupportSubject,
  updateCustomerSupportStatus,
  updateCustomerSupportSubjectStatus,
} from "store/actions/customerSupportAction"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
  handleStopPropagation,
} from "components/Common/Utils"
import Spinners from "components/Shared/Spinners"
import ViewIcon from "assets/images/icons/view.svg"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import { showAlert } from "store/reducers/alertReducer"
import { exportData } from "helpers/api_helper"
import { EXPORT_DATA } from "helpers/url_helper"
import Switch from "components/Shared/Switch"
import DeleteModal from "components/Common/DeleteModal"
import { getRolesList } from "store/actions/usersAction"

const CustomerSupportSubject = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Customer Support Subject | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const { userType } = useSelector(state => state.Login)
  const { rolesList } = useSelector(state => state.Users)

  const {
    loading,
    isLoading,
    customerSupportSubject,
    customerSupportSubjectList,
    customerSupportSubjectDetails,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.CustomerSupport)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    role: [],
  })

  useEffect(() => {
    fetchAllCustomerSupportSubject()
    fetchRolesList()
  }, [])

  const fetchRolesList = () => {
    dispatch(getRolesList())
  }

  const fetchAllCustomerSupportSubject = page => {
    const { status, orderBy, subject, role } = filters

    // const role = JSON.parse(localStorage.getItem("authUser"))?.roleId
    const updatedRoles = role?.map(item => item.value) || []

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      keyword,
      role: updatedRoles,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllCustomerSupportSubject(payload))
    setIsApiCalled(true)

    navigate(`/customer-support-subject?page=${page || pageNumber}`)
  }

  const additionOptions = [
    {
      name: "role",
      type: "multiSelect",
      options: rolesList?.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Select Roles"),
    },
  ]

  const filterOptions = [...commonFilterOptions, ...additionOptions]

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllCustomerSupportSubject(1)
  }

  const onPageChange = page => {
    fetchAllCustomerSupportSubject(page)
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 1 ? "0" : "1",
    }
    dispatch(updateCustomerSupportSubjectStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deleteCustomerSupportSubject(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Customer Support Subject")}
                  isAddBtn={true}
                  addBtnText={t("Create")}
                  onAddBtnClick={() =>
                    navigate(`/customer-support-subject/create`)
                  }
                />
                <div className="mt-2"></div>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={4}
                />
                {loading ? (
                  <div className="mt-5">
                    <Spinners />
                  </div>
                ) : customerSupportSubject.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Title(English)")}</th>
                          <th>{t("Title(عربي)")}</th>
                          <th>{t("Display Order")}</th>
                          <th>{t("Role")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerSupportSubject.map((item, index) => (
                          <tr key={item._id}>
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{item.title || "-"}</td>
                            <td>{item.titleAr || "-"}</td>
                            <td>{item.displayOrder ?? "-"}</td>
                            <td>
                              {language === "sa"
                                ? item?.role?.nameAr
                                : item?.role?.name || "-"}
                            </td>
                            <td>
                              {item.status === 1 ? (
                                <p className="rended_status status_tag">
                                  {t("Active")}
                                </p>
                              ) : item.status === 0 ? (
                                <p className="reject_status status_tag">
                                  {t("Deactive")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center gap-3">
                                <img
                                  src={ViewIcon}
                                  alt="Edit Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/customer-support-subject/details?id=${item._id}`
                                    )
                                  }
                                />
                                <img
                                  src={EditIcon}
                                  alt="Edit Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/customer-support-subject/update?id=${item._id}`
                                    )
                                  }
                                />
                                <img
                                  src={TrashIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setItemIdToDelete(item._id)
                                    setShowDeleteModal(true)
                                  }}
                                />
                                <Switch
                                  onClickHandler={() => {
                                    handleStatusClick(item._id, item.status)
                                  }}
                                  isChecked={item.status === 1 ? true : false}
                                  disabled={
                                    isLoading && selectedId === item._id
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && customerSupportSubject.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("Customer Support Subject"),
        })}
      />
    </React.Fragment>
  )
}

export default CustomerSupportSubject
