import React, { useEffect, useState } from "react"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import CustomField from "components/Shared/CustomField"
import CustomLabel from "components/Shared/CustomLabel"
import Spinners from "components/Shared/Spinners"
import TopTitle from "components/Shared/TopTitle"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import FileUpload from "components/Shared/FileUpload"
import {
  handleFileUpload,
  handleFileUpload_24_24,
} from "components/Common/Utils"
import FormButtons from "components/Shared/FormButtons"
import {
  createCustomerSupportSubjectSchema,
  createServiceSchema,
} from "components/Common/validation"
import { showAlert } from "store/reducers/alertReducer"
import {
  createService,
  getServiceDetails,
  updateService,
} from "store/actions/servicesAction"
import {
  createCustomerSupportSubject,
  getCustomerSupportSubjectDetails,
  updateCustomerSupportSubject,
} from "store/actions/customerSupportAction"
import { getRolesList } from "store/actions/usersAction"
import BackButton from "components/Shared/BackButton"

const initialValues = {
  title: "",
  titleAr: "",
  role: "",
  displayOrder: null,
}

const CreateCustomerSupportSubject = ({ mode }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title =
    mode === "create"
      ? t("Create Customer Support Subject | Takween")
      : t("Update Customer Support Subject | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { loading, isLoading, customerSupportSubjectDetails } = useSelector(
    state => state.CustomerSupport
  )
  const { userProfile } = useSelector(state => state.Login) || ""

  const { rolesList } = useSelector(state => state.Users)

  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getCustomerSupportSubjectDetails(payload))
    }
    mode === "update" && fetchDetails()
    fetchRolesList()
  }, [])

  const fetchRolesList = () => {
    dispatch(getRolesList())
  }

  useEffect(() => {
    if (customerSupportSubjectDetails && mode === "update") {
      const { title, titleAr, displayOrder, role } =
        customerSupportSubjectDetails

      setFormValues(prev => ({
        title,
        titleAr,
        displayOrder,
        role: role?._id,
      }))
    }
  }, [mode, customerSupportSubjectDetails])

  const handleOnSubmit = values => {
    if (mode === "create") {
      dispatch(createCustomerSupportSubject(values))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/customer-support-subject")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (mode === "update") {
      const updatedPayload = {
        ...values,
        id,
      }
      dispatch(updateCustomerSupportSubject(updatedPayload))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/customer-support-subject")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {mode === "update" && loading ? (
          <Spinners />
        ) : (
          <Container fluid>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h2 className="fs_18_700 g_start">
                      <BackButton />
                      {mode === "create"
                        ? t("Create Customer Support Subject")
                        : t("Update Customer Support Subject")}
                    </h2>
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={createCustomerSupportSubjectSchema}
                      onSubmit={handleOnSubmit}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="title"
                                  label={t("Title(English)")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="title"
                                  type="text"
                                  placeholder={t("Title(English)")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="titleAr"
                                  label={t("Title(عربي)")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="titleAr"
                                  type="text"
                                  isArabic={true}
                                  placeholder={t("Title(عربي)")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="displayOrder"
                                  label={t("Display Order")}
                                  isRequired={true}
                                />
                                <CustomField
                                  name="displayOrder"
                                  type="text"
                                  placeholder={t("Display Order")}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="role">
                                  {t("Select Role")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <div className="select_wrapper">
                                  <Field
                                    as="select"
                                    name="role"
                                    className={`form-control b_r_12 ${
                                      errors.role && touched.role
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={e => {
                                      const selectedValue = e.target.value
                                      setFieldValue("role", selectedValue)
                                    }}
                                  >
                                    <option hidden>{t("Select Role")}</option>
                                    {rolesList?.map(item => (
                                      <option key={item._id} value={item._id}>
                                        {language === "sa"
                                          ? item.nameAr
                                          : item.name}
                                      </option>
                                    ))}
                                  </Field>
                                </div>

                                <ErrorMessage
                                  name="role"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <FormButtons
                                buttonText={
                                  mode === "create" ? t("Create") : t("Update")
                                }
                                isLoading={isLoading}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default CreateCustomerSupportSubject
