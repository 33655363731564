import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { t } from "i18next"
import DashboardTopBar from "pages/Sales/DashboardTopBar"
import SalesDashboard from "pages/Sales/SalesDashboard"
import MarketingDashboardTopBar from "pages/Marketing/MarketingDashboardTopBar"
import MarketingDashboard from "pages/Marketing/MarketingDashboard"
import FinanceTopBar from "pages/Finance/FinanceTopBar"
import FinanceDashboard from "pages/Finance/FinanceDashboard"
import CustomerSupportTopBar from "pages/CustomerSupport/CustomerSupportTopBar"
import CustomerSupportDashboard from "pages/CustomerSupport/CustomerSupportDashboard"
import AdminDashboardTopBar from "pages/SuperAdmin/AdminDashboardTopBar"
import AdminDashboard from "pages/SuperAdmin/AdminDashboard"

import {
  convertDateFormat,
  formatDate,
  getCurrentMonth,
  getDashboardDateRange,
  getDateRange,
  getLastMonth,
  getTodayDate,
  getYesterdayDate,
} from "components/Common/Utils"
import { getDashboardData } from "store/actions/commonAction"
import { endOfYear, format, startOfYear } from "date-fns"
import DashboardDateFilter from "./DashboardDateFilter"
import { useNavigate } from "react-router-dom"

const Dashboard = props => {
  //meta title
  document.title = props.t("Dashboard | Takween")
  const navigate = useNavigate()
  const { userType } = useSelector(state => state.Login)
  // const { loading, isLoading } = useSelector(state => state.Common)

  const name = useSelector(state => state.Login.userProfile?.name) || ""
  useEffect(() => {
    if (userType === "mowatheq") navigate("/profile")
  }, [])

  // const lastMonth = getLastMonth()
  // const currentMonth = getCurrentMonth()

  // const [dateRange, setDateRange] = useState([
  //   format(startOfYear(new Date()), "yyyy-MM-dd"),
  //   format(endOfYear(new Date()), "yyyy-MM-dd"),
  // ])
  const [dateRange, setDateRange] = useState([])

  const adminType =
    userType === "sales"
      ? t("Sales")
      : userType === "marketing"
      ? t("Marketing")
      : userType === "finance"
      ? t("Finance")
      : userType === "customerService"
      ? t("Customer Support")
      : userType === "superadmin"
      ? t("Super Admin")
      : null

  return (
    <React.Fragment>
      <div className="page-content dashboard_page">
        <Container fluid>
          <div className="g_between">
            <div>
              <h1 className="main_title">{t("Dashboard")}</h1>
              <p className="sub_title">
                {t(`Hi, {{name}}. Welcome back to your {{type}} portal!`, {
                  name: name,
                  type:t(adminType)
                })}
              </p>
            </div>
            <DashboardDateFilter
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </div>
          {/* ADMIN */}
          {userType === "superadmin" && (
            <>
              <AdminDashboardTopBar />
              <AdminDashboard
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </>
          )}
          {/* SALES DASHBOARD START */}
          {userType === "sales" && (
            <>
              <DashboardTopBar />
              <SalesDashboard
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </>
          )}

          {userType === "marketing" && (
            <>
              <MarketingDashboardTopBar />
              <MarketingDashboard
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </>
          )}

          {userType === "finance" && (
            <>
              <FinanceTopBar />
              <FinanceDashboard
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </>
          )}
          {userType === "customerService" && (
            <>
              <CustomerSupportTopBar />
              <CustomerSupportDashboard
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
