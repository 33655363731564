import NoDataAvailable from "components/Shared/NoDataAvailable"
import React from "react"
import { Table } from "reactstrap"

const DeviceInfo = ({ t, maintenanceUserDetails }) => {
  return (
    <div>
      {/* {maintenanceUserDetails?.deviceInfos?.length === 0 ? (
        <NoDataAvailable />
      ) : ( */}
        <Table className="table mb-0 table_corner_radius">
          <thead className="full_th">
            <tr>
              <th>{t("Device Id")}</th>
              <th>{t("Language")}</th>
              <th>{t("FCM Token")}</th>
              <th>{t("App Version")}</th>
              <th>{t("OS Version")}</th>
              <th>{t("Platform")}</th>
            </tr>
          </thead>
          <tbody>
            {/* {maintenanceUserDetails?.deviceInfos?.map((item, index) => ( */}
            <tr>
              <td>{maintenanceUserDetails?.deviceInfo?.deviceId || "-"}</td>
              <td>{maintenanceUserDetails?.deviceInfo?.language || "-"}</td>
              <td>{maintenanceUserDetails?.deviceInfo?.fcmToken || "-"}</td>
              <td>{maintenanceUserDetails?.deviceInfo?.appVersion || "-"}</td>
              <td>{maintenanceUserDetails?.deviceInfo?.osVersion || "-"}</td>
              <td>{maintenanceUserDetails?.deviceInfo?.platform || "-"}</td>
            </tr>
            {/* ))} */}
          </tbody>
        </Table>
      {/* )} */}
    </div>
  )
}

export default DeviceInfo
