import { createSlice } from "@reduxjs/toolkit"
import {
  createFaq,
  deleteFaq,
  getAllFAQs,
  getFaqDetails,
  getPageContentByType,
  updateFaq,
  updateFaqStatus,
  updatePageContentByType,
} from "store/actions/contentManagementAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  faqs: [],
  faqDetails: {},
  pageContent: {}, // T&C/PP
}
const contentManagements = createSlice({
  name: "content managements",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllFAQs.pending, state => {
        state.loading = true
      })
      .addCase(getAllFAQs.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.faqs = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllFAQs.rejected, (state, action) => {
        state.loading = false
      })

    // GET DETAILS
    builder
      .addCase(getFaqDetails.pending, state => {
        state.loading = true
        state.faqDetails = {}
      })
      .addCase(getFaqDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.faqDetails = data
        }
      })
      .addCase(getFaqDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateFaqStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateFaqStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.faqs = state.faqs.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateFaqStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteFaq.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.faqs = state.faqs.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteFaq.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createFaq.pending, state => {
        state.isLoading = true
      })
      .addCase(createFaq.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createFaq.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateFaq.pending, state => {
        state.isLoading = true
      })
      .addCase(updateFaq.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateFaq.rejected, (state, action) => {
        state.isLoading = false
      })

    // GET CONTENT TYPE (T&C/PP)
    builder
      .addCase(getPageContentByType.pending, state => {
        state.loading = true
      })
      .addCase(getPageContentByType.fulfilled, (state, action) => {
        state.loading = false
        state.pageContent = {}
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          state.pageContent = data
        }
      })
      .addCase(getPageContentByType.rejected, (state, action) => {
        state.loading = false
      })

    builder
      .addCase(updatePageContentByType.pending, state => {
        state.isLoading = true
      })
      .addCase(updatePageContentByType.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updatePageContentByType.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default contentManagements.reducer
