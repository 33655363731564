import React, { useEffect } from "react"
import FormateDate from "components/Shared/FormateDate"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import { useDispatch, useSelector } from "react-redux"
import { Table } from "reactstrap"
import { getAllCustomersInterest } from "store/actions/customerInterestAction"
import { useTranslation } from "react-i18next"
import Spinners from "components/Shared/Spinners"
import Paginations from "components/Common/Pagination"

const CustomerRegisteredInterest = ({ id, activeTab }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const {
    loading,
    customersInterest,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.CustomerInterest)

  const dispatch = useDispatch()

  useEffect(() => {
    activeTab === 2 && fetchAllCustomerInterest()
  }, [activeTab])

  const fetchAllCustomerInterest = page => {
    const payload = {
      perPage: perPage,
      pageNo: page || 1,
      orderBy: 1,
      project: [],
      customer: [id],
      keyword: "",
      dateRange: {},
    }

    dispatch(getAllCustomersInterest(payload))
  }

  const onPageChange = page => {
    fetchAllCustomerInterest(page)
  }

  return (
    <div>
      {loading ? (
        <Spinners />
      ) : customersInterest?.length === 0 ? (
        <NoDataAvailable />
      ) : (
        <Table className="table mb-0 table_corner_radius">
          <thead className="full_th">
            <tr>
              <th>{t("#")}</th>
              <th>{t("Project Name")}</th>
              <th>{t("Customer Name")}</th>
              <th>{t("Customer Phone")}</th>
              <th>{t("Registration Date")}</th>
            </tr>
          </thead>
          <tbody>
            {customersInterest?.map((item, index) => (
              <tr key={item._id}>
                <th scope="row">{index + 1}</th>
                <td>{item?.projectData?.name || "-"}</td>
                <td>{item?.userData?.name}</td>
                <td>{item?.userData?.phone}</td>
                <td>{FormateDate(item.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!loading && customersInterest.length > 0 && (
        <>
          <div className="px-3 pb-3 pt-2">
            <Paginations
              currentPage={currentPage}
              totalPages={totalPages}
              totalCount={totalCount}
              onPageChange={onPageChange}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default CustomerRegisteredInterest
