import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import {
  CREATE_USER,
  DELETE_USER,
  GET_ALL_USERS,
  GET_ROLE_LISTS,
  GET_USER_DETAILS,
  UPDATE_USER,
  UPDATE_USER_STATUS,
} from "helpers/url_helper"

export const getAllUsers = createAsyncThunk(GET_ALL_USERS, async payload => {
  const response = await post(GET_ALL_USERS, payload)
  return response
})

export const getRolesList = createAsyncThunk(GET_ROLE_LISTS, async payload => {
  const response = await get(GET_ROLE_LISTS, payload)
  return response
})

export const getUserDetails = createAsyncThunk(
  GET_USER_DETAILS,
  async payload => {
    const response = await post(GET_USER_DETAILS, payload)
    return response
  }
)

export const updateUserStatus = createAsyncThunk(
  UPDATE_USER_STATUS,
  async payload => {
    const response = await patch(UPDATE_USER_STATUS, payload)
    return response
  }
)

export const deleteUser = createAsyncThunk(DELETE_USER, async payload => {
  const response = await del(DELETE_USER, payload)
  return response
})

export const createUser = createAsyncThunk(CREATE_USER, async payload => {
  const response = await post(CREATE_USER, payload)
  return response
})

export const updateUser = createAsyncThunk(UPDATE_USER, async payload => {
  const response = await put(UPDATE_USER, payload)
  return response
})
