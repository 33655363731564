import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import DetailsTitle from "components/Shared/DetailsTitle"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import TopTitle from "components/Shared/TopTitle"
import LocationIcon from "assets/images/icons/location.svg"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import CheckIcon from "assets/images/icons/check.svg"
import UnCheckIcon from "assets/images/icons/uncheck.svg"
import PlayIcon from "assets/images/icons/playButton.svg"
import { useDispatch, useSelector } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import { getProjectDetails } from "store/actions/projectsAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { getProjectStatus } from "components/Common/StatusUtils"

const ProjectDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Project Details | Takween")

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const { loading, isLoading, projectDetails } = useSelector(
    state => state.Projects
  )

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getProjectDetails(payload))
    }
    fetchDetails()
  }, [])

  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef(null)

  const handleVideoPlayPause = () => {
    const video = videoRef.current

    if (isPlaying) {
      video.pause()
      setIsPlaying(false)
    } else {
      video.play()
      setIsPlaying(true)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content project_details">
        <Container fluid>
          <TopTitle
            title={t("Project Details")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          {loading ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : Object.values(projectDetails).length === 0 ? (
            <p className="text-center my-3">
              <NoDataAvailable />
            </p>
          ) : (
            <>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <DetailsTitle title={t("Project Details")} />
                      <Row className="mt-3">
                        {/* IMG COL */}
                        <Col md={4}>
                          <img
                            src={projectDetails?.imageUrl}
                            alt=""
                            className="img-fluid rounded-4"
                          />
                        </Col>
                        {/* PROJECT DETAIL COL */}
                        <Col md={8}>
                          <Row>
                            <Col lg={8}>
                              <h2 className="main_title">
                                {language === "sa"
                                  ? projectDetails.nameAr
                                  : projectDetails.name}
                              </h2>
                              <p className="fs_18_400 d-flex text_black">
                                <img
                                  src={LocationIcon}
                                  alt="location icon"
                                  className="me-1"
                                  width={24}
                                  height={24}
                                />
                                {language === "sa"
                                  ? projectDetails.addressAr
                                  : projectDetails.address}
                              </p>
                            </Col>
                            <Col lg={4}>
                              <div>
                                <h4 className="fs_14_600">
                                  {t("Status Type")}
                                </h4>
                                <p
                                  className={`${
                                    getProjectStatus(projectDetails.status)
                                      .className
                                  } status_tag fit_content mt-2`}
                                >
                                  {t(
                                    getProjectStatus(projectDetails.status)
                                      .label
                                  )}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <DetailsInnerDiv
                                label={t("Status")}
                                value={
                                  projectDetails?.status === 1
                                    ? t("Active")
                                    : t("Deactive")
                                }
                                valueClass={`${
                                  projectDetails?.status === 1
                                    ? "active_color"
                                    : "danger_color"
                                }`}
                              />
                            </Col>
                            <Col lg={4}>
                              <DetailsInnerDiv
                                label={t("Total Unit")}
                                value={
                                  projectDetails?.generalDetails
                                    ?.totalNumberOfUnits ?? "-"
                                }
                              />
                            </Col>
                            <Col lg={4}>
                              <DetailsInnerDiv
                                label={t("City Name")}
                                value={projectDetails?.cityId?.name || "-"}
                              />
                            </Col>
                            <Col lg={4}>
                              <DetailsInnerDiv
                                label={t("Interface")}
                                value={
                                  projectDetails?.generalDetails?.interface
                                }
                              />
                            </Col>
                            <Col lg={4}>
                              <DetailsInnerDiv
                                label={t("Purpose")}
                                value={projectDetails?.generalDetails?.purpose}
                              />
                            </Col>
                            <Col lg={4}>
                              <DetailsInnerDiv
                                label={t("Total Number of floor")}
                                value={
                                  projectDetails?.generalDetails
                                    ?.totalNumberOfFloors
                                }
                              />
                            </Col>
                            <Col lg={4}>
                              <DetailsInnerDiv
                                label={t("Advertising license number")}
                                value={projectDetails.adsLicenseNumber}
                              />
                            </Col>
                            <Col lg={4}>
                              <DetailsInnerDiv
                                label={t("Latitude")}
                                value={projectDetails.latitude}
                              />
                            </Col>
                            <Col lg={4}>
                              <DetailsInnerDiv
                                label={t("Longitude")}
                                value={projectDetails.longitude}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col md={12} className="mt-4">
                          <div>
                            <h2 className="fs_20_600 font-bold">
                              {t("General Details")}
                            </h2>
                            <p className="fs_14_500 opacity-50">
                              {language === "sa"
                                ? projectDetails.descriptionAr
                                : projectDetails.description}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card className="nearby_landmarks full_height_card">
                    <CardBody>
                      <h2 className="fs_20 font-bold text_black">
                        {t("Nearby Landmarks")}
                      </h2>
                      <ul>
                        {projectDetails?.nearByLandMarks?.length === 0 ? (
                          <p className="fs_16 text-center py-4">
                            {t("No Near By Landmarks Added")}
                          </p>
                        ) : (
                          projectDetails?.nearByLandMarks?.map(
                            (item, index) => (
                              <div key={index}>
                                <li>
                                  <span className="fs_16 fw_400">
                                    {language === "sa"
                                      ? item.nameAr
                                      : item.name}
                                  </span>
                                  <span>
                                    {item.distance} {t(item.distanceUnit)}
                                  </span>
                                </li>
                                {index !==
                                  projectDetails?.nearbyLandMarks?.length -
                                    1 && <hr className="hr_100" />}
                              </div>
                            )
                          )
                        )}
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="facility_row full_height_card">
                    <CardBody>
                      <h2 className="fs_20 font-bold text_black">
                        {t("Facilities & Services")}
                      </h2>
                      <Row>
                        {projectDetails?.facilitiesAndServices?.length === 0 ? (
                          <p className="fs_16 text-center py-4">
                            {t("No Facilities & Services Added")}
                          </p>
                        ) : (
                          <ul>
                            {projectDetails?.facilitiesAndServices?.map(
                              (facility, index) => (
                                <Col lg={6} key={index}>
                                  {/* <li className="fs_20 mb-1 font-bold text_black">
                                   
                                  </li> */}
                                  <li>
                                          <img
                                            width={24}
                                            height={24}
                                            src={facility.imageUrl}
                                            alt="icon"
                                          />
                                          <span>
                                          {language === "sa"
                                      ? facility?.nameAr
                                      : facility?.name || "-"}
                                          </span>
                                        </li>

                                  {/* <p>
                                  {language === "sa"
                                    ? facility?.descriptionAr
                                    : facility?.description || "-"}
                                </p> */}
                                  {/* <ul className="ps-0">
                                  {facility?.lists?.length === 0
                                    ? ""
                                    : facility?.lists?.map(list => (
                                        <li key={list._id}>
                                          <img
                                            width={24}
                                            height={24}
                                            src={list.imageUrl}
                                            alt="icon"
                                          />
                                          <span>
                                            {language === "sa"
                                              ? list.nameAr
                                              : list.name}
                                          </span>
                                        </li>
                                      ))}
                                </ul> */}
                                </Col>
                              )
                            )}
                          </ul>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Card className="video_row">
                    <CardBody>
                      <h2 className="fs_20 font-bold text_black">
                        {t("Video")}
                      </h2>
                      {projectDetails.videoUrl ? (
                        <>
                          <video ref={videoRef} onClick={handleVideoPlayPause}>
                            <source
                              src={projectDetails.videoUrl}
                              type="video/mp4"
                            />
                            {t("Your browser does not support the video tag.")}
                          </video>
                          {!isPlaying && (
                            <div
                              onClick={handleVideoPlayPause}
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                cursor: "pointer",
                                zIndex: 1,
                              }}
                            >
                              <img
                                src={PlayIcon}
                                alt="Play Icon"
                                width="68"
                                height={68}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <p className="fs_16 text-center pb-4">
                          {t("No Video Uploaded")}
                        </p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card className="gallery_images_row">
                    <CardBody>
                      <h2 className="fs_20 font-bold text_black">
                        {t("Gallery Images")}
                      </h2>
                      <Row>
                        {projectDetails?.galleryImages?.length === 0 ? (
                          <p className="fs_16 text-center pb-4">
                            {t("No Gallery Images Uploaded")}
                          </p>
                        ) : (
                          projectDetails?.galleryImages?.map((image, index) => (
                            <Col lg={3} key={index}>
                              <div className="single_gallery mt-4">
                                <Link to={image} target="_blank">
                                  <img
                                    src={image}
                                    className="gallery_image"
                                    alt={`gallery image ${index + 1}`}
                                  />
                                </Link>
                              </div>
                            </Col>
                          ))
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectDetails
