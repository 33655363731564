import { createSlice } from "@reduxjs/toolkit"
import {
  createBanner,
  deleteBanner,
  geAllBanners,
  getBannerDetails,
  updateBanner,
  updateBannerStatus,
} from "store/actions/bannersAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 9,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  banners: [],
  bannerDetails: {},
}

const bannersSlice = createSlice({
  name: "Banners", //
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL BANNERS
    builder
      .addCase(geAllBanners.pending, state => {
        state.loading = true
      })
      .addCase(geAllBanners.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.banners = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(geAllBanners.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE BANNER STATUS
    builder
      .addCase(updateBannerStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateBannerStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.banners = state.banners.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateBannerStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // GET BANNER DETAILS
    builder
      .addCase(getBannerDetails.pending, state => {
        state.loading = true
        state.bannerDetails = {}
      })
      .addCase(getBannerDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.bannerDetails = data
        }
      })
      .addCase(getBannerDetails.rejected, (state, action) => {
        state.loading = false
      })

    // DELETE USER
    builder
      .addCase(deleteBanner.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.banners = state.banners.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE BANNER
    builder
      .addCase(createBanner.pending, state => {
        state.isLoading = true
      })
      .addCase(createBanner.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createBanner.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE BANNER
    builder
      .addCase(updateBanner.pending, state => {
        state.isLoading = true
      })
      .addCase(updateBanner.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateBanner.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default bannersSlice.reducer
