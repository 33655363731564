import React from "react"
import PDFIcon from "assets/images/icons/pdf.svg"
import { Link } from "react-router-dom"

const DetailsInnerDiv = ({
  label = "",
  value = "",
  valueClass = "",
  isPdfIcon = false,
  isLink = false,
  redirectLink = "#",
}) => {
  return (
    <div className="details_inner_div">
      <p>{label}</p>
      <h2 className={valueClass}>
        {isLink ? (
          redirectLink ? (
            <Link to={redirectLink} target="_blank">
              {isPdfIcon && (
                <img
                  src={PDFIcon}
                  alt="PDF"
                  style={{
                    position: "relative",
                    top: "-2px",
                    marginRight: 5,
                  }}
                />
              )}
              {value}
            </Link>
          ) : (
            "-"
          )
        ) : (
          value
        )}
      </h2>
    </div>
  )
}

export default DetailsInnerDiv
