import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  del,
  formDataPost,
  formDataPut,
  get,
  patch,
  post,
  put,
} from "helpers/api_helper"
import {
  ASSIGN_SERVICE_PROVIDER,
  CANCEL_SERVICE_REQUEST_STATUS,
  CREATE_MAINTENANCE_USER,
  DELETE_MAINTENANCE_USER,
  FETCH_AVAILABLE_SERVICE_PROVIDER,
  GET_ALL_MAINTENANCE_USERS,
  GET_ALL_RATINGS_FEEDBACK_M_U,
  GET_ALL_SERVICE_REQUEST,
  GET_MAINTENANCE_USER_DETAILS,
  GET_MAINTENANCE_USER_LISTS,
  GET_RATING_FEEDBACK_M_U_DETAILS,
  GET_SERVICE_REQUEST_DETAILS,
  UPDATE_MAINTENANCE_USER,
  UPDATE_MAINTENANCE_USER_STATUS,
} from "helpers/url_helper"

export const getAllMaintenanceUsers = createAsyncThunk(
  GET_ALL_MAINTENANCE_USERS,
  async payload => {
    const response = await post(GET_ALL_MAINTENANCE_USERS, payload)
    return response
  }
)

export const getMaintenanceUsersList = createAsyncThunk(
  GET_MAINTENANCE_USER_LISTS,
  async payload => {
    const response = await post(GET_MAINTENANCE_USER_LISTS, payload)
    return response
  }
)

export const getMaintenanceUserDetails = createAsyncThunk(
  GET_MAINTENANCE_USER_DETAILS,
  async payload => {
    const response = await post(GET_MAINTENANCE_USER_DETAILS, payload)
    return response
  }
)

export const updateMaintenanceUserStatus = createAsyncThunk(
  UPDATE_MAINTENANCE_USER_STATUS,
  async payload => {
    const response = await patch(UPDATE_MAINTENANCE_USER_STATUS, payload)
    return response
  }
)

export const deleteMaintenanceUser = createAsyncThunk(
  DELETE_MAINTENANCE_USER,
  async payload => {
    const response = await del(DELETE_MAINTENANCE_USER, payload)
    return response
  }
)

export const createMaintenanceUser = createAsyncThunk(
  CREATE_MAINTENANCE_USER,
  async payload => {
    const response = await formDataPost(CREATE_MAINTENANCE_USER, payload)
    return response
  }
)

export const updateMaintenanceUser = createAsyncThunk(
  UPDATE_MAINTENANCE_USER,
  async payload => {
    const response = await formDataPut(UPDATE_MAINTENANCE_USER, payload)
    return response
  }
)

// SERVICE REQUEST MANAGEMENT (MAINTENANCE USERS)

export const getAllServiceRequest = createAsyncThunk(
  GET_ALL_SERVICE_REQUEST,
  async payload => {
    const response = await post(GET_ALL_SERVICE_REQUEST, payload)
    return response
  }
)

export const getServiceRequestDetails = createAsyncThunk(
  GET_SERVICE_REQUEST_DETAILS,
  async payload => {
    const response = await post(GET_SERVICE_REQUEST_DETAILS, payload)
    return response
  }
)

export const getAvailableServiceProvider = createAsyncThunk(
  FETCH_AVAILABLE_SERVICE_PROVIDER,
  async payload => {
    const response = await post(FETCH_AVAILABLE_SERVICE_PROVIDER, payload)
    return response
  }
)

export const assignServiceProvider = createAsyncThunk(
  ASSIGN_SERVICE_PROVIDER,
  async payload => {
    const response = await put(ASSIGN_SERVICE_PROVIDER, payload)
    return response
  }
)

export const cancelServiceRequest = createAsyncThunk(
  CANCEL_SERVICE_REQUEST_STATUS,
  async payload => {
    const response = await patch(CANCEL_SERVICE_REQUEST_STATUS, payload)
    return response
  }
)

// RATINGS & FEEDBACK

export const getAllRatingsFeedback = createAsyncThunk(
  GET_ALL_RATINGS_FEEDBACK_M_U,
  async payload => {
    const response = await post(GET_ALL_RATINGS_FEEDBACK_M_U, payload)
    return response
  }
)

export const getRatingsFeedbackDetails = createAsyncThunk(
  GET_RATING_FEEDBACK_M_U_DETAILS,
  async payload => {
    const response = await post(GET_RATING_FEEDBACK_M_U_DETAILS, payload)
    return response
  }
)
