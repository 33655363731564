import {
  commonFilterOptions,
  notificationActionTypeOptions,
  notificationTypeOptions,
} from "components/Common/constants"
import { getDateRange, handleStopPropagation } from "components/Common/Utils"
import Filters from "components/Shared/Filters"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Spinners from "components/Shared/Spinners"
import Title from "components/Shared/Title"
import TopTitle from "components/Shared/TopTitle"
import useFilters from "components/Shared/useFilters"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import {
  getAllNotifications,
  getNotificationDetails,
  updateNotification,
  updateNotificationStatus,
} from "store/actions/notificationsActions"
import EditIcon from "assets/images/icons/edit.svg"
import ViewIcon from "assets/images/icons/view.svg"
import Switch from "components/Shared/Switch"
import Paginations from "components/Common/Pagination"
import IsLoading from "components/Shared/IsLoading"
import { Field, Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import CustomField from "components/Shared/CustomField"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import FormButtons from "components/Shared/FormButtons"
import { updateNotificationSchema } from "components/Common/validation"
import { showAlert } from "store/reducers/alertReducer"

const initialValues = {
  title: "",
  titleAr: "",
  description: "",
  descriptionAr: "",
  remark: "",
  status: null,
}

const Notifications = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Notifications | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const {
    loading,
    isLoading,
    isGettingDetails,
    notificationDetails,
    totalCount,
    totalPages,
    currentPage,
    perPage,
    notifications,
  } = useSelector(state => state.Notifications)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    type: [],
    actionType: [],
  })

  const [isModal, setIsModal] = useState(false)
  const [formValues, setFormValues] = useState(initialValues)

  const toggleModal = () => {
    isModal && setSelectedId(null)
    setIsModal(!isModal)
  }

  useEffect(() => {
    const fetchNotificationDetails = () => {
      const payload = {
        id: selectedId,
      }
      dispatch(getNotificationDetails(payload))
    }
    selectedId && isModal && fetchNotificationDetails()
  }, [selectedId])

  useEffect(() => {
    if (isModal) {
      if (notificationDetails) {
        const { title, body, remark, status } = notificationDetails
        setFormValues({
          title: title?.en || "-",
          titleAr: title?.ar || "-",
          description: body?.en || "-",
          descriptionAr: body?.ar || "-",
          remark,
          status: status === 1 ? true : false,
        })
      }
    } else {
      setFormValues(initialValues)
    }
  }, [notificationDetails])

  const additionOptions = [
    {
      name: "type",
      type: "multiSelect",
      options: notificationTypeOptions.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Type"),
    },
    {
      name: "actionType",
      type: "multiSelect",
      options: notificationActionTypeOptions.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Action Type"),
    },
  ]

  const filterOptions = [...commonFilterOptions, ...additionOptions]

  useEffect(() => {
    fetchAllNotifications()
  }, [])

  const fetchAllNotifications = page => {
    const { status, orderBy, actionType, type } = filters
    const updatedActionType = actionType?.map(item => item.value) || []
    const updatedType = type?.map(item => item.value) || []
    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status,
      orderBy,
      keyword,
      type: updatedType,
      actionType: updatedActionType,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllNotifications(payload))
    setIsApiCalled(true)
    navigate(`/app-notifications?page=${page || pageNumber}`)
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllNotifications(1)
  }

  const onPageChange = page => {
    fetchAllNotifications(page)
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 1 ? "0" : "1",
    }
    dispatch(updateNotificationStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleUpdateNotification = (values, { resetForm }) => {
    const { title, titleAr, description, descriptionAr, remark, status } =
      values
    const payload = {
      id: selectedId,
      title: {
        en: title,
        ar: titleAr,
      },
      body: {
        en: description,
        ar: descriptionAr,
      },
      remark,
      status: status ? "1" : "0",
    }

    dispatch(updateNotification(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          toggleModal()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Notifications")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Notifications List")} />
                <div className="pb-2"></div>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={3}
                />
                {loading ? (
                  <Spinners />
                ) : notifications.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Title")}</th>
                          <th>{t("Description")}</th>
                          <th>{t("Remark")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notifications.map((item, index) => (
                          <tr
                            key={item._id}
                            onClick={() =>
                              navigate(`/app-notifications/details?id=${item._id}`)
                            }
                            className="cursor-pointer"
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>
                              {language === "sa"
                                ? item?.title?.ar
                                : item?.title?.en || "-"}
                            </td>
                            <td>
                              {language === "sa"
                                ? item?.body?.ar
                                : item?.body?.en || "-"}
                            </td>
                            <td>{item?.remark || "-"}</td>
                            <td>
                              {item.status === 1 ? (
                                <p className="rended_status status_tag">
                                  {t("Active")}
                                </p>
                              ) : item.status === 0 ? (
                                <p className="reject_status status_tag">
                                  {t("Deactive")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center gap-3 pe-3">
                                <img
                                  src={EditIcon}
                                  alt="Edit Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    toggleModal()
                                    setSelectedId(item._id)
                                  }}
                                />
                                <img
                                  src={ViewIcon}
                                  alt="View Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    navigate(
                                      `/app-notifications/details?id=${item._id}`
                                    )
                                  }}
                                />
                                <Switch
                                  onClickHandler={() => {
                                    handleStatusClick(item._id, item.status)
                                  }}
                                  isChecked={item.status === 1 ? true : false}
                                  disabled={
                                    isLoading && selectedId === item._id
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && notifications.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={isModal}
        toggle={toggleModal}
        className="g_modal w_850"
        centered
      >
        <h2 className="modal_title">{t("Update App Notification Details")}</h2>
        <button type="button" onClick={toggleModal} className="modal_close_btn">
          <i className="mdi mdi-close"></i>
        </button>
        {isGettingDetails ? (
          <IsLoading />
        ) : (
          <div>
            <Formik
              initialValues={formValues}
              onSubmit={handleUpdateNotification}
              validationSchema={updateNotificationSchema}
              enableReinitialize={true}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form>
                  <Row>
                    <Col lg={6}>
                      <div className="form_field">
                        <CustomLabel
                          htmlFor="title"
                          label={t("Title(English)")}
                          isRequired={true}
                        />
                        <CustomField
                          name="title"
                          type="text"
                          placeholder={t("Title(English)")}
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form_field">
                        <CustomLabel
                          htmlFor="titleAr"
                          label={t("Title(عربي)")}
                          isRequired={true}
                        />
                        <CustomField
                          name="titleAr"
                          type="text"
                          placeholder={t("Title(عربي)")}
                          touched={touched}
                          errors={errors}
                          isArabic={true}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form_field">
                        <CustomLabel
                          htmlFor="description"
                          label={t("Description(English)")}
                          isRequired={true}
                        />
                        <Field
                          as="textarea"
                          id="description"
                          name="description"
                          rows={5}
                          placeholder={t("Description(English)")}
                          className={`form-control b_r_12 ${
                            errors.description && touched.description
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <CustomErrorMessage name={"description"} />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form_field">
                        <CustomLabel
                          htmlFor="descriptionAr"
                          label={t("Description(عربي)")}
                          isRequired={true}
                        />
                        <Field
                          as="textarea"
                          id="descriptionAr"
                          name="descriptionAr"
                          rows={5}
                          placeholder={t("Description(عربي)")}
                          className={`form-control b_r_12 ${
                            errors.descriptionAr && touched.descriptionAr
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <CustomErrorMessage name={"descriptionAr"} />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form_field">
                        <CustomLabel htmlFor="remark" label={t("Remarks")} />
                        <CustomField
                          name="remark"
                          type="text"
                          placeholder={t("Remark")}
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form_field">
                        <CustomLabel htmlFor="status" label={t("Status")} />
                      </div>
                      <Switch
                        onClickHandler={() => {
                          setFieldValue("status", !values.status)
                        }}
                        isChecked={values.status ? true : false}
                      />
                    </Col>
                    <Col lg={12}>
                      <div className="d-flex justify-content-end gap-3 mt-3">
                        <button
                          type="button"
                          className="cancel_btn"
                          onClick={toggleModal}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          className="primary_btn"
                          type="submit"
                          disabled={isLoading}
                        >
                          {t("Update")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default Notifications
