import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import { ascDescOptions, paymentStatus } from "components/Common/constants"
import { useDispatch, useSelector } from "react-redux"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Paginations from "components/Common/Pagination"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { Field, Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import {
  DownloadCSV,
  DownloadXLSX,
  fileAccept,
  getDateRange,
  handleFileUpload,
} from "components/Common/Utils"
import {
  cancelBookingSchema,
  deliveryLetterSchema,
} from "components/Common/validation"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import {
  cancelBooking,
  getAllBuyingPayments,
  getBuyingPaymentDetails,
  uploadDeliveryLetter,
} from "store/actions/buyingPaymentsAction"
import Spinners from "components/Shared/Spinners"
import ViewIcon from "assets/images/icons/view.svg"
import FormateDate from "components/Shared/FormateDate"
import IsLoading from "components/Shared/IsLoading"
import FileUpload from "components/Shared/FileUpload"
import { showAlert } from "store/reducers/alertReducer"
import { getUnitsList } from "store/actions/unitsAction"
import { exportData } from "helpers/api_helper"
import { EXPORT_DATA } from "helpers/url_helper"
import CustomField from "components/Shared/CustomField"

const DeliveryLetter = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Delivery Letter | Takween")

  const { search } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    isGettingDetails,
    buyingPayments,
    buyingPaymentDetails,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.BuyingPayments)
  const { unitsList } = useSelector(state => state.Units)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    orderBy: 2,
    unit: [],
    status: -1,
  })

  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [cancelModal, setCancelModal] = useState(false)

  useEffect(() => {
    fetchAllBuyingPayments()
    fetchUnitsList()
  }, [])

  const fetchAllBuyingPayments = page => {
    const { orderBy, unit, status } = filters
    const updatedUnits = unit?.map(item => item.value) || []

    const payload = {
      perPage,
      pageNo: page || pageNumber,
      orderBy,
      status: Number(status),
      unit: updatedUnits,
      customer: [], // STATIC
      paymentType: [5], //5: Delivery Letter
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllBuyingPayments(payload))
    setIsApiCalled(true)
    navigate(
      keyword ? `/delivery-letter?keyword=${keyword}` : `/delivery-letter`
    )
  }

  const fetchUnitsList = () => {
    const payload = {
      project: [],
      statusType: [1, 2, 3, 4],
    }
    dispatch(getUnitsList(payload))
  }

  useEffect(() => {
    modal && modalType === "VIEW_DETAILS" && fetchPaymentDetails()
  }, [modal, modalType])

  const additionOptions = [
    {
      name: "status",
      type: "select",
      options: paymentStatus.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Status"),
    },
    {
      name: "unit",
      type: "multiSelect",
      options: unitsList.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Select Units"),
    },
  ]

  const filterOptions = [...ascDescOptions, ...additionOptions]

  const fetchPaymentDetails = () => {
    const payload = {
      id: selectedId,
    }
    dispatch(getBuyingPaymentDetails(payload))
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllBuyingPayments(1)
  }

  const onPageChange = page => {
    fetchAllBuyingPayments(page)
  }

  const toggle = () => {
    modal && setModalType(null)
    setModal(!modal)
  }

  const handleUploadDeliveryLetter = values => {
    const { file, contents } = values

    let data = new FormData()
    data.append("id", selectedId)
    // data.append("contents", contents)
    data.append("file", file)

    dispatch(uploadDeliveryLetter({ data, id: selectedId }))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
          toggle()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const getExportFilters = () => {
    const { unit, status } = filters
    const updatedUnits = unit?.map(item => item.value) || []

    return {
      status: status,
      unit: updatedUnits,
      customer: [],
      paymentType: [5],
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "BuyingDetails",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Delivery Letter"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "BuyingDetails",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Delivery Letter"))
    }
  }

  const toggleCancelModal = () => {
    cancelModal && setSelectedId(null)
    setCancelModal(!cancelModal)
  }

  const handleCancelBooking = values => {
    const { note } = values
    const payload = {
      id: selectedId,
      note,
    }
    dispatch(cancelBooking(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          toggleCancelModal()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Delivery Letter")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={4}
                />
                {loading ? (
                  <Spinners />
                ) : buyingPayments.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Name")}</th>
                          {/* <th>{t("Last Name")}</th> */}
                          <th>{t("Unit Name")}</th>
                          <th>{t("Booking Id")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {buyingPayments.map((item, index) => (
                          <tr key={item._id}>
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{item?.userData?.name}</td>
                            {/* <td>{item?.buyerDetails?.lastName}</td> */}
                            <td>
                              {language === "sa"
                                ? item?.unitData?.nameAr
                                : item?.unitData?.name || "-"}
                            </td>
                            <td>{item.bookingNo || "-"}</td>
                            <td>
                              {!item?.deliveryLetterDetails?.uploadAt ? (
                                <p className="sold_status status_tag">
                                  {t("Pending")}
                                </p>
                              ) : item?.deliveryLetterDetails?.uploadAt ? (
                                <p className="rended_status status_tag">
                                  {t("Sent")}
                                </p>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <img
                                src={ViewIcon}
                                alt="Trash Icon"
                                width={24}
                                height={24}
                                className="cursor-pointer me-3"
                                onClick={() => {
                                  setSelectedId(item._id)
                                  setModalType("VIEW_DETAILS")
                                  toggle()
                                }}
                              />
                              {!item?.deliveryLetterDetails?.uploadAt && (
                                <button
                                  className="upload_btn"
                                  onClick={() => {
                                    setSelectedId(item._id)
                                    toggle()
                                    setModalType("UPLOAD_DELIVERY_LETTER")
                                  }}
                                >
                                  {t("Upload")}
                                </button>
                              )}

                              {item?.canCancelBooking ? (
                                <button
                                  className="reject_btn fit_content me-5 ms-2"
                                  onClick={() => {
                                    toggleCancelModal()
                                    setSelectedId(item._id)
                                  }}
                                >
                                  {t("Cancel Booking")}
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && buyingPayments.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        className={`g_modal ${
          modalType === "VIEW_DETAILS" ? "w_850" : "w_600"
        }`}
      >
        <h2 className="modal_title">
          {modalType === "VIEW_DETAILS"
            ? t("Delivery Letters Details")
            : t("Upload Delivery Letter")}
        </h2>
        <button type="button" onClick={toggle} className="modal_close_btn">
          <i className="mdi mdi-close"></i>
        </button>
        <div>
          {/* MODAL FOR VIEW DETAILS */}
          {modalType === "VIEW_DETAILS" &&
            (isGettingDetails ? (
              <IsLoading />
            ) : (
              <Row>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Name")}
                    value={buyingPaymentDetails?.userId?.name || "-"}
                  />
                </Col>
                <Col md={4}>
                <DetailsInnerDiv
                  label={t("Phone Number")}
                  value={buyingPaymentDetails?.userId?.phone || "-"}
                />
              </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Email")}
                    value={buyingPaymentDetails?.buyerDetails?.email || "-"}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Unit Name")}
                    value={
                      language === "sa"
                        ? buyingPaymentDetails?.unitId?.nameAr
                        : buyingPaymentDetails?.unitId?.name || "-"
                    }
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Booking Number")}
                    value={buyingPaymentDetails?.bookingNo || "-"}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Status")}
                    value={
                      !buyingPaymentDetails?.deliveryLetterDetails?.uploadAt
                        ? t("Pending")
                        : buyingPaymentDetails?.deliveryLetterDetails?.uploadAt
                        ? t("Sent")
                        : "-"
                    }
                    valueClass={
                      !buyingPaymentDetails?.deliveryLetterDetails?.uploadAt
                        ? "pending_color"
                        : buyingPaymentDetails?.deliveryLetterDetails?.uploadAt
                        ? "active_color"
                        : "-"
                    }
                  />
                </Col>
                {buyingPaymentDetails?.deliveryLetterDetails?.attachment && (
                  <Col md={4}>
                    <DetailsInnerDiv
                      label={t("Attachments")}
                      value={t("View")}
                      isPdfIcon={true}
                      isLink={true}
                      redirectLink={
                        buyingPaymentDetails?.deliveryLetterDetails?.attachment
                      }
                    />
                  </Col>
                )}
                {/* {buyingPaymentDetails?.deliveryLetterDetails?.contents && (
                  <Col md={12}>
                    <DetailsInnerDiv
                      label={t("Contents")}
                      value={buyingPaymentDetails?.contents || "-"}
                    />
                  </Col>
                )} */}
                {buyingPaymentDetails?.deliveryLetterDetails?.uploadAt && (
                  <Col md={4}>
                    <DetailsInnerDiv
                      label={t("Delivery Letter Uploaded At")}
                      value={FormateDate(
                        buyingPaymentDetails?.deliveryLetterDetails?.uploadAt
                      )}
                    />
                  </Col>
                )}
                {buyingPaymentDetails?.deliveryLetterDetails
                  ?.acceptedByCustomerAt && (
                  <Col md={4}>
                    <DetailsInnerDiv
                      label={t("Accepted By Customer At")}
                      value={FormateDate(
                        buyingPaymentDetails?.deliveryLetterDetails
                          ?.acceptedByCustomerAt
                      )}
                    />
                  </Col>
                )}

                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Created At")}
                    value={FormateDate(buyingPaymentDetails?.createdAt)}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Updated At")}
                    value={FormateDate(buyingPaymentDetails?.updatedAt)}
                  />
                </Col>
              </Row>
            ))}

          {/* UPLOAD DELIVERY LETTER */}
          {modalType === "UPLOAD_DELIVERY_LETTER" && (
            <Row>
              <Col lg={12}>
                <Formik
                  initialValues={{ attachment: "", file: "" }}
                  validationSchema={deliveryLetterSchema}
                  onSubmit={handleUploadDeliveryLetter}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                      <Row>
                        {/* <Col lg={12}>
                          <div className="form_field mb-0">
                            <CustomLabel
                              htmlFor="contents"
                              label={t("Contents")}
                            />
                            <Field
                              as="textarea"
                              id="contents"
                              name="contents"
                              rows={4}
                              placeholder={t("Contents")}
                              className={`form-control`}
                            />
                          </div>
                        </Col> */}
                        <Col lg={12}>
                          <div className="form_field mb-0">
                            <CustomLabel
                              htmlFor="attachment"
                              label={t("Upload File")}
                              isRequired={true}
                            />
                            <FileUpload
                              name="file"
                              value={values.file}
                              touched={touched.file}
                              error={errors.file}
                              accept={".pdf"}
                              customClasses="h_72"
                              labelText={t("Upload File")}
                              setFieldValue={setFieldValue}
                              showNameOnly={true}
                              handleFileUpload={event =>
                                handleFileUpload(event, setFieldValue, "file")
                              }
                            />
                            <CustomErrorMessage name={"file"} />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <button
                            className="save_btn mt-4 ms-auto d-block"
                            type="submit"
                            disabled={isLoading}
                          >
                            {t("Upload")}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={cancelModal}
        toggle={toggleCancelModal}
        centered
        className={`g_modal w_600`}
      >
        <h2 className="modal_title">{t("Cancel Booking")}</h2>
        <button
          type="button"
          onClick={toggleCancelModal}
          className="modal_close_btn"
        >
          <i className="mdi mdi-close"></i>
        </button>
        <div>
          <Formik
            initialValues={{
              note: "",
            }}
            validationSchema={cancelBookingSchema}
            onSubmit={handleCancelBooking}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                  <Col lg={12}>
                    <div className="form_field">
                      <CustomLabel
                        htmlFor="note"
                        label={t("Note")}
                        isRequired={true}
                      />
                      <CustomField
                        name="note"
                        type="text"
                        placeholder={t("Note")}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mt-3">
                      <button
                        className="primary_btn ms-auto d-block"
                        type="submit"
                        disabled={isLoading}
                      >
                        {t("Cancel Booking")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DeliveryLetter
