import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const FormButtons = ({ buttonText, isLoading = false, isCancelBtn = true }) => {
  const navigate = useNavigate()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  return (
    <div className="d-flex justify-content-end mt-3">
      {isCancelBtn && (
        <button
          type="button"
          className="cancel_btn mx-2"
          onClick={() => navigate(-1)}
        >
          {t("Cancel")}
        </button>
      )}
      <button className="primary_btn" type="submit" disabled={isLoading}>
        {buttonText}
      </button>
    </div>
  )
}

export default FormButtons
