import { createSlice } from "@reduxjs/toolkit"
import {
  userDetails,
  login,
  changeUserPassword,
  forgotPassword,
  verifyOtp,
  resetPassword,
  validateUUID,
  resetPasswordForNewUser,
  updateUserDetails,
  logout,
  changeDefaultLanguage,
} from "store/actions/authAction"

const initialState = {
  isLoggedIn: false,
  loading: false,
  isLoading: false,
  userType: null,
  authUser: {},
  userProfile: {},
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // TEMPORARY
    // setUserType(state, action) {
    //   state.userType = action.payload
    // },
  },
  extraReducers: builder => {
    // LOGIN API
    builder
      .addCase(login.pending, state => {
        state.loading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.authUser = data
          state.userProfile = data
          state.isLoggedIn = true
          localStorage.setItem("authUser", JSON.stringify(data))
          localStorage.setItem("isLoggedIn", true)
          localStorage.setItem("userType", data?.accountType)
          state.userType = data?.accountType
          // state.userType = "superadmin" // finance, sales,  marketing, customerService, superadmin
          if (data?.accountType === "mowatheq") {
            action.meta.arg.history("/profile")
          } else {
            action.meta.arg.history("/dashboard")
          }
        }
      })
      .addCase(login.rejected, state => {
        state.loading = false
      })

    // GET USER PROFILE DETAILS
    builder
      .addCase(userDetails.pending, state => {
        state.loading = true
      })
      .addCase(userDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.userProfile = data
        }
      })
      .addCase(userDetails.rejected, (state, action) => {
        state.loading = false
      })

    // CHANGE USER PASSWORD
    builder
      .addCase(changeUserPassword.pending, state => {
        state.isLoading = true
      })
      .addCase(changeUserPassword.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.isLoading = false
      })

    // FORGOT PASSWORD
    builder
      .addCase(forgotPassword.pending, state => {
        state.isLoading = true
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false
      })

    // VERIFY OTP
    builder
      .addCase(verifyOtp.pending, state => {
        state.isLoading = true
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.isLoading = false
      })

    // RESET PASSWORD
    builder
      .addCase(resetPassword.pending, state => {
        state.isLoading = true
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false
      })

    // VALIDATE UUID
    builder
      .addCase(validateUUID.pending, state => {
        state.isLoading = true
      })
      .addCase(validateUUID.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(validateUUID.rejected, (state, action) => {
        state.isLoading = false
      })

    // RESET PASSWORD FOR NEW USER
    builder
      .addCase(resetPasswordForNewUser.pending, state => {
        state.isLoading = true
      })
      .addCase(resetPasswordForNewUser.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(resetPasswordForNewUser.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE USER DETAILS
    builder
      .addCase(updateUserDetails.pending, state => {
        state.isLoading = true
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.isLoading = false
      })

    // LOGOUT
    builder
      .addCase(logout.pending, state => {
        state.isLoading = true
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false
      })

    // CHANGE LANGUAGE
    builder
      .addCase(changeDefaultLanguage.pending, state => {
        state.isLoading = true
      })
      .addCase(changeDefaultLanguage.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(changeDefaultLanguage.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

// export const { setUserType } = authSlice.actions

export default authSlice.reducer
