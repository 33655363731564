import DetailsTitle from "components/Shared/DetailsTitle"
import TopTitle from "components/Shared/TopTitle"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import QRCode from "qrcode.react"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"
import { Form, Formik } from "formik"
import Select from "react-select"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import Switch from "components/Shared/Switch"
import CustomLabel from "components/Shared/CustomLabel"
import { generateQrCodeSchema } from "components/Common/validation"
import { useSelector } from "react-redux"

const initialValues = {
  projectId: "",
  type: "",
}
const GenerateQRCode = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Generate QRCode | Takween")
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [formValues, setFormValues] = useState(initialValues)
  const [qrCodeLink, setQrCodeLink] = useState("")
  const [projectId, setProjectId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [projectName, setProjectName] = useState(null)
  const qrCodeRef = useRef(null)
  const [isShowProjectName, setIsShowProjectName] = useState(false)
  const ratingType = [{ label: t("Download App"), value: "downloadApp" }]

  const handleOnSubmit = async (values, { resetForm }) => {
    console.log(values)
  }

  const projectLists = [
    { id: 1, name: "Details-Al-Malaqa" },
    { id: 2, name: "Details-Al-Aqsa" },
    { id: 3, name: "Skyline Towers" },
  ]

  const exportToPDF = async () => {
    try {
      setIsExporting(true)

      if (qrCodeRef.current) {
        const canvas = await html2canvas(qrCodeRef.current, { scale: 5 })
        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF()
        const imgProps = pdf.getImageProperties(imgData)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight)
        pdf.save(`${t("QR-Code")}.pdf`)
      }
    } catch (error) {
      console.error("Error exporting PDF:", error)
    } finally {
      setIsExporting(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content customer_details">
        <Container fluid>
          <TopTitle
            title={t("QR Code")}
            subTitle={t(`Hi, {{name}}. Welcome back to your Customer service Portal!`, {
              name: name,
            })}
          />
          <Row>
            <Col lg={6}>
              <Card className="full_height_card">
                <CardBody>
                  <h2 className="fs_18_700">{t("Generate QR Code")}</h2>
                  <Formik
                    initialValues={formValues}
                    onSubmit={handleOnSubmit}
                    validationSchema={generateQrCodeSchema}
                  >
                    {({
                      values,
                      setFieldValue,
                      errors,
                      touched,
                      isSubmitting,
                    }) => (
                      <Form>
                        <div className="row g_label">
                          <div className="col-lg-12">
                            <div className="mt-3">
                              <CustomLabel
                                htmlFor="projectId"
                                label={t("Select Branch")}
                                isRequired={true}
                              />
                              <label htmlFor="">{t("")}</label>
                              <Select
                                value={values.locations}
                                onChange={item => {
                                  setFieldValue("projectId", item)
                                }}
                                options={projectLists?.map(item => ({
                                  label: item.name,
                                  value: item._id,
                                }))}
                                className={`select2-selection b_r_12 ${
                                  touched.projectId && errors.projectId
                                    ? "danger_color_border"
                                    : ""
                                } `}
                                placeholder={t("Select Project")}
                              />
                              <CustomErrorMessage name={"projectId"} />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mt-3">
                              <CustomLabel
                                htmlFor="type"
                                label={t("Select QR Type")}
                                isRequired={true}
                              />

                              <Select
                                value={values.locations}
                                onChange={item => {
                                  setFieldValue("type", item)
                                }}
                                options={ratingType?.map(item => ({
                                  label: item.label,
                                  value: item.value,
                                }))}
                                className={`select2-selection b_r_12 ${
                                  touched.type && errors.type
                                    ? "danger_color_border"
                                    : ""
                                } `}
                                placeholder={t("Select QR Type")}
                              />
                              <CustomErrorMessage name={"type"} />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mt-3">
                              <CustomLabel
                                htmlFor="isShowProjectName"
                                label={t(
                                  "Do You Want To Print Project Name Underneath The QR Code"
                                )}
                              />

                              <Switch
                                isChecked={isShowProjectName}
                                onClickHandler={() =>
                                  setIsShowProjectName(!isShowProjectName)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12">
                            <button
                              type="submit"
                              className="d-block ms-auto primary_btn"
                              disabled={isSubmitting}
                            >
                              {t("Generate QR Code")}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className="full_height_card qr_code_view">
                <CardBody className="g_center">
                  {qrCodeLink ? (
                    <div>
                      <div ref={qrCodeRef} className="px-4 pb-2">
                        <div className="qr_code">
                          <QRCode
                            value={qrCodeLink}
                            size={250}
                            level="H"
                            includeMargin={true}
                            renderAs="svg"
                          />
                        </div>

                        {isShowProjectName && (
                          <p className="text-center fw-bold fs-5 text-black">
                            {projectName}
                          </p>
                        )}
                      </div>
                      <div className="d-flex">
                        <button
                          className="primary_btn mx-auto d-block mt-3"
                          onClick={exportToPDF}
                          disabled={!qrCodeLink || isExporting}
                        >
                          {t("Download To PDF")}
                        </button>
                        <button
                          className="primary_btn mx-auto d-block mt-3"
                          //   onClick={handelSave}
                          disabled={isLoading}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="px-4 pb-2">
                      <div className="qr_code">
                        <QRCode
                          value="https://example.com"
                          size={250}
                          level="H"
                          includeMargin={true}
                          renderAs="svg"
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GenerateQRCode
