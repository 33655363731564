import { createSlice } from "@reduxjs/toolkit"
import {
  createUnit,
  deleteUnit,
  getAllUnits,
  getUnitDetails,
  getUnitsList,
  updateUnit,
  updateUnitStatus,
  uploadUnitGallery,
} from "store/actions/unitsAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  units: [],
  unitsList: [],
  unitDetails: {},
}
const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllUnits.pending, state => {
        state.loading = true
      })
      .addCase(getAllUnits.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.units = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllUnits.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getUnitsList.pending, state => {})
      .addCase(getUnitsList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.unitsList = data
        }
      })
      .addCase(getUnitsList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getUnitDetails.pending, state => {
        state.loading = true
      })
      .addCase(getUnitDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.unitDetails = data
        }
      })
      .addCase(getUnitDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateUnitStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateUnitStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.units = state?.units?.map(item =>
            item._id === id ? { ...item, status: data?.status } : item
          )
        }
      })
      .addCase(updateUnitStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteUnit.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteUnit.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.units = state.units.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteUnit.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createUnit.pending, state => {
        state.isLoading = true
      })
      .addCase(createUnit.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createUnit.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateUnit.pending, state => {
        state.isLoading = true
      })
      .addCase(updateUnit.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateUnit.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPLOAD GALLERY IMAGES
    builder
      .addCase(uploadUnitGallery.pending, state => {
        state.isLoading = true
      })
      .addCase(uploadUnitGallery.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(uploadUnitGallery.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default unitsSlice.reducer
