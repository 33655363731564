import { createSlice } from "@reduxjs/toolkit"
import {
  createService,
  deleteService,
  getAllServices,
  getServiceDetails,
  getServicesList,
  updateService,
  updateServiceStatus,
} from "store/actions/servicesAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  services: [],
  servicesList: [],
  serviceDetails: {},
}
const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllServices.pending, state => {
        state.loading = true
      })
      .addCase(getAllServices.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.services = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllServices.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getServicesList.pending, state => {})
      .addCase(getServicesList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.servicesList = data
        }
      })
      .addCase(getServicesList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getServiceDetails.pending, state => {
        state.loading = true
      })
      .addCase(getServiceDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.serviceDetails = data
        }
      })
      .addCase(getServiceDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateServiceStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateServiceStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.services = state.services.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateServiceStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteService.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.services = state.services.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createService.pending, state => {
        state.isLoading = true
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createService.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateService.pending, state => {
        state.isLoading = true
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateService.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default servicesSlice.reducer
