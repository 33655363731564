import { ascDescOptions, baseFilterOptions } from "components/Common/constants"
import Filters from "components/Shared/Filters"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Spinners from "components/Shared/Spinners"
import Title from "components/Shared/Title"
import TopTitle from "components/Shared/TopTitle"
import useFilters from "components/Shared/useFilters"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Card, Col, Container, Row, Table } from "reactstrap"
import ViewIcon from "assets/images/icons/view.svg"
import TrashIcon from "assets/images/icons/trash.svg"
import {
  getDateRange,
  getTimeAgo,
  handleStopPropagation,
} from "components/Common/Utils"
import { handleNotificationClick } from "components/Common/NotificationUtils"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import {
  deleteAdminNotification,
  deleteAllAdminNotification,
  getAdminNotifications,
  markAdminAllNotificationAsRead,
  markAdminNotificationAsRead,
} from "store/actions/adminNotificationAction"
import { showAlert } from "store/reducers/alertReducer"

const Notifications = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Notifications | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const {
    loading,
    isLoading,
    totalCount,
    totalPages,
    currentPage,
    perPage,
    adminNotifications,
    isReadingAll,
    isDeletingAll,
  } = useSelector(state => state.AdminNotifications)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
  })

  const [showAllDeleteModal, setShowAllDeleteModal] = useState(false)

  const additionOptions = [
    {
      name: "status",
      type: "select",
      options: [
        { label: t("All"), value: -1 },
        { label: t("Un Read"), value: 0 },
        { label: t("Read"), value: 1 },
      ],
      placeholder: t("All"),
    },
  ]

  const filterOptions = [...ascDescOptions, ...additionOptions]

  useEffect(() => {
    fetchAllNotifications()
  }, [])

  const fetchAllNotifications = page => {
    const { status, orderBy } = filters

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: Number(status), //-1 all , 0 unread, 1 read
      orderBy,
      dateRange: getDateRange(dateRange),
    }

    dispatch(getAdminNotifications(payload))
    setIsApiCalled(true)
    navigate(`/notifications?page=${page || pageNumber}`)
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllNotifications(1)
  }

  const onPageChange = page => {
    fetchAllNotifications(page)
  }

  const toggleDeleteAllModal = () => {
    setShowAllDeleteModal(!showAllDeleteModal)
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deleteAdminNotification(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDeleteAll = () => {
    const payload = {
      status: "2",
    }
    dispatch(deleteAllAdminNotification(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          toggleDeleteAllModal()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleReadAllNotifications = () => {
    const payload = {
      status: "1",
    }
    dispatch(markAdminAllNotificationAsRead(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleClick = (id, status) => {
    if (status === 1) {
      // REDIRECT ON DETAILS IF ALREADY READ NOTIFICATION
      navigate(`/notifications/details?id=${id}`)
    } else {
      const payload = {
        id: id,
        status: "1",
      }
      dispatch(markAdminNotificationAsRead(payload))
        .then(action => {})
        .catch(error => {})

      navigate(`/notifications/details?id=${id}`)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Notifications")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Notifications")}
                  isDeleteBtn={adminNotifications?.length > 0 ? true : false}
                  deleteButtonText={t("Delete All")}
                  handleDeleteClick={toggleDeleteAllModal}
                  isAddBtn={adminNotifications.length > 0 ? true : false}
                  addBtnText={t("Read All")}
                  isLoading={isReadingAll}
                  onAddBtnClick={handleReadAllNotifications}
                />
                <div className="pb-2"></div>
                <Filters
                  isGlobalFilter={false}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                />
                {loading ? (
                  <Spinners />
                ) : adminNotifications.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0 full_th admin_notifications">
                      <thead className="">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Title")}</th>
                          <th>{t("Description")}</th>
                          <th>{t("Date")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {adminNotifications.map((item, index) => (
                          <tr
                            key={item._id}
                            onClick={() => handleClick(item._id, item.status)}
                            className={`cursor-pointer ${
                              item.status === 0 ? "unread" : "read"
                            }`}
                          >
                            <th scope="row">
                              {/* {(currentPage - 1) * perPage + index + 1} */}
                              {index + 1}
                            </th>
                            <td>
                              {language === "sa"
                                ? item?.title?.ar
                                : item?.title?.en || "-"}
                            </td>
                            <td>
                              {language === "sa"
                                ? item?.body?.ar
                                : item?.body?.en || "-"}
                            </td>
                            <td>{getTimeAgo(item.createdAt) || "-"}</td>
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center gap-3">
                                <img
                                  src={ViewIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleClick(item._id, item.status)
                                  }
                                />
                                <img
                                  src={TrashIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setItemIdToDelete(item._id)
                                    setShowDeleteModal(true)
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && adminNotifications.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("Notification"),
        })}
      />
      <DeleteModal
        show={showAllDeleteModal}
        onDeleteClick={handleDeleteAll}
        onCloseClick={toggleDeleteAllModal}
        isLoading={isLoading}
        buttonText={t("Delete All")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("All Notifications"),
        })}
      />
    </React.Fragment>
  )
}

export default Notifications
