import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import {
  CREATE_CUSTOMER_SUPPORT_SUBJECT,
  DELETE_CUSTOMER_SUPPORT_SUBJECT,
  GET_ALL_CUSTOMER_SUPPORT,
  GET_ALL_CUSTOMER_SUPPORT_SUBJECT,
  GET_CUSTOMER_SUPPORT_DETAILS,
  GET_CUSTOMER_SUPPORT_SUBJECT_DETAILS,
  GET_CUSTOMER_SUPPORT_SUBJECT_LIST,
  UPDATE_CUSTOMER_SUPPORT_STATUS,
  UPDATE_CUSTOMER_SUPPORT_SUBJECT,
  UPDATE_CUSTOMER_SUPPORT_SUBJECT_STATUS,
} from "helpers/url_helper"

export const getAllCustomerSupport = createAsyncThunk(
  GET_ALL_CUSTOMER_SUPPORT,
  async payload => {
    const response = await post(GET_ALL_CUSTOMER_SUPPORT, payload)
    return response
  }
)

export const updateCustomerSupportStatus = createAsyncThunk(
  UPDATE_CUSTOMER_SUPPORT_STATUS,
  async payload => {
    const response = await patch(UPDATE_CUSTOMER_SUPPORT_STATUS, payload)
    return response
  }
)

export const getCustomerSupportDetails = createAsyncThunk(
  GET_CUSTOMER_SUPPORT_DETAILS,
  async payload => {
    const response = await post(GET_CUSTOMER_SUPPORT_DETAILS, payload)
    return response
  }
)

// CUSTOMER SUPPORT SUBJECT

export const getAllCustomerSupportSubject = createAsyncThunk(
  GET_ALL_CUSTOMER_SUPPORT_SUBJECT,
  async payload => {
    const response = await post(GET_ALL_CUSTOMER_SUPPORT_SUBJECT, payload)
    return response
  }
)

export const getCustomerSupportSubjectList = createAsyncThunk(
  GET_CUSTOMER_SUPPORT_SUBJECT_LIST,
  async payload => {
    const response = await post(GET_CUSTOMER_SUPPORT_SUBJECT_LIST, payload)
    return response
  }
)

export const getCustomerSupportSubjectDetails = createAsyncThunk(
  GET_CUSTOMER_SUPPORT_SUBJECT_DETAILS,
  async payload => {
    const response = await post(GET_CUSTOMER_SUPPORT_SUBJECT_DETAILS, payload)
    return response
  }
)

export const updateCustomerSupportSubjectStatus = createAsyncThunk(
  UPDATE_CUSTOMER_SUPPORT_SUBJECT_STATUS,
  async payload => {
    const response = await patch(
      UPDATE_CUSTOMER_SUPPORT_SUBJECT_STATUS,
      payload
    )
    return response
  }
)

export const deleteCustomerSupportSubject = createAsyncThunk(
  DELETE_CUSTOMER_SUPPORT_SUBJECT,
  async payload => {
    const response = await del(DELETE_CUSTOMER_SUPPORT_SUBJECT, payload)
    return response
  }
)

export const createCustomerSupportSubject = createAsyncThunk(
  CREATE_CUSTOMER_SUPPORT_SUBJECT,
  async payload => {
    const response = await post(CREATE_CUSTOMER_SUPPORT_SUBJECT, payload)
    return response
  }
)

export const updateCustomerSupportSubject = createAsyncThunk(
  UPDATE_CUSTOMER_SUPPORT_SUBJECT,
  async payload => {
    const response = await put(UPDATE_CUSTOMER_SUPPORT_SUBJECT, payload)
    return response
  }
)
