import React from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Row } from "reactstrap"
import complainIcon from "assets/images/icons/sales/dashboardComplaints.svg"
import deedIcon from "assets/images/icons/sales/dashboardDeed.svg"
import deliveryIcon from "assets/images/icons/sales/dashboardDelivery.svg"
import unitIcon from "assets/images/icons/sales/dashboardUnit.svg"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

const DashboardTopBar = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
 
  const salesDashboardTopBar = [
    {
      id: 1,
      imgUrl: deliveryIcon,
      text: t("Delivery Requests"),
      redirectLink: "/delivery-letter",
    },
    {
      id: 2,
      imgUrl: unitIcon,
      text: t("Water & Bill Submissions"),
      redirectLink: "/water-and-bill-submission",
    },
    {
      id: 3,
      imgUrl: deedIcon,
      text: t("Deed Records"),
      redirectLink: "/deed-records",
    },
    {
      id: 4,
      imgUrl: complainIcon,
      text: t("Customer Support"),
      redirectLink: "/customer-support",
    },
  ]

  return (
    <Card className="dashboard_top_bar">
      <CardBody>
        <Row>
          <h2>{t("What's going on Dashboard?")}</h2>
          <p className="mt-2">{t("Mange delivery letter uploads, water bill submissions and many more.")}</p>
          {salesDashboardTopBar &&
            salesDashboardTopBar.map(item => (
              <Col lg={3} key={item.id}>
                <Link to={item.redirectLink}>
                  <div className="inner_section">
                    <img src={item.imgUrl} alt="unit icon" />
                    <h3>{t(item.text)}</h3>
                  </div>
                </Link>
              </Col>
            ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default DashboardTopBar
