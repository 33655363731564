import React, { useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import FormateDate from "components/Shared/FormateDate"
import { Link, useLocation } from "react-router-dom"
import DetailsTitle from "components/Shared/DetailsTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import { getTransactionDetails } from "store/actions/transactionsAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import {
  paymentStatusOptions,
  paymentTypes,
  transactionsTypes,
} from "components/Common/constants"

const PaymentHistoryDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Payment History Details | Takween")

  const dispatch = useDispatch()
  const { loading, transactionDetails } = useSelector(
    state => state.Transactions
  )

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getTransactionDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content details_view">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Payment History Details")} />
                  <div>
                    {loading ? (
                      <ShimmerThumbnail
                        height={350}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(transactionDetails).length === 0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <Row className="g_details">
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Id")}
                            value={transactionDetails._id}
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Customer Name")}
                            value={transactionDetails?.userId?.name || "-"}
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Customer Phone Number")}
                            value={transactionDetails?.userId?.phone || "-"}
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Unit Name")}
                            value={transactionDetails?.unitId?.name || "-"}
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Amount")}
                            value={`${transactionDetails?.amount ?? "-"} ${
                              transactionDetails?.currency || "-"
                            }`}
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Payment Method")}
                            value={t(transactionDetails?.paymentMethod || "-")}
                            valueClass="text-capitalize"
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Payment Status")}
                            value={
                              t(
                                paymentStatusOptions.find(
                                  item =>
                                    item.value ===
                                    transactionDetails?.paymentStatus
                                )?.label
                              ) || "-"
                            }
                            valueClass={
                              transactionDetails?.paymentStatus === 0
                                ? "pending_color"
                                : transactionDetails?.paymentStatus === 1
                                ? "active_color"
                                : transactionDetails?.paymentStatus === 2
                                ? "danger_color"
                                : ""
                            }
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Payment Type")}
                            value={
                              t(
                                paymentTypes.find(
                                  item =>
                                    item.value ===
                                    transactionDetails?.paymentType
                                )?.label
                              ) || "-"
                            }
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Transaction Id")}
                            value={transactionDetails?.transactionId || "-"}
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Transaction Type")}
                            value={
                              t(
                                transactionsTypes.find(
                                  item =>
                                    item.value === transactionDetails?.transType
                                )?.label
                              ) || "-"
                            }
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Buying Details Id")}
                            value={transactionDetails?.buyingDetailsId || "-"}
                          />
                        </Col>

                        <Col md={12}>
                          <h4 className="fs_24 mt-3">{t("Additional Info")}</h4>
                        </Col>

                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Sadad Invoice No")}
                            value={
                              transactionDetails?.additionalInfo
                                ?.sadadInvoiceNo || "-"
                            }
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Bill No")}
                            value={
                              transactionDetails?.additionalInfo?.billNo || "-"
                            }
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("EPTN")}
                            value={
                              transactionDetails?.additionalInfo?.EPTN || "-"
                            }
                          />
                        </Col>

                        <Col lg={12}></Col>

                        <Col md={12}>
                          <h4 className="fs_24 mt-3">{t("Refund Info")}</h4>
                        </Col>

                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Is Refunded")}
                            value={
                              transactionDetails?.refundInfo?.isRefund
                                ? t("Yes")
                                : t("No")
                            }
                          />
                        </Col>

                        {transactionDetails?.refundInfo?.refundAt && (
                          <Col lg={3}>
                            <DetailsInnerDiv
                              label={t("Refunded At")}
                              value={FormateDate(
                                transactionDetails?.refundInfo?.refundAt
                              )}
                            />
                          </Col>
                        )}

                        {transactionDetails?.refundInfo?.refundId && (
                          <Col lg={3}>
                            <DetailsInnerDiv
                              label={t("Refund Id")}
                              value={FormateDate(
                                transactionDetails?.refundInfo?.refundId
                              )}
                            />
                          </Col>
                        )}

                        <Col lg={12}></Col>

                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Created At")}
                            value={FormateDate(transactionDetails.createdAt)}
                          />
                        </Col>
                        <Col lg={3}>
                          <DetailsInnerDiv
                            label={t("Updated At")}
                            value={FormateDate(transactionDetails.updatedAt)}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaymentHistoryDetails
