import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import Title from "components/Shared/Title"
import { ErrorMessage, Field, Form, Formik } from "formik"
import {
  handle_4_3_FileUpload,
  handleBannerUpload,
  handleFileUpload,
} from "components/Common/Utils"
import ImgPreview from "components/Shared/ImgPreview"
import { createNewsSchema } from "components/Common/validation"
import TopTitle from "components/Shared/TopTitle"
import CustomLabel from "components/Shared/CustomLabel"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import FileUpload from "components/Shared/FileUpload"
import FormButtons from "components/Shared/FormButtons"
import { useDispatch, useSelector } from "react-redux"
import {
  createNews,
  getNewsDetails,
  updateNews,
} from "store/actions/newsAction"
import { showAlert } from "store/reducers/alertReducer"
import Spinners from "components/Shared/Spinners"
import BackButton from "components/Shared/BackButton"
import { newsUploadMaxSize } from "components/Common/FileUploadSizeUtils"

const initialValues = {
  title: "",
  titleAr: "",
  description: "",
  descriptionAr: "",
  image: "",
}

const CreateNews = ({ mode }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t(
    `${
      mode === "create"
        ? t("Create News | Takween")
        : t("Update News | Takween")
    }`
  )

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { loading, isLoading, newsDetails } = useSelector(state => state.News)
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getNewsDetails(payload))
    }
    mode === "update" && fetchDetails()
  }, [])

  useEffect(() => {
    if (newsDetails && mode === "update") {
      const { title, titleAr, description, descriptionAr, imageUrl } =
        newsDetails

      setFormValues(prev => ({
        ...prev,
        title,
        titleAr,
        description,
        descriptionAr,
        image: imageUrl,
      }))
    }
  }, [mode, newsDetails])

  const handleOnSubmit = values => {
    const { title, titleAr, description, descriptionAr, image } = values

    let data = new FormData()
    data.append("title", title)
    data.append("titleAr", titleAr)
    data.append("description", description)
    data.append("descriptionAr", descriptionAr)
    data.append("image", image)

    if (mode === "create") {
      dispatch(createNews(data))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/news")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (mode === "update") {
      data.append("id", id)
      dispatch(updateNews(data))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/news")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {mode === "update" && loading ? (
          <Spinners />
        ) : (
          <Container fluid>
            <TopTitle
              title={t("News")}
              subTitle={t(
                `Hi, {{name}}. Welcome back to your Marketing portal!`,
                { name: name }
              )}
            />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h2 className="fs_18_700 g_start">
                      <BackButton />
                      {mode === "create"
                        ? t("Create News")
                        : t("Update News")}{" "}
                    </h2>
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={createNewsSchema}
                      onSubmit={handleOnSubmit}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel htmlFor="" label={t("English")} />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel htmlFor="" label={t("Arabic")} />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="title"
                                  label={t("Title")}
                                  isRequired={true}
                                />
                                <Field
                                  name="title"
                                  type="text"
                                  id="title"
                                  placeholder={t("Title")}
                                  className={`form-control ${
                                    touched.title && errors.title
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"title"} />
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="titleAr"
                                  label={t("Title")}
                                  isRequired={true}
                                />
                                <Field
                                  name="titleAr"
                                  type="text"
                                  id="titleAr"
                                  placeholder={t("Title(عربي)")}
                                  className={`form-control rtl ${
                                    touched.titleAr && errors.titleAr
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"titleAr"} />
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="description"
                                  label={t("Description(English)")}
                                  isRequired={true}
                                />
                                <Field
                                  as="textarea"
                                  name="description"
                                  id="description"
                                  placeholder={t("Description(English)")}
                                  className={`form-control ${
                                    touched.description && errors.description
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"description"} />
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="descriptionAr"
                                  label={t("Description(عربي)")}
                                  isRequired={true}
                                />
                                <Field
                                  as="textarea"
                                  name="descriptionAr"
                                  id="descriptionAr"
                                  placeholder={t("Description(عربي)")}
                                  className={`form-control ${
                                    touched.descriptionAr &&
                                    errors.descriptionAr
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"descriptionAr"} />
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="form_field">
                                <label htmlFor="image">
                                  {t("Upload Image")}
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <FileUpload
                                  name="image"
                                  value={values.image}
                                  touched={touched.image}
                                  error={errors.image}
                                  labelText={t("Upload Image")}
                                  setFieldValue={setFieldValue}
                                  handleFileUpload={event =>
                                    handle_4_3_FileUpload(
                                      event,
                                      setFieldValue,
                                      "image",
                                      true, // Enable size validation
                                      newsUploadMaxSize
                                    )
                                  }
                                />
                                <CustomErrorMessage name={"image"} />
                                <p className="fs_14_500 opacity-75">
                                {t(
                                  `Recommended Image Size: Rectangle (4:3 ratio, e.g., 800*600 px). Max size: {{maxSize}} KB`,
                                  { maxSize: newsUploadMaxSize }
                                )}
                                </p>
                              </div>
                            </Col>

                            <Col lg={12}>
                              <FormButtons
                                buttonText={
                                  mode === "create" ? t("Create") : t("Update")
                                }
                                isLoading={isLoading}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default CreateNews
