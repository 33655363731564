import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Banners from "pages/Marketing/Banners/Banners"
import News from "pages/Marketing/News/News"
import Users from "pages/SuperAdmin/Users/Users"
import CreateBanner from "pages/Marketing/Banners/CreateBanner"
import CreateNews from "pages/Marketing/News/CreateNews"
import Partners from "pages/Marketing/Partners/Partners"
import BannerDetails from "pages/Marketing/Banners/BannerDetails"
import NewsDetails from "pages/Marketing/News/NewsDetails"
import PaymentHistory from "pages/Finance/PaymentHistory"
import SalesHistory from "pages/Sales/SalesHistory"
import PaymentHistoryDetails from "pages/Finance/PaymentHistoryDetails"
import SalesReports from "pages/Finance/Reports/SalesReports"
import PaymentReports from "pages/Finance/Reports/PaymentReports"
import SalesHistoryDetails from "pages/Sales/SalesHistoryDetails"
import Projects from "pages/Marketing/Projects/Projects"
import ProjectDetails from "pages/Marketing/Projects/ProjectDetails"
import UnitDetails from "pages/Marketing/Projects/Units/UnitDetails"
import PushNotifications from "pages/Marketing/PushNotifications"
import SMSNotifications from "pages/Marketing/SMSNotifications"
import AppLookAndFeel from "pages/Marketing/AppLookAndFeel"
import CustomerSupport from "pages/CustomerSupport/CustomerSupport"
import CustomerSupportDetails from "pages/CustomerSupport/CustomerSupportDetails"
import RegisteredInterests from "pages/CustomerSupport/RegisteredInterests"
import MaintenanceUsers from "pages/SuperAdmin/MaintenanceUsers/MaintenanceUsers"
import CreateMaintenanceUser from "pages/SuperAdmin/MaintenanceUsers/CreateMaintenanceUser"
import MaintenanceUserDetails from "pages/SuperAdmin/MaintenanceUsers/MaintenanceUserDetails"
import UserDetails from "pages/SuperAdmin/Users/UserDetails"
import CreateUser from "../pages/SuperAdmin/Users/CreateUser"
import ChangePassword from "pages/Authentication/ChangePassword"
import Units from "pages/Marketing/Projects/Units/Units"
import DeliveryLetter from "pages/Sales/DeliveryLetter"
import DeedRecords from "pages/Sales/DeedRecords"
import WaterAndBillSubmission from "pages/Sales/WaterAndBillSubmission"
import History from "pages/Sales/History"
import HistoryDetails from "pages/Sales/HistoryDetails"
import CreateProject from "pages/Marketing/Projects/CreateProject"
import UpdateProject from "pages/Marketing/Projects/UpdateProject/UpdateProject"
import CreateUnit from "pages/Marketing/Projects/Units/CreateUnit"
import UpdateUnit from "pages/Marketing/Projects/Units/UpdateUnit/UpdateUnit"
import DownPayments from "pages/Finance/DownPayments"
import FullPayments from "pages/Finance/FullPayments"
import PaymentContracts from "pages/Finance/PaymentContracts"
import TaxPayments from "pages/Finance/TaxPayments"
import Customers from "pages/CustomerSupport/Customers"
import CustomerDetails from "pages/CustomerSupport/CustomerDetails"
import GenerateQRCode from "pages/CustomerSupport/GenerateQRCode"
import ResetPassword from "pages/Authentication/ResetPassword"
import UpdateProfile from "pages/Authentication/UpdateProfile"
import Attributes from "pages/Marketing/Attributes/Attributes"
import CreateAttribute from "pages/Marketing/Attributes/CreateAttribute"
import AttributeDetails from "pages/Marketing/Attributes/AttributeDetails"
import Services from "pages/Marketing/Services/Services"
import CreateService from "pages/Marketing/Services/CreateService"
import ServiceDetails from "pages/Marketing/Services/ServiceDetails"
import RoomTypes from "pages/Marketing/RoomTypes/RoomTypes"
import Test from "pages/test"
import Cities from "pages/Marketing/Cities/Cities"
import AppNotifications from "pages/Marketing/Notification/Notifications"
import AppNotificationDetails from "pages/Marketing/Notification/NotificationDetails"
import ServiceItems from "pages/Marketing/ServiceItems/ServiceItems"
import ServiceItemDetails from "pages/Marketing/ServiceItems/ServiceItemDetails"
import CreateServiceItem from "pages/Marketing/ServiceItems/CreateServiceItem"
import RETTUpload from "pages/Finance/RETTCertificate"
import DeedRequest from "pages/Mowatheq/DeedRequest"
import Notifications from "pages/Finance/Notifications/Notifications"
import NotificationDetails from "pages/Finance/Notifications/NotificationDetails"
import Faq from "pages/Marketing/ContentManagement/Faq/Faq"
import FaqDetails from "pages/Marketing/ContentManagement/Faq/FaqDetails"
import CreateFaq from "pages/Marketing/ContentManagement/Faq/CreateFaq"
import TermsAndConditions from "pages/Marketing/ContentManagement/TermsAndConditions/TermsAndConditions"
import PrivacyPolicy from "pages/Marketing/ContentManagement/TermsAndConditions/PrivacyPolicy"
import CustomerSupportSubject from "pages/CustomerSupport/CustomerSupportSubject/CustomerSupportSubject"
import CustomerSupportSubjectDetails from "pages/CustomerSupport/CustomerSupportSubject/CustomerSupportSubjectDetails"
import CreateCustomerSupportSubject from "pages/CustomerSupport/CustomerSupportSubject/CreateCustomerSupportSubject"
import PaymentReceipt from "pages/Finance/PaymentReceipt"
import ServiceRequest from "pages/SuperAdmin/MaintenanceUsers/ServiceRequest/ServiceRequest"
import ServiceRequestDetails from "pages/SuperAdmin/MaintenanceUsers/ServiceRequest/ServiceRequestDetails"
import RatingsAndFeedback from "pages/SuperAdmin/MaintenanceUsers/RatingFeedback/RatingsAndFeedback"
import RatingsAndFeedbackDetails from "pages/SuperAdmin/MaintenanceUsers/RatingFeedback/RatingsAndFeedbackDetails"
import ExternalLinks from "pages/Marketing/GlobalSettings/ExternalLinks"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // SUPER ADMIN ONLY
  { path: "/maintenance-users", component: <MaintenanceUsers /> },
  {
    path: "/maintenance-users/create",
    component: <CreateMaintenanceUser mode="create" />,
  },
  {
    path: "/maintenance-users/update",
    component: <CreateMaintenanceUser mode="update" />,
  },
  { path: "/maintenance-users/details", component: <MaintenanceUserDetails /> },

  // SERVICE REQUEST
  { path: "/service-request", component: <ServiceRequest /> },
  { path: "/service-request/details", component: <ServiceRequestDetails /> },
  // RATING & FEEDBACK
  { path: "/ratings-feedback", component: <RatingsAndFeedback /> },
  {
    path: "/ratings-feedback/details",
    component: <RatingsAndFeedbackDetails />,
  },

  // Users
  { path: "/users", component: <Users /> },
  { path: "/users/create", component: <CreateUser mode="create" /> },
  { path: "/users/update", component: <CreateUser mode="update" /> },
  { path: "/users/details", component: <UserDetails /> },

  // Profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/change-password", component: <ChangePassword /> },
  { path: "/update-profile", component: <UpdateProfile /> },

  //************ SALES START *************
  // {
  //   path: "/complaints",
  //   component: <Complaints />,
  //   allowedRoles: ["sales", "finance"],
  // },
  {
    path: "/delivery-letter",
    component: <DeliveryLetter />,
    allowedRoles: ["sales"],
  },
  {
    path: "/deed-request",
    component: <DeedRequest />,
    allowedRoles: ["mowatheq"],
  },
  {
    path: "/deed-records",
    component: <DeedRecords />,
    allowedRoles: ["sales"],
  },
  {
    path: "/water-and-bill-submission",
    component: <WaterAndBillSubmission />,
    allowedRoles: ["sales"],
  },
  { path: "/history", component: <History />, allowedRoles: ["sales"] },
  {
    path: "/history/details",
    component: <HistoryDetails />,
    allowedRoles: ["sales"],
  },
  //************ SALES END *************

  //************ MARKETING START *************

  { path: "/projects", component: <Projects />, allowedRoles: ["marketing"] },
  {
    path: "/projects/details",
    component: <ProjectDetails />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/projects/create",
    component: <CreateProject />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/projects/update",
    component: <UpdateProject />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/units",
    component: <Units mode="allUnits" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/projects/:projectId/units",
    component: <Units mode="projectUnits" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/projects/:projectId/units/create",
    component: <CreateUnit mode="projectUnits" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/units/create",
    component: <CreateUnit mode="allUnits" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/projects/:projectId/units/update",
    component: <UpdateUnit mode="updateUnit" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/projects/:projectId/units/details",
    component: <UnitDetails />,
    allowedRoles: ["marketing"],
  },

  { path: "/cities", component: <Cities />, allowedRoles: ["marketing"] },

  { path: "/banners", component: <Banners />, allowedRoles: ["marketing"] },
  {
    path: "/banners/create",
    component: <CreateBanner mode="create" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/banners/update",
    component: <CreateBanner mode="update" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/banners/banner-details",
    component: <BannerDetails />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/app-look-and-feel",
    component: <AppLookAndFeel />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/app-notifications",
    component: <AppNotifications />,
    allowedRoles: ["marketing", "customerService"],
  },
  {
    path: "/app-notifications/details",
    component: <AppNotificationDetails />,
    allowedRoles: ["marketing", "customerService"],
  },
  {
    path: "/push-notifications",
    component: <PushNotifications />,
    allowedRoles: ["marketing", "customerService"],
  },
  {
    path: "/sms-notifications",
    component: <SMSNotifications />,
    allowedRoles: ["marketing", "customerService"],
  },
  { path: "/news", component: <News /> },
  {
    path: "/news/create",
    component: <CreateNews mode="create" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/news/update",
    component: <CreateNews mode="update" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/news/news-details",
    component: <NewsDetails />,
    allowedRoles: ["marketing"],
  },
  { path: "/partners", component: <Partners />, allowedRoles: ["marketing"] },

  {
    path: "/attributes",
    component: <Attributes />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/attributes/create",
    component: <CreateAttribute mode="create" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/attributes/update",
    component: <CreateAttribute mode="update" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/attributes/details",
    component: <AttributeDetails />,
    allowedRoles: ["marketing"],
  },

  {
    path: "/services",
    component: <Services />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/services/create",
    component: <CreateService mode="create" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/services/update",
    component: <CreateService mode="update" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/services/details",
    component: <ServiceDetails />,
    allowedRoles: ["marketing"],
  },

  {
    path: "/service-items",
    component: <ServiceItems />,
    allowedRoles: ["marketing"],
  },

  {
    path: "/service-items/details",
    component: <ServiceItemDetails />,
    allowedRoles: ["marketing"],
  },

  {
    path: "/service-items/create",
    component: <CreateServiceItem mode="create" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/service-items/update",
    component: <CreateServiceItem mode="update" />,
    allowedRoles: ["marketing"],
  },

  {
    path: "/room-types",
    component: <RoomTypes />,
    allowedRoles: ["marketing"],
  },

  {
    path: "/faq",
    component: <Faq />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/faq/details",
    component: <FaqDetails />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/faq/create",
    component: <CreateFaq mode="create" />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/faq/update",
    component: <CreateFaq mode="update" />,
    allowedRoles: ["marketing"],
  },

  {
    path: "/terms-and-conditions",
    component: <TermsAndConditions />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
    allowedRoles: ["marketing"],
  },
  {
    path: "/external-links",
    component: <ExternalLinks />,
    allowedRoles: ["marketing"],
  },
  //************ MARKETING END *************

  //************ FINANCE START *************
  {
    path: "/notifications",
    component: <Notifications />,
    allowedRoles: ["finance", "sales", "mowatheq"],
  },
  {
    path: "/notifications/details",
    component: <NotificationDetails />,
    allowedRoles: ["finance", "sales", "mowatheq"],
  },
  {
    path: "/down-payments",
    component: <DownPayments />,
    allowedRoles: ["finance"],
  },
  {
    path: "/full-payments",
    component: <FullPayments />,
    allowedRoles: ["finance"],
  },
  {
    path: "/payment-contracts",
    component: <PaymentContracts />,
    allowedRoles: ["finance"],
  },
  {
    path: "/rett-certificate",
    component: <RETTUpload />,
    allowedRoles: ["finance"],
  },
  {
    path: "/tax-payments",
    component: <TaxPayments />,
    allowedRoles: ["finance"],
  },
  {
    path: "/payment-receipt",
    component: <PaymentReceipt />,
    allowedRoles: ["finance"],
  },

  {
    path: "/payment-history",
    component: <PaymentHistory />,
    allowedRoles: ["finance"],
  },
  {
    path: "/payment-history/details",
    component: <PaymentHistoryDetails />,
    allowedRoles: ["finance", "customerService"],
  },
  {
    path: "/sales-reports",
    component: <SalesReports />,
    allowedRoles: ["finance"],
  },
  {
    path: "/payment-reports",
    component: <PaymentReports />,
    allowedRoles: ["finance"],
  },
  //************ FINANCE END *************

  //************ SALES START *************
  // sales history
  {
    path: "/sales-history",
    component: <SalesHistory />,
    allowedRoles: ["sales"],
  },
  {
    path: "/sales-history/details",
    component: <SalesHistoryDetails />,
    allowedRoles: ["sales"],
  },

  //************ SALES END *************

  //************ CUSTOMER SUPPORT START *************
  {
    path: "/customer-support",
    component: <CustomerSupport />,
    allowedRoles: ["customerService", "finance", "sales", "marketing"],
  },
  {
    path: "/customer-support/details",
    component: <CustomerSupportDetails />,
    allowedRoles: ["customerService", "finance", "sales", "marketing"],
  },
  {
    path: "/customer-support-subject",
    component: <CustomerSupportSubject />,
    allowedRoles: ["customerService", "finance", "sales", "marketing"],
  },
  {
    path: "/customer-support-subject/details",
    component: <CustomerSupportSubjectDetails />,
    allowedRoles: ["customerService", "finance", "sales", "marketing"],
  },
  {
    path: "/customer-support-subject/create",
    component: <CreateCustomerSupportSubject mode="create" />,
    allowedRoles: ["customerService", "finance", "sales", "marketing"],
  },
  {
    path: "/customer-support-subject/update",
    component: <CreateCustomerSupportSubject mode="update" />,
    allowedRoles: ["customerService", "finance", "sales", "marketing"],
  },
  {
    path: "/registered-interests",
    component: <RegisteredInterests />,
    allowedRoles: ["customerService"],
  },
  {
    path: "/customers",
    component: <Customers />,
    allowedRoles: ["customerService"],
  },
  {
    path: "/customers/details",
    component: <CustomerDetails />,
    allowedRoles: ["customerService"],
  },
  {
    path: "/qr-code",
    component: <GenerateQRCode />,
    allowedRoles: ["customerService"],
  },
  //************ CUSTOMER SUPPORT END *************

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/test", component: <Test /> },
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/reset-password/:uuid", component: <ResetPassword /> },
]

export { authProtectedRoutes, publicRoutes }
