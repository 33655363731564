import React, { useEffect } from "react"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import BackButton from "components/Shared/BackButton"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import DetailsTitle from "components/Shared/DetailsTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { getUserDetails } from "store/actions/usersAction"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import FormateDate from "components/Shared/FormateDate"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { getRatingsFeedbackDetails } from "store/actions/maintenanceUsersAction"
import StarRatings from "react-star-ratings"

const RatingsAndFeedbackDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Ratings & Feedback Details | Takween")

  const dispatch = useDispatch()
  const { loading, ratingsAndFeedbackDetails } = useSelector(
    state => state.MaintenanceUsers
  )

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getRatingsFeedbackDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Rating & Feedback Details")} />

                  <div className="">
                    {loading ? (
                      <ShimmerThumbnail
                        height={250}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(ratingsAndFeedbackDetails).length ===
                      0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <Row className="g_details">
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Id")}
                            value={ratingsAndFeedbackDetails._id || "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Request Number")}
                            value={
                              ratingsAndFeedbackDetails?.serviceRequestId
                                ?.requestNo ?? "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Service Provider Name")}
                            value={
                              ratingsAndFeedbackDetails?.serviceProviderId
                                ?.name ?? "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Service Provider Phone Number")}
                            value={
                              ratingsAndFeedbackDetails?.serviceProviderId
                                ?.phone ?? "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Customer Name")}
                            value={
                              ratingsAndFeedbackDetails?.userId?.name ?? "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Customer Phone Number")}
                            value={
                              ratingsAndFeedbackDetails?.userId?.phone ?? "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Feedback")}
                            value={ratingsAndFeedbackDetails?.feedback ?? "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Platform")}
                            value={
                              t(ratingsAndFeedbackDetails?.platform) ?? "-"
                            }
                            valueClass="text-capitalize"
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Rating")}
                            value={
                              <StarRatings
                                rating={ratingsAndFeedbackDetails?.rating}
                                starRatedColor="#F1B44C"
                                starEmptyColor="#74788d"
                                numberOfStars={5}
                                name="rating"
                                starDimension="18px"
                                starSpacing="3px"
                              />
                            }
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RatingsAndFeedbackDetails
