import { createSlice } from "@reduxjs/toolkit"
import {
  createCity,
  deleteCity,
  getAllCities,
  getCitiesList,
  getCityDetails,
  updateCity,
  updateCityStatus,
} from "store/actions/citiesAction"

const initialState = {
  loading: true,
  isLoading: false,
  isGettingDetails: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  cities: [],
  citiesList: [],
  cityDetails: {},
}
const citiesReducer = createSlice({
  name: "cities",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllCities.pending, state => {
        state.loading = true
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.cities = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllCities.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getCitiesList.pending, state => {})
      .addCase(getCitiesList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.citiesList = data
        }
      })
      .addCase(getCitiesList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getCityDetails.pending, state => {
        state.isGettingDetails = true
        state.cityDetails = {}
      })
      .addCase(getCityDetails.fulfilled, (state, action) => {
        state.isGettingDetails = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.cityDetails = data
        }
      })
      .addCase(getCityDetails.rejected, (state, action) => {
        state.isGettingDetails = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateCityStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateCityStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.cities = state.cities.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateCityStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteCity.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteCity.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.cities = state.cities.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteCity.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createCity.pending, state => {
        state.isLoading = true
      })
      .addCase(createCity.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          state.cities = [data, ...state.cities]
          if (state.totalCount !== null) {
            state.totalCount += 1
          }
        }
      })
      .addCase(createCity.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateCity.pending, state => {
        state.isLoading = true
      })
      .addCase(updateCity.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          state.cities = state?.cities?.map(item =>
            item._id === data._id ? data : item
          )
        }
      })
      .addCase(updateCity.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default citiesReducer.reducer
