import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import {
  DELETE_CUSTOMER,
  GET_ALL_CUSTOMERS,
  GET_CUSTOMER_DETAILS,
  GET_CUSTOMERS_LIST,
  UPDATE_CUSTOMER_STATUS,
} from "helpers/url_helper"

export const getAllCustomers = createAsyncThunk(
  GET_ALL_CUSTOMERS,
  async payload => {
    const response = await post(GET_ALL_CUSTOMERS, payload)
    return response
  }
)

export const updateCustomerStatus = createAsyncThunk(
  UPDATE_CUSTOMER_STATUS,
  async payload => {
    const response = await patch(UPDATE_CUSTOMER_STATUS, payload)
    return response
  }
)

export const deleteCustomer = createAsyncThunk(
  DELETE_CUSTOMER,
  async payload => {
    const response = await del(DELETE_CUSTOMER, payload)
    return response
  }
)

export const getCustomersList = createAsyncThunk(GET_CUSTOMERS_LIST, async payload => {
  const response = await post(GET_CUSTOMERS_LIST, payload)
  return response
})

export const getCustomerDetails = createAsyncThunk(
  GET_CUSTOMER_DETAILS,
  async payload => {
    const response = await post(GET_CUSTOMER_DETAILS, payload)
    return response
  }
)