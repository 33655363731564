import { createAsyncThunk } from "@reduxjs/toolkit"
import { get, patch, post, put } from "helpers/api_helper"
import {
  CHANGE_LANGUAGE,
  CHANGE_USER_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN_URL,
  LOGOUT_URL,
  RESET_PASSWORD,
  RESET_PASSWORD_FOR_USER,
  UPDATE_USER_DETAILS,
  USER_DETAILS,
  VALIDATE_UUID,
  VERIFY_OTP,
} from "helpers/url_helper"

export const login = createAsyncThunk(LOGIN_URL, async payload => {
  const response = await post(LOGIN_URL, payload)
  return response
})

export const logout = createAsyncThunk(LOGOUT_URL, async payload => {
  const response = await post(LOGOUT_URL, payload)
  return response
})

export const changeDefaultLanguage = createAsyncThunk(
  CHANGE_LANGUAGE,
  async payload => {
    const response = await patch(CHANGE_LANGUAGE, payload)
    return response
  }
)

export const userDetails = createAsyncThunk(USER_DETAILS, async payload => {
  const response = await get(USER_DETAILS)
  return response
})

export const changeUserPassword = createAsyncThunk(
  CHANGE_USER_PASSWORD,
  async payload => {
    const response = await patch(CHANGE_USER_PASSWORD, payload)
    return response
  }
)

export const forgotPassword = createAsyncThunk(
  FORGOT_PASSWORD,
  async payload => {
    const response = await post(FORGOT_PASSWORD, payload)
    return response
  }
)

export const verifyOtp = createAsyncThunk(VERIFY_OTP, async payload => {
  const response = await post(VERIFY_OTP, payload)
  return response
})

export const resetPassword = createAsyncThunk(RESET_PASSWORD, async payload => {
  const response = await patch(RESET_PASSWORD, payload)
  return response
})

export const validateUUID = createAsyncThunk(VALIDATE_UUID, async payload => {
  const response = await post(VALIDATE_UUID, payload)
  return response
})

export const resetPasswordForNewUser = createAsyncThunk(
  RESET_PASSWORD_FOR_USER,
  async payload => {
    const response = await patch(RESET_PASSWORD_FOR_USER, payload)
    return response
  }
)

export const updateUserDetails = createAsyncThunk(
  UPDATE_USER_DETAILS,
  async payload => {
    const response = await put(UPDATE_USER_DETAILS, payload)
    return response
  }
)
