import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import DetailsTitle from "components/Shared/DetailsTitle"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import TopTitle from "components/Shared/TopTitle"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { getServiceItemDetails } from "store/actions/serviceItemsAction"

const ServiceItemDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Service Item Details | Takween")

  const dispatch = useDispatch()
  const { loading, serviceItemDetails } = useSelector(
    state => state.ServiceItems
  )
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getServiceItemDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Service Item Details")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Service Item Details")} />
                  {loading ? (
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  ) : Object.values(serviceItemDetails).length === 0 ? (
                    <p className="text-center my-3">
                      <NoDataAvailable />
                    </p>
                  ) : (
                    <Row className="g_details">
                      <Col lg={4}>
                        <div className="d-flex mt-3">
                          <div>
                            <p className="fs_14_600 opacity-50 mb-2">
                              {t("Image")}
                            </p>
                            {serviceItemDetails.imageUrl ? (
                              <img
                                src={serviceItemDetails.imageUrl}
                                alt=""
                                className="img-fluid rounded-3"
                                style={{ objectFit: "contain" }}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col lg={8}>
                        <Row>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Id")}
                              value={serviceItemDetails._id}
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Name")}
                              value={
                                language === "sa"
                                  ? serviceItemDetails.nameAr
                                  : serviceItemDetails.name
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Status")}
                              value={
                                serviceItemDetails.status === 1
                                  ? t("Active")
                                  : t("Deactive")
                              }
                              valueClass={`${
                                serviceItemDetails.status === 1
                                  ? "active_color"
                                  : "danger_color"
                              }`}
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Price")}
                              value={`${serviceItemDetails.price ?? ""} ${
                                serviceItemDetails.currency ?? "-"
                              }`}
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Service Name")}
                              value={
                                language === "sa"
                                  ? serviceItemDetails?.serviceId?.nameAr
                                  : serviceItemDetails?.serviceId?.name || ""
                              }
                            />
                          </Col>

                          <Col md={12}>
                            <DetailsInnerDiv
                              label={t("Description")}
                              value={
                                language === "sa"
                                  ? serviceItemDetails.descriptionAr
                                  : serviceItemDetails.description
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ServiceItemDetails
