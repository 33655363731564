import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Field, FieldArray, Form, Formik } from "formik"
import { Col, Row } from "reactstrap"
import CustomLabel from "components/Shared/CustomLabel"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import { nearbyLandmarksSchema } from "components/Common/validation"
import { distanceOptions } from "components/Common/constants"
import { useDispatch, useSelector } from "react-redux"
import { updateProject } from "store/actions/projectsAction"
import { showAlert } from "store/reducers/alertReducer"
import { updateUnit } from "store/actions/unitsAction"
import { useNavigate } from "react-router-dom"

const initialValues = {
  nearByLandMarks: [
    {
      name: "",
      nameAr: "",
      distance: null,
      distanceUnit: "",
    },
  ],
}

const NearbyLandmarks = ({ actionType, id }) => {
  // actionType UNIT(For unit), PROJECT(For project)
  const [formValues, setFormValues] = useState(initialValues)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { loading, isLoading, projectDetails } = useSelector(
    state => state.Projects
  )
  const { isLoading: isUnitLoading, unitDetails } = useSelector(
    state => state.Units
  )

  useEffect(() => {
    const details = actionType === "PROJECT" ? projectDetails : unitDetails
    if (details) {
      const { nearByLandMarks } = details
      if (nearByLandMarks?.length > 0) {
        setFormValues(prev => ({
          ...prev,
          nearByLandMarks,
        }))
      }
    }
  }, [projectDetails, unitDetails, actionType])

  const handleOnSubmit = values => {
    const { nearByLandMarks } = values
    let data = new FormData()
    data.append("id", id)

    nearByLandMarks?.forEach((item, index) => {
      data.append(`nearByLandMarks[${index}][name]`, item.name)
      data.append(`nearByLandMarks[${index}][nameAr]`, item.nameAr)
      data.append(`nearByLandMarks[${index}][distance]`, Number(item.distance))
      data.append(`nearByLandMarks[${index}][distanceUnit]`, item.distanceUnit)
    })

    const updatedAction = actionType === "PROJECT" ? updateProject : updateUnit

    dispatch(updatedAction(data))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <div>
      <Formik
        initialValues={formValues}
        validationSchema={nearbyLandmarksSchema}
        onSubmit={handleOnSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <FieldArray name="nearByLandMarks">
              {({ remove, push }) => (
                <div>
                  {values?.nearByLandMarks?.map((field, index) => (
                    <div key={index} className="primary_bg_10">
                      <Row>
                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`nearByLandMarks.${index}.name`}
                              label={t("Name(English)")}
                              isRequired={true}
                            />
                            <Field
                              name={`nearByLandMarks.${index}.name`}
                              type={"text"}
                              id={`nearByLandMarks.${index}.name`}
                              placeholder={t("Name(English)")}
                              className={`form-control  ${
                                errors.nearByLandMarks?.[index]?.name &&
                                touched.nearByLandMarks?.[index]?.name
                                  ? "is-invalid"
                                  : ""
                              } `}
                            />
                            <CustomErrorMessage
                              name={`nearByLandMarks.${index}.name`}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`nearByLandMarks.${index}.nameAr`}
                              label={t("Name(Arabic)")}
                              isRequired={true}
                            />
                            <Field
                              name={`nearByLandMarks.${index}.nameAr`}
                              type={"text"}
                              id={`nearByLandMarks.${index}.nameAr`}
                              placeholder={t("Name(Arabic)")}
                              className={`form-control  ${
                                errors.nearByLandMarks?.[index]?.nameAr &&
                                touched.nearByLandMarks?.[index]?.nameAr
                                  ? "is-invalid"
                                  : ""
                              } `}
                            />
                            <CustomErrorMessage
                              name={`nearByLandMarks.${index}.nameAr`}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`nearByLandMarks.${index}.distance`}
                              label={t("Distance")}
                              isRequired={true}
                            />

                            <Field
                              name={`nearByLandMarks.${index}.distance`}
                              type={"number"}
                              id={`nearByLandMarks.${index}.distance`}
                              placeholder={t("Distance")}
                              className={`form-control  ${
                                errors.nearByLandMarks?.[index]?.distance &&
                                touched.nearByLandMarks?.[index]?.distance
                                  ? "is-invalid"
                                  : ""
                              } `}
                            />
                            <CustomErrorMessage
                              name={`nearByLandMarks.${index}.distance`}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form_field">
                            <CustomLabel
                              htmlFor={`nearByLandMarks.${index}.distanceUnit`}
                              label={t("Distance Unit")}
                              isRequired={true}
                            />
                            <div className="select_wrapper">
                              <Field
                                as="select"
                                name={`nearByLandMarks.${index}.distanceUnit`} // Adjust name to match the specific field in the array
                                className={`form-control b_r_12 ${
                                  errors.nearByLandMarks?.[index]
                                    ?.distanceUnit &&
                                  touched.nearByLandMarks?.[index]?.distanceUnit
                                    ? "is-invalid"
                                    : ""
                                }`}
                                onChange={e => {
                                  const selectedValue = e.target.value
                                  setFieldValue(
                                    `nearByLandMarks.${index}.distanceUnit`,
                                    selectedValue
                                  )
                                }}
                              >
                                <option hidden>{t("Distance Unit")}</option>
                                {distanceOptions?.map(item => (
                                  <option key={item.value} value={item.value}>
                                    {t(item.label)}
                                  </option>
                                ))}
                              </Field>
                            </div>
                            <CustomErrorMessage
                              name={`nearByLandMarks.${index}.distanceUnit`}
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="d-flex justify-content-end mt-3">
                            {values.nearByLandMarks.length > 1 && (
                              <button
                                type="button"
                                className="reject_btn mx-2"
                                onClick={() => remove(index)}
                              >
                                {t("Remove")}
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-3 gap-3">
                    <button
                      type="button"
                      className="cancel_btn"
                      onClick={() =>
                        push({
                          name: "",
                          nameAr: "",
                          distance: null,
                          distanceUnit: "",
                        })
                      }
                    >
                      {t("Add More")}
                    </button>
                    <button
                      type="button"
                      className="cancel_btn"
                      onClick={() => navigate(-1)}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="primary_btn"
                      type="submit"
                      disabled={isLoading || isUnitLoading}
                    >
                      {t("Update")}
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default NearbyLandmarks
