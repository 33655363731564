import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import DetailsTitle from "components/Shared/DetailsTitle"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import FormateDate from "components/Shared/FormateDate"
import TopTitle from "components/Shared/TopTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import {
  getCustomerSupportDetails,
  getCustomerSupportSubjectDetails,
} from "store/actions/customerSupportAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { customerSupportStatusOptions } from "components/Common/constants"

const CustomerSupportSubjectDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Customer Support Subject Details | Takween")

  const dispatch = useDispatch()
  const { loading,  customerSupportSubjectDetails } = useSelector(
    state => state.CustomerSupport
  )

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getCustomerSupportSubjectDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Customer Support Subject")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Customer service Portal!`,
              {
                name: name,
              }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Customer Support Subject Details")} />
                  <div>
                    {loading ? (
                      <ShimmerThumbnail
                        height={350}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(customerSupportSubjectDetails).length ===
                      0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <>
                        <Row className="g_details">
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Id")}
                              value={customerSupportSubjectDetails?._id}
                            />
                          </Col>

                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Title")}
                              value={
                                language === "sa"
                                  ? customerSupportSubjectDetails.titleAr
                                  : customerSupportSubjectDetails.title || "-"
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Status")}
                              value={
                                customerSupportSubjectDetails.status === 1
                                  ? t("Active")
                                  : t("Deactive")
                              }
                              valueClass={
                                customerSupportSubjectDetails.status === 1
                                  ? "active_color"
                                  : "danger_color"
                              }
                            />
                          </Col>

                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Role")}
                              value={
                                language === "sa"
                                  ? customerSupportSubjectDetails?.role?.nameAr
                                  : customerSupportSubjectDetails?.role?.name ||
                                    "-"
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Display Order")}
                              value={
                                customerSupportSubjectDetails.displayOrder ??
                                "-"
                              }
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomerSupportSubjectDetails
