import React, { useEffect } from "react"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import BackButton from "components/Shared/BackButton"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import DetailsTitle from "components/Shared/DetailsTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import {
  getMaintenanceUserDetails,
  getServiceRequestDetails,
} from "store/actions/maintenanceUsersAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import FormateDate, { FormateOnlyDate } from "components/Shared/FormateDate"
import {
  serviceRequestStatus,
  serviceRequestType,
} from "components/Common/constants"

const ServiceRequestDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Service Request Details | Takween")

  const dispatch = useDispatch()
  const { loading, maintenanceServiceRequestDetails } = useSelector(
    state => state.MaintenanceUsers
  )

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getServiceRequestDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Service Request Details")} />

                  <div>
                    {loading ? (
                      <ShimmerThumbnail
                        height={250}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(maintenanceServiceRequestDetails)
                        .length === 0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <Row className="g_details">
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Id")}
                            value={maintenanceServiceRequestDetails._id ?? "-"}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Request Number")}
                            value={
                              maintenanceServiceRequestDetails?.requestNo ?? "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Customer Name")}
                            value={
                              maintenanceServiceRequestDetails?.userId?.name ??
                              "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Customer Phone Number")}
                            value={
                              maintenanceServiceRequestDetails?.userId?.phone ??
                              "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Invoice Internal Code")}
                            value={
                              maintenanceServiceRequestDetails?.invoiceInternalCode ??
                              "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Service Name")}
                            value={
                              language === "sa"
                                ? maintenanceServiceRequestDetails?.serviceId
                                    ?.nameAr
                                : maintenanceServiceRequestDetails?.serviceId
                                    ?.name ?? "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Service Item Name")}
                            value={
                              language === "sa"
                                ? maintenanceServiceRequestDetails
                                    ?.serviceItemId?.nameAr
                                : maintenanceServiceRequestDetails
                                    ?.serviceItemId?.name ?? "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Service Provider Name")}
                            value={
                              maintenanceServiceRequestDetails
                                ?.serviceProviderId?.name ?? "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Unit Name")}
                            value={
                              language === "sa"
                                ? maintenanceServiceRequestDetails?.unitId
                                    ?.nameAr
                                : maintenanceServiceRequestDetails?.unitId
                                    ?.name ?? "-"
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Request Type")}
                            value={t(
                              serviceRequestType.find(
                                item =>
                                  item.value ===
                                  maintenanceServiceRequestDetails?.requestType
                              )?.label ?? "-"
                            )}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Price")}
                            value={`${
                              maintenanceServiceRequestDetails?.price
                            } ${t("SAR")}`}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Is Payment Done")}
                            value={
                              maintenanceServiceRequestDetails?.isPaymentDone
                                ? t("Yes")
                                : t("No")
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Payment Status")}
                            value={t(
                              maintenanceServiceRequestDetails?.paymentDetails
                                ?.status
                            )}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Payment Method")}
                            value={t(
                              maintenanceServiceRequestDetails?.paymentDetails
                                ?.paymentMethod
                            )}
                            valueClass="text-capitalize"
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Payment Created At")}
                            value={FormateDate(
                              maintenanceServiceRequestDetails?.paymentDetails
                                ?.createdAt
                            )}
                            valueClass="text-capitalize"
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Request Date & Time")}
                            value={FormateDate(
                              maintenanceServiceRequestDetails?.requestDateTime
                            )}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Scheduled Date")}
                            value={FormateOnlyDate(
                              maintenanceServiceRequestDetails?.scheduledDate
                            )}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Scheduled Time")}
                            value={
                              maintenanceServiceRequestDetails?.scheduledTime
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Refund At")}
                            value={FormateDate(
                              maintenanceServiceRequestDetails?.refundAt
                            )}
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Is Rated")}
                            value={
                              maintenanceServiceRequestDetails?.isRated
                                ? t("Yes")
                                : t("No")
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <DetailsInnerDiv
                            label={t("Status")}
                            value={t(
                              serviceRequestStatus.find(
                                item =>
                                  item.value ===
                                  maintenanceServiceRequestDetails.status
                              )?.label
                            )}
                            valueClass={`
                                ${
                                  serviceRequestStatus.find(
                                    item =>
                                      item.value ===
                                      maintenanceServiceRequestDetails.status
                                  )?.className
                                } status_tag fit_content`}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ServiceRequestDetails
