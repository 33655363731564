const projectUploadMaxSize = 200
const bannerUploadMaxSize = 300
const newsUploadMaxSize = 200
const serviceItemUploadMaxSize = 200
const unitUploadMaxSize = 200
const partnerUploadMaxSize = 200
const attributeUploadMaxSize = 20
const serviceUploadMaxSize = 20
const galleryUploadMaxSize = 300

export {
  projectUploadMaxSize,
  bannerUploadMaxSize,
  newsUploadMaxSize,
  serviceItemUploadMaxSize,
  unitUploadMaxSize,
  partnerUploadMaxSize,
  attributeUploadMaxSize,
  serviceUploadMaxSize,
  galleryUploadMaxSize
}
