import React, { useEffect } from "react"
import { Card, Col, Container, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import { ascDescOptions } from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import { getDateRange } from "components/Common/Utils"
import Paginations from "components/Common/Pagination"
import TopTitle from "components/Shared/TopTitle"
import { useDispatch, useSelector } from "react-redux"

import Spinners from "components/Shared/Spinners"
import { getAllRatingsFeedback } from "store/actions/maintenanceUsersAction"
import StarRatings from "react-star-ratings"

const RatingsAndFeedback = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  document.title = t("Ratings & Feedback | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    ratingsAndFeedback,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.MaintenanceUsers)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    serviceRequest: [],
    customer: [],
    serviceProvider: [],
  })

  const additionOptions = [
    // {
    //   name: "role",
    //   type: "multiSelect",
    //   options: rolesList.map(item => ({
    //     label: language === "sa" ? item.nameAr : item.name,
    //     value: item._id,
    //   })),
    //   placeholder: t("Select Roles"),
    // },
  ]

  const filterOptions = [...ascDescOptions, ...additionOptions]

  useEffect(() => {
    fetchAllRatingsAndFeedback()
  }, [])

  const fetchAllRatingsAndFeedback = page => {
    const { orderBy } = filters
    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      orderBy,
      serviceRequest: [],
      customer: [],
      serviceProvider: [],
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllRatingsFeedback(payload))
    setIsApiCalled(true)

    navigate(`/ratings-feedback?page=${page || pageNumber}`)
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllRatingsAndFeedback(1)
  }

  const onPageChange = page => {
    fetchAllRatingsAndFeedback(page)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Ratings & Feedback")}
            subTitle={t(`Hi, {{name}}. Welcome back to your Admin portal!`, {
              name: name,
            })}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Ratings & Feedback List")} />
                <div className="mt-2"></div>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  // colSize={4}
                />
                {loading ? (
                  <Spinners />
                ) : ratingsAndFeedback.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Request Number")}</th>
                          <th>{t("Service Provider Name")}</th>
                          <th>{t("Service Provider Phone Number")}</th>
                          <th>{t("Customer Name")}</th>
                          <th>{t("Customer Phone Number")}</th>
                          <th>{t("Feedback")}</th>
                          <th>{t("Platform")}</th>
                          <th>{t("Rating")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ratingsAndFeedback.map((item, index) => (
                          <tr
                            key={item._id}
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(
                                `/ratings-feedback/details?id=${item._id}`
                              )
                            }}
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>
                              {item?.serviceRequestData?.requestNo ?? "-"}
                            </td>
                            <td>{item?.serviceProviderData?.name ?? "-"}</td>
                            <td>{item?.serviceProviderData?.phone ?? "-"}</td>
                            <td>{item?.userData?.name ?? "-"}</td>
                            <td>{item?.userData?.phone ?? "-"}</td>
                            <td>{item?.feedback ?? "-"}</td>
                            <td>{t(item?.platform) ?? "-"}</td>
                            <td className="fit_content">
                              <StarRatings
                                rating={item?.rating}
                                starRatedColor="#F1B44C"
                                starEmptyColor="#74788d"
                                numberOfStars={5}
                                name="rating"
                                starDimension="18px"
                                starSpacing="3px"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && ratingsAndFeedback.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RatingsAndFeedback
