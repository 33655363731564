import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  del,
  formDataPost,
  formDataPut,
  get,
  patch,
  post,
  put,
} from "helpers/api_helper"
import {
  ADD_CITY,
  DELETE_CITY,
  GET_ALL_CITIES,
  GET_CITIES_DETAILS,
  GET_CITIES_LIST,
  UPDATE_CITY,
  UPDATE_CITY_STATUS,
} from "helpers/url_helper"

export const getAllCities = createAsyncThunk(GET_ALL_CITIES, async payload => {
  const response = await post(GET_ALL_CITIES, payload)
  return response
})

export const updateCityStatus = createAsyncThunk(
  UPDATE_CITY_STATUS,
  async payload => {
    const response = await patch(UPDATE_CITY_STATUS, payload)
    return response
  }
)

export const deleteCity = createAsyncThunk(DELETE_CITY, async payload => {
  const response = await del(DELETE_CITY, payload)
  return response
})

export const createCity = createAsyncThunk(ADD_CITY, async payload => {
  const response = await formDataPost(ADD_CITY, payload)
  return response
})

export const updateCity = createAsyncThunk(UPDATE_CITY, async payload => {
  const response = await formDataPut(UPDATE_CITY, payload)
  return response
})

export const getCityDetails = createAsyncThunk(
  GET_CITIES_DETAILS,
  async payload => {
    const response = await post(GET_CITIES_DETAILS, payload)
    return response
  }
)

export const getCitiesList = createAsyncThunk(
  GET_CITIES_LIST,
  async payload => {
    const response = await post(GET_CITIES_LIST, payload)
    return response
  }
)
