import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Modal, ModalBody } from "reactstrap"
import TrashIcon from "assets/images/icons/trash.svg"
import HoldIcon from "assets/images/icons/hold.svg"
import UnHoldIcon from "assets/images/icons/unhold.svg"

const DeleteModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  buttonText,
  bodyTitle,
  isLoading,
  iconName = false,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <img
                src={
                  iconName
                    ? iconName === "HOLD"
                      ? HoldIcon
                      : UnHoldIcon
                    : TrashIcon
                }
                alt="Trash Icon"
                width={24}
                height={24}
              />
            </div>
          </div>
          <p className="text-muted font-size-20 mb-4">{bodyTitle}</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="cancel_btn mx-2"
              onClick={onCloseClick}
            >
              {t("Close")}
            </button>
            <button
              type="button"
              className="primary_btn delete_btn"
              onClick={onDeleteClick}
              disabled={isLoading}
            >
              {buttonText}
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default DeleteModal
