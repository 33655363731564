import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Card, Col, Container, Row, Table } from "reactstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import { ascDescOptions } from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Paginations from "components/Common/Pagination"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
} from "components/Common/Utils"
import { getAllBuyingPayments } from "store/actions/buyingPaymentsAction"
import Spinners from "components/Shared/Spinners"
import { exportData } from "helpers/api_helper"
import { EXPORT_DATA } from "helpers/url_helper"

const History = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("History | Takween")

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    isGettingDetails,
    buyingPayments,
    buyingPaymentDetails,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.BuyingPayments)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    orderBy: 2,
  })

  useEffect(() => {
    fetchAllBuyingPayments()
  }, [])

  const fetchAllBuyingPayments = page => {
    const { orderBy } = filters

    const payload = {
      perPage,
      pageNo: page || pageNumber,
      orderBy,
      status: -1,
      unit: [], // STATIC
      customer: [], // STATIC
      paymentType: [8], // 8. History
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllBuyingPayments(payload))
    setIsApiCalled(true)
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllBuyingPayments(1)
  }

  const onPageChange = page => {
    fetchAllBuyingPayments(page)
  }

  const getExportFilters = () => {
    return {
      customer: [],
      paymentType: [8],
      status: -1,
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "BuyingDetails",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("History"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "BuyingDetails",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("History"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("History")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={ascDescOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                />
                {loading ? (
                  <Spinners />
                ) : buyingPayments.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Name")}</th>
                          {/* <th>{t("Last Name")}</th> */}
                          <th>{t("Unit Name")}</th>
                          <th>{t("Booking Id")}</th>
                          <th>{t("Current Invoice Number")}</th>
                          <th>{t("Total Amount")}</th>
                          <th>{t("Payment Method")}</th>
                          <th>{t("Full Payment Status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {buyingPayments.map((item, index) => (
                          <tr
                            key={item._id}
                            onClick={() => {
                              navigate(`/history/details?id=${item._id}`)
                            }}
                            className="cursor-pointer"
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{item?.userData?.name}</td>
                            {/* <td>{item?.buyerDetails?.lastName}</td> */}
                            <td>
                              {language === "sa"
                                ? item?.unitData?.nameAr
                                : item?.unitData?.name || "-"}
                            </td>
                            <td>{item.bookingNo || "-"}</td>
                            <td>{item.currentInvoice || "-"}</td>
                            <td>
                              {item?.paymentDetails?.total ?? "-"} {t("SAR")}
                            </td>
                            <td>
                              {t(
                                item?.invoices?.find(
                                  invoice => invoice?.type === "fullPayment"
                                )?.paymentMethod || "-"
                              )}
                            </td>
                            <td>
                              {item?.paymentDetails?.isFullPaymentDone ? (
                                <p className="rended_status status_tag">
                                  {t("Paid")}
                                </p>
                              ) : !item?.paymentDetails?.isFullPaymentDone ? (
                                <p className="sold_status status_tag">
                                  {t("Pending")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && buyingPayments.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default History
