import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Pie } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { useTranslation } from "react-i18next"
import StackedColumnChart from "pages/Dashboard/StackedColumnChart"
import { useDispatch, useSelector } from "react-redux"
import { getDashboardData } from "store/actions/commonAction"
import { getDashboardDateRange, getMonthLabels } from "components/Common/Utils"
import ShimmerEffect from "components/Shared/ShimmerEffect"

const monthData = [
  { month: 5, year: 2024, iOSHits: 10, androidHits: 20, websiteHits: 15 },
  { month: 6, year: 2024, iOSHits: 5, androidHits: 25, websiteHits: 10 },
  { month: 7, year: 2024, iOSHits: 15, androidHits: 20, websiteHits: 10 },
  { month: 8, year: 2024, iOSHits: 10, androidHits: 15, websiteHits: 12 },
  { month: 9, year: 2024, iOSHits: 8, androidHits: 18, websiteHits: 14 },
  { month: 10, year: 2024, iOSHits: 12, androidHits: 22, websiteHits: 25 },
]

const MarketingDashboard = ({ dateRange, setDateRange }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const dispatch = useDispatch()
  const { loading, dashboardData } = useSelector(state => state.Common)
  const [totalCounts, setTotalCounts] = useState(null)
  const [chartData, setChartData] = useState({
    labels: [t("iOS App"), t("Android App"), t("Website")],
    datasets: [
      {
        label: t("Number Of Hits"),
        data: [0, 0, 0],
        backgroundColor: ["#0C3553", "#D5B086", "#3CB3C1"],
        borderColor: ["#0C3553", "#D5B086", "#3CB3C1"],
        borderWidth: 1,
      },
    ],
  })

  const [chartLabels, setChartLabels] = useState([])
  const [periodData, setPeriodData] = useState([])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
          },
          color: "#7990a0",
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
  }

  useEffect(() => {
    fetchDashboardData()
  }, [])

  const fetchDashboardData = ({ isReset = false } = {}) => {
    const payload = {
      type: "marketing",
      filter: {
        dateRange: isReset ? {} : getDashboardDateRange(dateRange),
      },
    }
    dispatch(getDashboardData(payload))
  }

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchDashboardData()
    }
  }, [dateRange])

  useEffect(() => {
    if (dashboardData) {
      const {
        iOSHitCount = 0,
        androidHitCount = 0,
        websiteHitCount = 0,
      } = dashboardData

      // CALCULATE THE TOTAL
      const total = iOSHitCount + androidHitCount + websiteHitCount
      setTotalCounts(total)

      const updatedChartData = [iOSHitCount, androidHitCount, websiteHitCount]

      // MAKE DATA SETS FOR CHART
      setChartData(prevState => ({
        ...prevState,
        datasets: [{ ...prevState.datasets[0], data: updatedChartData }],
      }))

      //  Make series for chart

      const start = new Date(monthData[0].year, monthData[0].month - 1)
      const end = new Date(
        monthData[monthData.length - 1].year,
        monthData[monthData.length - 1].month - 1
      )

      const labels = getMonthLabels(start, end)
      setChartLabels(labels)

      const seriesData = [
        {
          name: t("iOS Hits"),
          data: monthData.map(item => item.iOSHits),
        },
        {
          name: t("Android Hits"),
          data: monthData.map(item => item.androidHits),
        },
        {
          name: t("Website Hits"),
          data: monthData.map(item => item.websiteHits),
        },
      ]

      setPeriodData(seriesData)
    }
  }, [dashboardData])

  // const columnData = [
  //   {
  //     name: t("iOS App"),
  //     data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48],
  //   },
  //   {
  //     name: t("Android App"),
  //     data: [22, 43, 36, 52, 24, 43, 55, 41, 67, 22, 36, 48],
  //   },
  //   {
  //     name: t("Website"),
  //     data: [48, 55, 78, 41, 12, 32, 65, 25, 12, 24, 24, 74],
  //   },
  // ]

  const getPlatformHits = platformType => {
    return dashboardData?.hits?.find(
      hit => hit.type.toLowerCase() === platformType.toLowerCase()
    )
  }

  return loading ? (
    <ShimmerEffect data={[0, 1]} />
  ) : (
    <Row className="marketing_dashboard">
      <Col lg={6}>
        <Card className="full_height_card">
          <CardBody>
            <h2 className="fs_24"> {t("Number Of Hits")} </h2>
            <div style={{ height: 430, direction: "ltr" }} className="d-flex">
              <Pie data={chartData} options={options} />
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={6} className="hit_details">
        <Card>
          <CardBody>
            <h2 className="fs_24"> {t("Hits Details")} </h2>
            <div style={{ maxHeight: 600 }} className="">
              <h3 className="total_hit_val">{totalCounts ?? "-"}</h3>
              <h4 className="total_hit_label">{t("Total Hits")}</h4>
              <hr className="hr_100" />
              <div className="inner_details">
                <div className="top_title">
                  <h2 className="circle ios">{t("iOS App")}</h2>
                  <h2>{dashboardData?.iOSHitCount ?? "-"}</h2>
                </div>
                <ul>
                  <li>
                    <span>{t("Home Page Hits")}</span>
                    <span>{getPlatformHits("iOS")?.homeHits ?? "-"}</span>
                  </li>
                  <li>
                    <span>{t("Product Page Hits")}</span>
                    <span>{getPlatformHits("iOS")?.productHits ?? "-"}</span>
                  </li>
                </ul>
              </div>
              <div className="inner_details">
                <div className="top_title">
                  <h2 className="circle">{t("Android App")}</h2>
                  <h2>{dashboardData?.androidHitCount ?? "-"}</h2>
                </div>
                <ul>
                  <li>
                    <span>{t("Home Page Hits")}</span>
                    <span>{getPlatformHits("android")?.homeHits ?? "-"}</span>
                  </li>
                  <li>
                    <span>{t("Product Page Hits")}</span>
                    <span>
                      {getPlatformHits("android")?.productHits ?? "-"}
                    </span>
                  </li>
                </ul>
              </div>
              <hr className="hr_100" />
              <div className="inner_details">
                <div className="top_title">
                  <h2 className="circle website">{t("Website")}</h2>
                  <h2>{dashboardData?.websiteHitCount ?? "-"}</h2>
                </div>
                <ul>
                  <li>
                    <span>{t("Home Page Hits")}</span>
                    <span>{getPlatformHits("website")?.homeHits ?? "-"}</span>
                  </li>
                  <li>
                    <span>{t("Product Page Hits")}</span>
                    <span>
                      {getPlatformHits("website")?.productHits ?? "-"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={12}>
        <Card>
          <CardBody>
            <div>
              <h2 className="fs_24"> {t("Number Of Hits Overview")} </h2>
            </div>
            <div style={{ direction: "ltr" }}>
              <StackedColumnChart
                periodData={periodData}
                chartLabels={chartLabels}
                dataColors='["#0C3553", "#D5B086", "#3CB3C1"]'
                height={450}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
ChartJS.register(ArcElement, Tooltip, Legend)

export default MarketingDashboard
