import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import useFilters from "components/Shared/useFilters"
import { useLocation, useNavigate } from "react-router-dom"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import { ascDescOptions } from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import FormateDate from "components/Shared/FormateDate"
import Paginations from "components/Common/Pagination"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
} from "components/Common/Utils"
import {
  getAllCustomersInterest,
  getCustomerInterestDetails,
} from "store/actions/customerInterestAction"
import Spinners from "components/Shared/Spinners"
import { getProjectsList } from "store/actions/projectsAction"
import IsLoading from "components/Shared/IsLoading"
import { EXPORT_DATA } from "helpers/url_helper"
import { exportData } from "helpers/api_helper"

const RegisteredInterests = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Registered Interests | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    customersInterest,
    customerInterestDetails,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.CustomerInterest)

  const { projectsList } = useSelector(state => state.Projects)
  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    orderBy: 2,
  })

  const [modal, setModal] = useState(false)

  useEffect(() => {
    fetchAllCustomerInterest()
    fetchProjectsList()
  }, [])

  const fetchAllCustomerInterest = page => {
    const { orderBy, project } = filters
    const updatedProjects = project?.map(item => item.value) || []

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      orderBy,
      keyword,
      project: updatedProjects,
      customer: [],
      dateRange: getDateRange(dateRange),
    }

    dispatch(getAllCustomersInterest(payload))
    setIsApiCalled(true)

    navigate(`/registered-interests?page=${page || pageNumber}`)
  }

  const fetchProjectsList = () => {
    const queryParams = "?status=-1"
    dispatch(getProjectsList(queryParams))
  }

  const additionOptions = [
    {
      name: "project",
      type: "multiSelect",
      options: projectsList?.map(item => ({
        label: item.name,
        value: item._id,
      })),
      placeholder: t("Select Projects"),
    },
  ]

  const filterOptions = [...ascDescOptions, ...additionOptions]

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id: selectedId,
      }
      dispatch(getCustomerInterestDetails(payload))
    }
    selectedId && modal && fetchDetails()
  }, [selectedId])

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllCustomerInterest(1)
  }

  const onPageChange = page => {
    fetchAllCustomerInterest(page)
  }

  const toggle = () => {
    modal && setSelectedId(null)
    setModal(!modal)
  }

  const getExportFilters = () => {
    const { project } = filters
    const updatedProjects = project?.map(item => item.value) || []

    return {
      project: updatedProjects,
      customer: [],
      dateRange: getDateRange(dateRange),
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "MyInterest",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Registered Interests"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "MyInterest",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Registered Interests"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Registered Interests")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={4}
                />
                {loading ? (
                  <Spinners />
                ) : customersInterest.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Customer Name")}</th>
                          <th>{t("Project Name")}</th>
                          <th>{t("Customer Phone")}</th>
                          <th>{t("Registration Date")}</th>
                          {/* <th>{t("Launch Date")}</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {customersInterest.map((interest, index) => (
                          <tr
                            key={interest._id}
                            className="cursor-pointer"
                            onClick={() => {
                              toggle()
                              setSelectedId(interest._id)
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{interest?.userData?.name}</td>
                            <td>
                              {language === "sa"
                                ? interest?.projectData?.nameAr
                                : interest?.projectData?.name || "-"}
                            </td>
                            <td>{interest?.userData?.phone}</td>
                            <td>{FormateDate(interest.createdAt)}</td>
                            {/* <td>{FormateDate(interest.launchDate)}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && customersInterest.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        className={`g_modal w_850`}
      >
        <h2 className="modal_title">
          {t("Registered Interest Customer Details")}
        </h2>
        <button type="button" onClick={toggle} className="modal_close_btn">
          <i className="mdi mdi-close"></i>
        </button>
        {isLoading ? (
          <div className="pt-4">
            <IsLoading />
          </div>
        ) : (
          <div>
            <Row>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Id")}
                  value={customerInterestDetails?._id || "-"}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Project")}
                  value={
                    language === "sa"
                      ? customerInterestDetails?.projectId?.nameAr
                      : customerInterestDetails?.projectId?.name || "-"
                  }
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Customer Name")}
                  value={customerInterestDetails?.userId?.name || "-"}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Customer Phone")}
                  value={customerInterestDetails?.userId?.phone || "-"}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Registration Date")}
                  value={FormateDate(customerInterestDetails?.createdAt)}
                />
              </Col>
              <Col md={4}>
                <DetailsInnerDiv
                  label={t("Updated At")}
                  value={FormateDate(customerInterestDetails?.updatedAt)}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default RegisteredInterests
