import React, { useEffect } from "react"
import { Card, Col, Container, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import Title from "components/Shared/Title"
import { Link, useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import {
  ascDescOptions,
  baseFilterOptions,
  commonFilterOptions,
  projectStatusType,
  projectTypes,
} from "components/Common/constants"
import Filters from "components/Shared/Filters"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Paginations from "components/Common/Pagination"
import TopTitle from "components/Shared/TopTitle"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import ViewIcon from "assets/images/icons/view.svg"
import DeleteModal from "components/Common/DeleteModal"
import Switch from "components/Shared/Switch"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
  handleStopPropagation,
} from "components/Common/Utils"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteProject,
  getAllProjects,
  updateProjectStatus,
} from "store/actions/projectsAction"
import Spinners from "components/Shared/Spinners"
import { showAlert } from "store/reducers/alertReducer"
import { getProjectStatus } from "components/Common/StatusUtils"
import { EXPORT_DATA } from "helpers/url_helper"
import { exportData } from "helpers/api_helper"
import { getCitiesList } from "store/actions/citiesAction"

const Projects = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Projects | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    projects,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.Projects)
  const { citiesList } = useSelector(state => state.Cities)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    statusType: [],
    type: [],
    city: [],
  })

  useEffect(() => {
    fetchAllProjects()
    const fetchCity = () => {
      dispatch(getCitiesList())
    }
    fetchCity()
  }, [])

  const fetchAllProjects = page => {
    const { orderBy, statusType, type, city } = filters
    const updatedStatusTypes = statusType?.map(item => item.value) || []
    const updatedTypes = type?.map(item => item.value) || []
    const updatedCities = city?.map(item => item.value) || []

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: -1, //static
      orderBy,
      keyword,
      statusType: updatedStatusTypes,
      type: updatedTypes,
      city: updatedCities,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllProjects(payload))
    setIsApiCalled(true)

    navigate(`/projects?page=${page || pageNumber}`)
  }

  const extendedProjectStatusType = [
    { value: 0, label: "Disabled" },
    ...projectStatusType,
  ]

  const additionOptions = [
    {
      name: "statusType", // WE HAVE CHANGE STATUS TO STATUS_TYPE, NOW IT'S MERGED
      type: "multiSelect",
      options: extendedProjectStatusType.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Status"),
    },
    {
      name: "type",
      type: "multiSelect",
      options: projectTypes.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Types"),
    },
    {
      name: "city",
      type: "multiSelect",
      options: citiesList.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Cities"),
    },
  ]

  const filterOptions = [...ascDescOptions, ...additionOptions]

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllProjects(1)
  }

  const onPageChange = page => {
    fetchAllProjects(page)
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 0 ? "1" : "0",
    }
    dispatch(updateProjectStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deleteProject(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const getExportFilters = () => {
    const { statusType, type, city } = filters
    const updatedStatusTypes = statusType?.map(item => item.value) || []
    const updatedTypes = type?.map(item => item.value) || []
    const updatedCities = city?.map(item => item.value) || []

    return {
      status: -1, //static
      statusType: updatedStatusTypes,
      type: updatedTypes,
      city: updatedCities,
      dateRange: getDateRange(dateRange),
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "Project",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Projects"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "Project",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Projects"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content projects_page">
        <Container fluid>
          <TopTitle
            title={t("Projects")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
            createBtnText={t("Create Project")}
            onCreateBtnClick={() => {
              navigate("/projects/create")
            }}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Projects")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={3}
                />
                {loading ? (
                  <Spinners />
                ) : projects.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Image")}</th>
                          <th>{t("Name")}</th>
                          {/* <th>{t("Total Units")}</th>
                        <th>{t("Available Units")}</th>
                        <th>{t("Number Of Floors")}</th> */}
                          <th>{t("Interface")}</th>
                          <th>{t("Purpose")}</th>
                          {/* <th>{t("Status Type")}</th> */}
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projects.map((item, index) => (
                          <tr
                            key={item._id}
                            onClick={() =>
                              navigate(`/projects/${item._id}/units`)
                            }
                            className="cursor-pointer"
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>
                              <img
                                src={item.imageUrl}
                                alt={item.projectName}
                                width={36}
                                height={36}
                                className="object_fit_contain"
                              />
                            </td>
                            <td>
                              {language === "sa"
                                ? item.nameAr
                                : item.name || "-"}
                            </td>
                            {/* <td>
                            {item?.generalDetails?.totalNumberOfUnits || "-"}
                          </td>
                          <td>
                            {item?.generalDetails
                              ?.totalNumberOfAvailableUnits || "-"}
                          </td>
                          <td>
                            {item?.generalDetails?.totalNumberOfFloors || "-"}
                          </td> */}
                            <td>{t(item?.generalDetails?.interface || "-")}</td>
                            <td>{t(item?.generalDetails?.purpose || "-")}</td>
                            <td>
                              <p
                                className={`${
                                  getProjectStatus(item.status).className
                                } status_tag`}
                              >
                                {t(getProjectStatus(item.status).label)}
                              </p>
                            </td>
                            {/* <td>
                              {item.status === 1 ? (
                                <p className="rended_status status_tag">
                                  {t("Active")}
                                </p>
                              ) : item.status === 0 ? (
                                <p className="reject_status status_tag">
                                  {t("Deactive")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td> */}
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center">
                                <img
                                  src={EditIcon}
                                  alt="Edit Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(`/projects/update?id=${item._id}`)
                                  }
                                />
                                <img
                                  src={TrashIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer mx-3"
                                  onClick={() => {
                                    setShowDeleteModal(true)
                                    setItemIdToDelete(item._id)
                                  }}
                                />
                                <img
                                  src={ViewIcon}
                                  alt="View Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer me-3"
                                  onClick={() => {
                                    navigate(`/projects/details?id=${item._id}`)
                                  }}
                                />
                                <Switch
                                  onClickHandler={() => {
                                    handleStatusClick(item._id, item.status)
                                  }}
                                  isChecked={item.status === 0 ? false : true}
                                  disabled={
                                    isLoading && selectedId === item._id
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && projects.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}{" "}
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("Project"),
        })}
      />
    </React.Fragment>
  )
}

export default Projects
