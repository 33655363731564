import { commonFilterOptions } from "components/Common/constants"
import { getDateRange, handleStopPropagation } from "components/Common/Utils"
import Filters from "components/Shared/Filters"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Spinners from "components/Shared/Spinners"
import Title from "components/Shared/Title"
import TopTitle from "components/Shared/TopTitle"
import useFilters from "components/Shared/useFilters"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Card, Col, Container, Row, Table } from "reactstrap"
import {
  deleteServiceItem,
  getAllServiceItems,
  updateServiceItemStatus,
} from "store/actions/serviceItemsAction"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import Switch from "components/Shared/Switch"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import { getServicesList } from "store/actions/servicesAction"
import { showAlert } from "store/reducers/alertReducer"

const ServiceItems = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Service Items | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    serviceItems,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.ServiceItems)

  const { servicesList } = useSelector(state => state.Services)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    service: [],
  })

  useEffect(() => {
    fetchAllServiceItems()
    fetchServicesList()
  }, [])

  const fetchAllServiceItems = page => {
    const { status, orderBy, service } = filters
    const updatedServices = service?.map(item => item.value) || []

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      service: updatedServices,
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllServiceItems(payload))
    setIsApiCalled(true)

    navigate(`/service-items?page=${page || pageNumber}`)
  }

  const fetchServicesList = () => {
    const payload = {
      type: ["features"],
    }
    dispatch(getServicesList(payload))
  }

  const additionOptions = [
    {
      name: "service",
      type: "multiSelect",
      options: servicesList.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Select Services"),
    },
  ]

  const filterOptions = [...commonFilterOptions, ...additionOptions]

  // RECALL API ONCHANGE FILTER
  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllServiceItems(1)
  }

  const onPageChange = page => {
    fetchAllServiceItems(page)
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 1 ? "0" : "1",
    }
    dispatch(updateServiceItemStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deleteServiceItem(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Service Items")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
            createBtnText={t("Create")}
            onCreateBtnClick={() => {
              navigate("/service-items/create")
            }}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Service Items List")} />
                <div className="pb-2"></div>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={4}
                />
                {loading ? (
                  <Spinners />
                ) : serviceItems.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("# ")}</th>
                          <th>{t("Name")}</th>
                          <th>{t("Price")}</th>
                          <th>{t("Service Name")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceItems.map((item, index) => (
                          <tr
                            key={item._id}
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(`/service-items/details?id=${item._id}`)
                            }}
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>
                              {language === "sa"
                                ? item?.nameAr
                                : item?.name || "-"}
                            </td>
                            <td>{`${item?.price ?? "-"} ${item?.currency}`}</td>
                            <td>
                              {language === "sa"
                                ? item?.serviceId?.nameAr
                                : item?.serviceId?.name || "-"}
                            </td>

                            <td>
                              {item.status === 1 ? (
                                <p className="rended_status status_tag">
                                  {t("Active")}
                                </p>
                              ) : item.status === 0 ? (
                                <p className="reject_status status_tag">
                                  {t("Deactive")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center">
                                <img
                                  src={EditIcon}
                                  alt="Edit Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/service-items/update?id=${item._id}`
                                    )
                                  }
                                />
                                <img
                                  src={TrashIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer mx-3"
                                  onClick={() => {
                                    setItemIdToDelete(item._id)
                                    setShowDeleteModal(true)
                                  }}
                                />
                                <Switch
                                  onClickHandler={() => {
                                    handleStatusClick(item._id, item.status)
                                  }}
                                  isChecked={item.status === 1 ? true : false}
                                  disabled={
                                    isLoading && selectedId === item._id
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && serviceItems.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("Service Item"),
        })}
      />
    </React.Fragment>
  )
}

export default ServiceItems
