import DeleteModal from "components/Common/DeleteModal"
import { handleFileUpload } from "components/Common/Utils"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import CustomLabel from "components/Shared/CustomLabel"
import FileUpload from "components/Shared/FileUpload"
import VideoPreview from "components/Shared/VideoPreview"
import { Field, Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { updateProject, uploadProjectVideo } from "store/actions/projectsAction"
import { showAlert } from "store/reducers/alertReducer"

const initialValues = {
  videoUrlType: "URL",
  videoUrl: "",
  video: "",
}

const Video = ({ id }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, isLoading, projectDetails } = useSelector(
    state => state.Projects
  )
  const [formValues, setFormValues] = useState(initialValues)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    if (projectDetails) {
      const { videoUrlType, videoUrl } = projectDetails

      setFormValues({
        videoUrlType: videoUrlType || "UPLOAD",
        videoUrl: videoUrlType === "URL" ? videoUrl : "",
        video: videoUrlType === "UPLOAD" ? videoUrl : "",
      })
    }
  }, [projectDetails])

  const handleOnSubmit = (values, { resetForm }) => {
    const { videoUrlType, videoUrl, video } = values

    let data = new FormData()

    data.append("id", id)
    data.append("videoUrlType", videoUrlType)
    videoUrlType === "URL"
      ? data.append("videoUrl", videoUrl)
      : data.append("video", video)

    dispatch(uploadProjectVideo(data))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const deleteProjectVideo = setFieldValue => {
    

    let data = new FormData()

    data.append("id", id)
    data.append("videoUrl", "")

    dispatch(updateProject(data))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setFieldValue("video", null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <div className="project_video">
      <Row className="g_label">
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          onSubmit={handleOnSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <Col lg={12}>
                <div className="">
                  <div className="form_field">
                    <CustomLabel htmlFor="image" label={t("Video URL Type")} />
                  </div>
                  <div className="d-flex ">
                    <div className="form-check mx-2">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="videoUrlType"
                        id="URL"
                        value="URL"
                        onClick={() => {
                          setFieldValue("videoUrlType", "URL")
                          // setFieldValue("videoUrl", "")
                        }}
                      />
                      <label className="form-check-label" htmlFor="URL">
                        {t("URL")}
                      </label>
                    </div>
                    <div className="form-check mx-2">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="videoUrlType"
                        id="UPLOAD"
                        value="UPLOAD"
                        onClick={() => {
                          setFieldValue("videoUrlType", "UPLOAD")
                          // setFieldValue("videoUrl", "")
                        }}
                      />
                      <label className="form-check-label" htmlFor="UPLOAD">
                        {t("UPLOAD")}
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                {values.videoUrlType === "URL" ? (
                  <div className="form_field">
                    <CustomLabel htmlFor="videoUrl" label={t("Video Url")} />
                    <Field
                      name="videoUrl"
                      type="text"
                      id="videoUrl"
                      placeholder={t("Video Url")}
                      className={`form-control ${
                        touched.videoUrl && errors.videoUrl ? "is-invalid" : ""
                      }`}
                    />
                    <CustomErrorMessage name={"videoUrl"} />
                    {values.videoUrl && (
                      <div className="mt-4">
                        <VideoPreview url={values.videoUrl} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="mt-3">
                    <FileUpload
                      name="video"
                      value={values.video}
                      touched={touched.video}
                      error={errors.video}
                      labelText={t("Upload Video")}
                      accept="video/*"
                      setFieldValue={setFieldValue}
                      fileType="video"
                      handleFileUpload={event => {
                        handleFileUpload(event, setFieldValue, "video")
                      }}
                      isCustomDelete={true}
                      handleVideoDelete={() => {
                        setShowDeleteModal(true)
                      }}
                    />
                  </div>
                )}
              </Col>
              <Col lg={12}>
                <div className="d-flex justify-content-end mt-3 gap-3">
                  <button
                    type="button"
                    className="cancel_btn"
                    onClick={() => navigate(-1)}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="primary_btn"
                    type="submit"
                    disabled={
                      isLoading || values.videoUrlType === "URL"
                        ? !values.videoUrl
                        : !values.video
                    }
                  >
                    {t("Save")}
                  </button>
                </div>
              </Col>
              <DeleteModal
                show={showDeleteModal}
                onDeleteClick={() => {
                  deleteProjectVideo(setFieldValue)
                }}
                onCloseClick={() => {
                  setShowDeleteModal(false)
                }}
                isLoading={isLoading}
                buttonText={t("Delete")}
                bodyTitle={t("Are you sure you want to delete this {{name}}?", {
                  name: t("Video"),
                })}
              />
            </Form>
          )}
        </Formik>
      </Row>
    </div>
  )
}

export default Video
