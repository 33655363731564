import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { addBannerSchema } from "components/Common/validation"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  getImagePreview,
  handle_4_3_FileUpload,
  handleBannerUpload,
  handleFileUpload,
} from "components/Common/Utils"
import Switch from "components/Shared/Switch"
import ImgPreview from "components/Shared/ImgPreview"
import FileUpload from "components/Shared/FileUpload"
import TopTitle from "components/Shared/TopTitle"
import CustomLabel from "components/Shared/CustomLabel"
import FormButtons from "components/Shared/FormButtons"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import {
  createBanner,
  getBannerDetails,
  updateBanner,
} from "store/actions/bannersAction"
import { showAlert } from "store/reducers/alertReducer"
import { useDispatch, useSelector } from "react-redux"
import Spinners from "components/Shared/Spinners"
import BackButton from "components/Shared/BackButton"
import { bannerUploadMaxSize } from "components/Common/FileUploadSizeUtils"

const initialValues = {
  title: "",
  titleAr: "",
  image: "",
  imageAr: "",
  displayOrder: "",
  applySameImageOnBothLang: false,
}

const CreateBanner = ({ mode }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t(
    `${
      mode === "create"
        ? t("Create Banner | Takween")
        : t("Update Banner | Takween")
    }`
  )

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { loading, isLoading, bannerDetails } = useSelector(
    state => state.Banners
  )
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getBannerDetails(payload))
    }
    mode === "update" && fetchDetails()
  }, [])

  useEffect(() => {
    if (bannerDetails && mode === "update") {
      const {
        title,
        titleAr,
        imageUrl,
        imageUrlAr,
        applySameImageOnBothLang,
        displayOrder,
      } = bannerDetails

      setFormValues(prev => ({
        ...prev,
        title,
        titleAr,
        image: imageUrl,
        imageAr: imageUrlAr,
        applySameImageOnBothLang,
        displayOrder,
      }))
    }
  }, [mode, bannerDetails])

  const handleOnSubmit = values => {
    const {
      title,
      titleAr,
      image,
      imageAr,
      displayOrder,
      applySameImageOnBothLang,
    } = values

    let data = new FormData()
    data.append("title", title)
    data.append("titleAr", titleAr)
    data.append("image", image)
    !applySameImageOnBothLang && data.append("imageAr", imageAr)
    data.append("applySameImageOnBothLang", applySameImageOnBothLang)
    data.append("displayOrder", displayOrder)

    if (mode === "create") {
      dispatch(createBanner(data))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/banners")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (mode === "update") {
      data.append("id", id)
      dispatch(updateBanner(data))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            navigate("/banners")
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {mode === "update" && loading ? (
          <Spinners />
        ) : (
          <Container fluid>
            <TopTitle
              title={t("Banner")}
              subTitle={t(
                `Hi, {{name}}. Welcome back to your Marketing portal!`,
                { name: name }
              )}
            />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h2 className="fs_18_700 g_start">
                      <BackButton />
                      {mode === "create"
                        ? t("Create Banner Set")
                        : t("Update Banner Set")}
                    </h2>
                    <Formik
                      initialValues={formValues}
                      enableReinitialize={true}
                      validationSchema={addBannerSchema}
                      onSubmit={handleOnSubmit}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel htmlFor="" label={t("English")} />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel htmlFor="" label={t("Arabic")} />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="title"
                                  label={t("Title")}
                                  // isRequired={true}
                                />
                                <Field
                                  name="title"
                                  type="text"
                                  id="title"
                                  placeholder={t("Title")}
                                  className={`form-control ${
                                    touched.title && errors.title
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"title"} />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="titleAr"
                                  label={t("Title")}
                                  // isRequired={true}
                                />
                                <Field
                                  name="titleAr"
                                  type="text"
                                  id="titleAr"
                                  placeholder={t("Title")}
                                  className={`form-control rtl ${
                                    touched.titleAr && errors.titleAr
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"titleAr"} />
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor="displayOrder"
                                  label={t("Display Order")}
                                  // isRequired={true}
                                />
                                <Field
                                  name="displayOrder"
                                  type="text"
                                  id="displayOrder"
                                  placeholder={t("Display Order")}
                                  className={`form-control ${
                                    touched.displayOrder && errors.displayOrder
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <CustomErrorMessage name={"displayOrder"} />
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="g_label mt-2">
                                <label
                                  htmlFor="applySameImageOnBothLang"
                                  className=""
                                >
                                  {t(
                                    "Apply same banner for both English & Arabic"
                                  )}
                                </label>
                                <Switch
                                  onClickHandler={e => {
                                    setFieldValue(
                                      "applySameImageOnBothLang",
                                      !values.applySameImageOnBothLang
                                    )
                                  }}
                                  isChecked={values.applySameImageOnBothLang}
                                />
                              </div>
                            </Col>

                            <Col lg={6}>
                              <div className="form_field">
                                <FileUpload
                                  name="image"
                                  value={values.image}
                                  touched={touched.image}
                                  error={errors.image}
                                  labelText={
                                    values.applySameImageOnBothLang
                                      ? t("Upload Banner")
                                      : t("Upload Banner Image For English")
                                  }
                                  setFieldValue={setFieldValue}
                                  handleFileUpload={event =>
                                    handle_4_3_FileUpload(
                                      event,
                                      setFieldValue,
                                      "image",
                                      true, // Enable size validation
                                      bannerUploadMaxSize
                                    )
                                  }
                                />
                                <CustomErrorMessage name={"image"} />
                                <p className="fs_14_500 opacity-75">
                                  {t(
                                    `Recommended Image Size: Rectangle (4:3 ratio, e.g., 800*600 px). Max size: {{maxSize}} KB`,
                                    { maxSize: bannerUploadMaxSize }
                                  )}
                                </p>
                              </div>
                            </Col>

                            {!values.applySameImageOnBothLang && (
                              <Col lg={6}>
                                <div className="form_field">
                                  <FileUpload
                                    name="imageAr"
                                    value={values.imageAr}
                                    touched={touched.imageAr}
                                    error={errors.imageAr}
                                    labelText={t(
                                      "Upload Banner Image For Arabic"
                                    )}
                                    setFieldValue={setFieldValue}
                                    // handleFileUpload={handleBannerUpload}
                                    handleFileUpload={event =>
                                      handle_4_3_FileUpload(
                                        event,
                                        setFieldValue,
                                        "imageAr",
                                        true, // Enable size validation
                                        bannerUploadMaxSize
                                      )
                                    }
                                  />
                                  <CustomErrorMessage name={"imageAr"} />
                                  <p className="fs_14_500 opacity-75">
                                    {t(
                                      `Recommended Image Size: Rectangle (4:3 ratio, e.g., 800*600 px). Max size: {{maxSize}} KB`,
                                      { maxSize: bannerUploadMaxSize }
                                    )}
                                  </p>
                                </div>
                              </Col>
                            )}

                            <Col lg={12}>
                              <FormButtons
                                buttonText={
                                  mode === "create" ? t("Create") : t("Update")
                                }
                                isLoading={isLoading}
                              />
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default CreateBanner
