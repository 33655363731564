import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  del,
  formDataPost,
  formDataPut,
  get,
  patch,
  post,
  put,
} from "helpers/api_helper"
import {
  CREATE_SERVICE_ITEM,
  DELETE_SERVICE_ITEM,
  GET_ALL_SERVICE_ITEM,
  GET_SERVICE_ITEM_DETAILS,
  GET_SERVICE_ITEM_LIST,
  UPDATE_SERVICE_ITEM,
  UPDATE_SERVICE_ITEM_STATUS,
} from "helpers/url_helper"

export const getAllServiceItems = createAsyncThunk(
  GET_ALL_SERVICE_ITEM,
  async payload => {
    const response = await post(GET_ALL_SERVICE_ITEM, payload)
    return response
  }
)

export const getServiceItemList = createAsyncThunk(
  GET_SERVICE_ITEM_LIST,
  async payload => {
    const response = await post(GET_SERVICE_ITEM_LIST, payload)
    return response
  }
)

export const updateServiceItemStatus = createAsyncThunk(
  UPDATE_SERVICE_ITEM_STATUS,
  async payload => {
    const response = await patch(UPDATE_SERVICE_ITEM_STATUS, payload)
    return response
  }
)

export const deleteServiceItem = createAsyncThunk(
  DELETE_SERVICE_ITEM,
  async payload => {
    const response = await del(DELETE_SERVICE_ITEM, payload)
    return response
  }
)

export const getServiceItemDetails = createAsyncThunk(
  GET_SERVICE_ITEM_DETAILS,
  async payload => {
    const response = await post(GET_SERVICE_ITEM_DETAILS, payload)
    return response
  }
)

export const createServiceItem = createAsyncThunk(
  CREATE_SERVICE_ITEM,
  async payload => {
    const response = await formDataPost(CREATE_SERVICE_ITEM, payload)
    return response
  }
)

export const updateServiceItem = createAsyncThunk(
  UPDATE_SERVICE_ITEM,
  async payload => {
    const response = await formDataPut(UPDATE_SERVICE_ITEM, payload)
    return response
  }
)
