import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"

import TopTitle from "components/Shared/TopTitle"
import { useDispatch, useSelector } from "react-redux"

import Spinners from "components/Shared/Spinners"
import { showAlert } from "store/reducers/alertReducer"

import {
  getPageContentByType,
  updatePageContentByType,
} from "store/actions/contentManagementAction"

import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const TermsAndConditions = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Terms And Conditions | Takween")

  const dispatch = useDispatch()

  const { loading, isLoading, pageContent } = useSelector(
    state => state.ContentManagements
  )

  const name = useSelector(state => state.Login.userProfile?.name) || ""
  const quillRefAr = useRef(null)

  const [description, setDescription] = useState("")
  const [descriptionAr, setDescriptionAr] = useState("")

  useEffect(() => {
    fetchContent()
  }, [])

  useEffect(() => {
    if (pageContent) {
      const {  description, descriptionAr, } =
        pageContent
      setDescription(description)
      setDescriptionAr(descriptionAr)
    }
  }, [pageContent])

  // Apply RTL direction for Arabic ReactQuill
  useEffect(() => {
    const editor = quillRefAr.current?.getEditor() // Access the Quill editor instance
    if (editor) {
      const root = editor.root
      root.setAttribute("dir", "rtl") // Set RTL for the Arabic editor
      root.style.textAlign = "right" // Ensure proper alignment
    }
  }, [pageContent])

  const fetchContent = () => {
    const payload = {
      type: "TermsAndConditions", // TermsAndConditions , PrivacyPolicy
    }
    dispatch(getPageContentByType(payload))
  }

  const handleSubmit = () => {
    const payload = {
      type: "TermsAndConditions", // TermsAndConditions , PrivacyPolicy
      title: "Terms And Conditions", // STATIC
      titleAr: "الشروط والأحكام", // STATIC
      description,
      descriptionAr,
    }

    dispatch(updatePageContentByType(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Terms And Conditions")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <Spinners />
                  ) : (
                    <Row>
                      <Col lg={6}>
                        <div className="form_field">
                          <label htmlFor="name">{t("English")}</label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form_field">
                          <label htmlFor="name">{t("Arabic")}</label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <ReactQuill
                          value={description}
                          onChange={setDescription}
                          placeholder={t("Write your terms and conditions here..."
                          )}
                        />
                      </Col>
                      <Col lg={6}>
                        <div style={{ direction: "rtl" }}>
                          <ReactQuill
                            ref={quillRefAr}
                            value={descriptionAr}
                            onChange={setDescriptionAr}
                            placeholder={t("اكتب شروطك وأحكامك هنا..."
                            )}
                          />
                        </div>
                      </Col>

                      <div className="d-flex justify-content-end mt-3">
                        <button
                          className="primary_btn"
                          type="submit"
                          disabled={isLoading}
                          onClick={handleSubmit}
                        >
                          {t("Save")}
                        </button>
                      </div>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TermsAndConditions
