import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import {
  CREATE_ROOM_TYPE,
  DELETE_ROOM_TYPE,
  GET_ALL_ROOM_TYPE,
  GET_ROOM_TYPE_DETAILS,
  GET_ROOM_TYPE_LISTS,
  UPDATE_ROOM_TYPE,
  UPDATE_ROOM_TYPE_STATUS,
} from "helpers/url_helper"

export const getAllRoomTypes = createAsyncThunk(
  GET_ALL_ROOM_TYPE,
  async payload => {
    const response = await post(GET_ALL_ROOM_TYPE, payload)
    return response
  }
)

export const getRoomTypesList = createAsyncThunk(
  GET_ROOM_TYPE_LISTS,
  async () => {
    const response = await get(GET_ROOM_TYPE_LISTS)
    return response
  }
)

export const getRoomTypeDetails = createAsyncThunk(
  GET_ROOM_TYPE_DETAILS,
  async payload => {
    const response = await post(GET_ROOM_TYPE_DETAILS, payload)
    return response
  }
)

export const updateRoomTypeStatus = createAsyncThunk(
  UPDATE_ROOM_TYPE_STATUS,
  async payload => {
    const response = await patch(UPDATE_ROOM_TYPE_STATUS, payload)
    return response
  }
)

export const deleteRoomType = createAsyncThunk(
  DELETE_ROOM_TYPE,
  async payload => {
    const response = await del(DELETE_ROOM_TYPE, payload)
    return response
  }
)

export const createRoomType = createAsyncThunk(
  CREATE_ROOM_TYPE,
  async payload => {
    const response = await post(CREATE_ROOM_TYPE, payload)
    return response
  }
)

export const updateRoomType = createAsyncThunk(UPDATE_ROOM_TYPE, async payload => {
  const response = await put(UPDATE_ROOM_TYPE, payload)
  return response
})
