import React from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Row } from "reactstrap"
import SMS from "assets/images/icons/marketing/sms.svg"
import PushNotification from "assets/images/icons/marketing/notification.svg"
import RegisteredIcon from "assets/images/icons/customerSupport/RegisteredInterest.svg"
import QrCode from "assets/images/icons/customerSupport/QrCode.svg"

import { Link } from "react-router-dom"

const CustomerSupportTopBar = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const marketingDashboardTopBar = [
    {
      id: 1,
      imgUrl: SMS,
      text: t("SMS Campaigns"),
      redirectLink: "/sms-notifications",
    },
    {
      id: 2,
      imgUrl: PushNotification,
      text: t("Notification Campaigns"),
      redirectLink: "/push-notifications",
    },
    {
      id: 3,
      imgUrl: RegisteredIcon,
      text: t("Registered Interest"),
      redirectLink: "/registered-interests",
    },
    {
      id: 4,
      imgUrl: QrCode,
      text: t("QR Code"),
      redirectLink: "/qr-code",
    },
  ]

  return (
    <Card className="dashboard_top_bar">
      <CardBody>
        <Row>
          <h2>{t("Team")}</h2>
          <p>{t("Customer Support Team")}</p>
          {marketingDashboardTopBar &&
            marketingDashboardTopBar.map(item => (
              <Col lg={3} key={item.id}>
                <Link to={item.redirectLink}>
                  <div className="inner_section">
                    <img src={item.imgUrl} alt="unit icon" />
                    <h3>{t(item.text)}</h3>
                  </div>
                </Link>
              </Col>
            ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default CustomerSupportTopBar
