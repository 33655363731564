import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import DetailsTitle from "components/Shared/DetailsTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { getFaqDetails } from "store/actions/contentManagementAction"
import TopTitle from "components/Shared/TopTitle"

const FaqDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("FAQ Details | Takween")

  const dispatch = useDispatch()
  const { loading, faqDetails } = useSelector(state => state.ContentManagements)
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getFaqDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("FAQ Details")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("FAQ Details")} />
                  {loading ? (
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  ) : Object.values(faqDetails).length === 0 ? (
                    <p className="text-center my-3">
                      <NoDataAvailable />
                    </p>
                  ) : (
                    <Row className="g_details">
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Id")}
                          value={faqDetails._id}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Title(English)")}
                          value={faqDetails.title}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Title(عربي)")}
                          value={faqDetails.titleAr}
                        />
                      </Col>
                      <Col md={3}>
                        <DetailsInnerDiv
                          label={t("Status")}
                          value={
                            faqDetails.status === 1
                              ? t("Active")
                              : t("Deactive")
                          }
                          valueClass={`${
                            faqDetails.status === 1
                              ? "active_color"
                              : "danger_color"
                          }`}
                        />
                      </Col>
                      <Col md={6}>
                        <DetailsInnerDiv
                          label={t("Description(English)")}
                          value={faqDetails.description}
                        />
                      </Col>
                      <Col md={6}>
                        <DetailsInnerDiv
                          label={t("Description(عربي)")}
                          value={faqDetails.descriptionAr}
                        />
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FaqDetails
