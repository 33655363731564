// AUTH
export const LOGIN_URL = "auth/login" // DONE
export const LOGOUT_URL = "auth/logout" // DONE
export const CHANGE_LANGUAGE = "auth/change-language" // DONE

// USER RELATED API
export const REGENERATE_TOKEN = "auth/regenerate-token"
export const USER_DETAILS = "auth/details" // DONE
export const UPDATE_USER_DETAILS = "auth/details/update" // DONE
export const CHANGE_USER_PASSWORD = "auth/details/update-password" // DONE

// FORGOT PASSWORD FLOW
export const FORGOT_PASSWORD = "auth/forgot-password" // DONE
export const VERIFY_OTP = "auth/verify-otp" // DONE
export const RESET_PASSWORD = "auth/reset-password" // DONE

// NEW USER PASSWORD RESET
export const VALIDATE_UUID = "auth/validate-uuid" // DONE
export const RESET_PASSWORD_FOR_USER = "auth/new-user/reset-password" // DONE

// USER MANAGEMENTS
export const GET_ALL_USERS = "admin/user/all" // DONE
export const GET_ROLE_LISTS = "admin/roles/list" // DONE
export const GET_USER_DETAILS = "admin/user/details" // DONE
export const CREATE_USER = "admin/user/add" // DONE
export const UPDATE_USER = "admin/user/details/update" // DONE
export const UPDATE_USER_STATUS = "admin/user/details/update-status" // DONE
export const DELETE_USER = "admin/user/delete" // DONE

// MAINTENANCE USER MANAGEMENTS
export const GET_ALL_MAINTENANCE_USERS = "admin/service-provider/all"
export const GET_MAINTENANCE_USER_LISTS = "admin/service-provider/list"
export const GET_MAINTENANCE_USER_DETAILS = "admin/service-provider/details"
export const UPDATE_MAINTENANCE_USER_STATUS =
  "admin/service-provider/details/update-status"
export const DELETE_MAINTENANCE_USER = "admin/service-provider/delete"
export const CREATE_MAINTENANCE_USER = "admin/service-provider/add"
export const UPDATE_MAINTENANCE_USER = "admin/service-provider/details/update"

// SERVICE REQUEST MANAGEMENTS
export const GET_ALL_SERVICE_REQUEST = "maintenance/service-request/all" // DONE
export const GET_SERVICE_REQUEST_DETAILS = "maintenance/service-request/details" // DONE
export const CANCEL_SERVICE_REQUEST_STATUS =
  "maintenance/service-request/details/update-status" // DONE
export const FETCH_AVAILABLE_SERVICE_PROVIDER =
  "maintenance/service-request/get-available-providers" // DONE
export const ASSIGN_SERVICE_PROVIDER =
  "maintenance/service-request/assign-service-provider" // DONE

// RATING FEEDBACK MANAGEMENTS FOR MAINTENANCE USERS
export const GET_ALL_RATINGS_FEEDBACK_M_U =
  "maintenance/rating-and-feedback/all"
export const GET_RATING_FEEDBACK_M_U_DETAILS =
  "maintenance/rating-and-feedback/details"

// MARKETING START
// BANNERS MANAGEMENT
export const GET_ALL_BANNERS = "marketing/banner/all" // DONE
export const CREATE_BANNER = "marketing/banner/add" // DONE
export const UPDATE_BANNER = "marketing/banner/details/update" // DONE
export const GET_BANNER_DETAILS = "marketing/banner/details" // DONE
export const UPDATE_BANNER_STATUS = "marketing/banner/details/update-status" // DONE
export const DELETE_BANNER = "marketing/banner/delete" // DONE

// NEWS MANAGEMENT
export const GET_ALL_NEWS = "marketing/news/all" // DONE
export const CREATE_NEWS = "marketing/news/add" // DONE
export const UPDATE_NEWS = "marketing/news/details/update" // DONE
export const GET_NEWS_DETAILS = "marketing/news/details" // DONE
export const UPDATE_NEWS_STATUS = "marketing/news/details/update-status" // DONE
export const DELETE_NEWS = "marketing/news/delete" // DONE

// ATTRIBUTE MANAGEMENT
export const GET_ALL_ATTRIBUTE = "marketing/attribute/all" // DONE
export const GET_ATTRIBUTE_LISTS = "marketing/attribute/list" // DONE
export const CREATE_ATTRIBUTE = "marketing/attribute/add" // DONE
export const UPDATE_ATTRIBUTE = "marketing/attribute/details/update" // DONE
export const GET_ATTRIBUTE_DETAILS = "marketing/attribute/details" // DONE
export const UPDATE_ATTRIBUTE_STATUS =
  "marketing/attribute/details/update-status" // DONE
export const DELETE_ATTRIBUTE = "marketing/attribute/delete" // DONE

// SERVICE MANAGEMENT
export const GET_ALL_SERVICE = "marketing/service/all" // DONE
export const GET_SERVICES_LIST = "marketing/service/list" // DONE
export const CREATE_SERVICE = "marketing/service/add" // DONE
export const UPDATE_SERVICE = "marketing/service/details/update" // DONE
export const GET_SERVICE_DETAILS = "marketing/service/details" // DONE
export const UPDATE_SERVICE_STATUS = "marketing/service/details/update-status" // DONE
export const DELETE_SERVICE = "marketing/service/delete" // DONE

// SERVICE ITEM MANAGEMENT
export const GET_ALL_SERVICE_ITEM = "marketing/service-item/all" // DONE
export const GET_SERVICE_ITEM_LIST = "marketing/service-item/list" // DONE
export const GET_SERVICE_ITEM_DETAILS = "marketing/service-item/details" // DONE
export const UPDATE_SERVICE_ITEM_STATUS =
  "marketing/service-item/details/update-status" // DONE
export const CREATE_SERVICE_ITEM = "marketing/service-item/add" // DONE
export const UPDATE_SERVICE_ITEM = "marketing/service-item/details/update" // DONE
export const DELETE_SERVICE_ITEM = "marketing/service-item/delete" // DONE

// ROOM TYPE MANAGEMENT
export const GET_ALL_ROOM_TYPE = "marketing/room-type/all" // DONE
export const GET_ROOM_TYPE_DETAILS = "marketing/room-type/details" // DONE
export const GET_ROOM_TYPE_LISTS = "marketing/room-type/list" // DONE
export const CREATE_ROOM_TYPE = "marketing/room-type/add" // DONE
export const UPDATE_ROOM_TYPE = "marketing/room-type/details/update" // DONE
export const UPDATE_ROOM_TYPE_STATUS =
  "marketing/room-type/details/update-status" // DONE
export const DELETE_ROOM_TYPE = "marketing/room-type/delete" // DONE

// PARTNERS MANAGEMENT
export const GET_ALL_PARTNERS = "marketing/partner/all" // DONE
export const GET_PARTNER_DETAILS = "marketing/partner/details" // DONE
export const CREATE_PARTNER = "marketing/partner/add" // DONE
export const UPDATE_PARTNER = "marketing/partner/details/update" // DONE
export const UPDATE_PARTNER_STATUS = "marketing/partner/details/update-status" //DONE
export const DELETE_PARTNER = "marketing/partner/delete" // DONE

// PROJECTS MANAGEMENT
export const GET_ALL_PROJECTS = "marketing/project/all" // DONE
export const GET_PROJECTS_LIST = "marketing/project/list" // DONE
export const GET_PROJECT_DETAILS = "marketing/project/details" // DONE
export const CREATE_PROJECT = "marketing/project/add" // DONE
export const UPDATE_PROJECT = "marketing/project/details/update" // DONE
export const UPDATE_PROJECT_STATUS = "marketing/project/details/update-status" // DONE
export const DELETE_PROJECT = "marketing/project/delete" // DONE
export const UPLOAD_PROJECT_VIDEO = "marketing/project/video-upload" // DONE
export const UPLOAD_PROJECT_GALLERY = "marketing/project/gallery-upload" // DONE

// UNIT MANAGEMENT
export const GET_ALL_UNITS = "marketing/unit/all" // DONE
export const GET_UNITS_LIST = "marketing/unit/list" // DONE
export const GET_UNIT_DETAILS = "marketing/unit/details" // DONE
export const UPDATE_UNIT_STATUS = "marketing/unit/details/update-status" // DONE
export const DELETE_UNIT = "marketing/unit/delete" // DONE
export const CREATE_UNIT = "marketing/unit/add" // DONE
export const UPDATE_UNIT = "marketing/unit/details/update" // DONE
export const UPLOAD_UNIT_GALLERY = "marketing/unit/gallery-upload" // DONE

// CITIES MANAGEMENT
export const GET_ALL_CITIES = "marketing/cities/all" // DONE
export const GET_CITIES_DETAILS = "marketing/cities/details" // DONE
export const GET_CITIES_LIST = "marketing/cities/list"
export const UPDATE_CITY_STATUS = "marketing/cities/details/update-status" // DONE
export const DELETE_CITY = "marketing/cities/delete" // DONE
export const ADD_CITY = "marketing/cities/add" // DONE
export const UPDATE_CITY = "marketing/cities/details/update" // DONE

// NOTIFICATION MANAGEMENT
export const GET_ALL_NOTIFICATIONS = "marketing/push-notification/all"
export const GET_NOTIFICATION_DETAILS = "marketing/push-notification/details"
export const UPDATE_NOTIFICATION_STATUS =
  "marketing/push-notification/details/update-status"
export const UPDATE_NOTIFICATION = "marketing/push-notification/details/update"

export const PUSH_NOTIFICATION_BROADCAST =
  "marketing/push-notification/broadcast"
export const SMS_BROADCAST = "marketing/sms/broadcast"

// MARKETING END

// FINANCE START
export const GET_ALL_BUYING_PAYMENTS = "finance/buying-payment/all" // DONE
export const GET_BUYING_PAYMENT_DETAILS = "finance/buying-payment/details" // DONE
export const HOLD_UN_HOLD_PAYMENT = "finance/buying-payment/hold-payment" // DONE
export const UPLOAD_PAYMENT_CONTRACT =
  "finance/buying-payment/upload-payment-contract" // DONE
export const CANCEL_BOOKING = "finance/buying-payment/cancel-booking" // DONE
export const UPLOAD_DELIVERY_LETTER =
  "finance/buying-payment/upload-delivery-letter" // DONE
export const UPLOAD_DEED_DOCUMENTS =
  "finance/buying-payment/upload-deed-documents" // DONE
export const APPROVE_REJECT_TAX_EXEMPTION =
  "finance/buying-payment/approve-reject-tax-exemption" // DONE
export const APPROVE_REJECT_OWNERSHIP_DETAILS =
  "finance/buying-payment/approve-reject-ownership-details" // DONE
export const APPROVE_EDAAT_PAYMENT =
  "finance/buying-payment/approve-edaat-payment" // DONE
export const UPLOAD_RETT_CERTIFICATE =
  "finance/buying-payment/upload-rett-certificate" //DONE
export const GET_ALL_DEED_RECORDS = "finance/buying-payment/deed-records" //DONE
export const UPLOAD_PAYMENT_RECEIPTS =
  "finance/buying-payment/upload-payment-receipts" //DONE

// FINANCE END

// GLOBAL SETTINGS START
export const GET_GLOBAL_SETTINGS = "common/global-settings/get"
export const UPDATE_GLOBAL_SETTINGS = "common/global-settings/update"

// GLOBAL SETTINGS END

// CUSTOMER SUPPORT START
export const GET_ALL_CUSTOMERS_INTEREST =
  "customer-service/customer-interest/all"
export const GET_CUSTOMER_INTEREST_DETAILS =
  "customer-service/customer-interest/details"

export const GET_ALL_CUSTOMERS = "customer-service/customer/all" // DONE
export const GET_CUSTOMER_DETAILS = "customer-service/customer/details" // DONE
export const GET_CUSTOMERS_LIST = "customer-service/customer/list" // DONE
export const UPDATE_CUSTOMER_STATUS =
  "customer-service/customer/details/update-status" // DONE
export const DELETE_CUSTOMER = "customer-service/customer/delete" // DONE

export const GET_ALL_CUSTOMER_SUPPORT = "customer-service/customer-support/all" // DONE
export const GET_CUSTOMER_SUPPORT_DETAILS =
  "customer-service/customer-support/details"
export const UPDATE_CUSTOMER_SUPPORT_STATUS =
  "customer-service/customer-support/details/update-status" // DONE

export const GET_ALL_TRANSACTIONS = "finance/transaction/all" // DONE
export const GET_TRANSACTION_DETAILS = "finance/transaction/details" // DONE

export const GET_ALL_CUSTOMER_SUPPORT_SUBJECT =
  "customer-service/subject-type/all"
export const GET_CUSTOMER_SUPPORT_SUBJECT_LIST =
  "customer-service/subject-type/list"
export const GET_CUSTOMER_SUPPORT_SUBJECT_DETAILS =
  "customer-service/subject-type/details"
export const UPDATE_CUSTOMER_SUPPORT_SUBJECT_STATUS =
  "customer-service/subject-type/details/update-status"
export const DELETE_CUSTOMER_SUPPORT_SUBJECT =
  "customer-service/subject-type/delete"
export const CREATE_CUSTOMER_SUPPORT_SUBJECT =
  "customer-service/subject-type/add"
export const UPDATE_CUSTOMER_SUPPORT_SUBJECT =
  "customer-service/subject-type/details/update"

// CUSTOMER SUPPORT END

// COMMON START
export const EXPORT_DATA = "common/data/export"
export const DASHBOARD_DATA = "common/dashboard/data"
export const SALES_REPORT = "common/report/sales"
export const PAYMENT_METHOD_REPORT = "common/report/payment-method"

// COMMON END

// ADMIN NOTIFICATION START
export const ADMIN_NOTIFICATION_LIST = "admin/notification/list"
export const ADMIN_NOTIFICATIONS = "admin/notification/all"
export const DELETE_ADMIN_NOTIFICATION = "admin/notification/delete"
export const DELETE_ALL_ADMIN_NOTIFICATIONS = "admin/notification/delete-all"
export const ADMIN_NOTIFICATION_DETAILS = "admin/notification/details"
export const MARK_NOTIFICATION_AS_READ =
  "admin/notification/details/update-status"
export const MARK_ALL_NOTIFICATION_AS_READ =
  "admin/notification/details/update-all-status"
// ADMIN NOTIFICATION END

// CONTENT MANAGEMENT START
export const GET_ALL_FAQS = "common/page-content/all"
export const GET_FAQ_DETAILS = "common/page-content/details"
export const UPDATE_FAQ_STATUS = "common/page-content/details/update-status"
export const DELETE_FAQ = "common/page-content/delete"
export const CREATE_FAQ = "common/page-content/add"
export const UPDATE_FAQ = "common/page-content/details/update"

export const GET_PAGE_CONTENT_BY_TYPE = "common/page-content/get-by-type"
export const UPDATE_PAGE_CONTENT_BY_TYPE = "common/page-content/update-by-type"
// CONTENT MANAGEMENT END
