import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Container, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import { commonFilterOptions } from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Switch from "components/Shared/Switch"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
  handleStopPropagation,
} from "components/Common/Utils"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import TopTitle from "components/Shared/TopTitle"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteUser,
  getAllUsers,
  getRolesList,
  updateUserStatus,
} from "store/actions/usersAction"
import Spinners from "components/Shared/Spinners"
import { showAlert } from "store/reducers/alertReducer"
import { exportData } from "helpers/api_helper"
import { EXPORT_DATA } from "helpers/url_helper"

const Users = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Users | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    users,
    rolesList,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.Users)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    role: [],
  })

  const additionOptions = [
    {
      name: "role",
      type: "multiSelect",
      options: rolesList.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Select Roles"),
    },
  ]

  const filterOptions = [...commonFilterOptions, ...additionOptions]

  useEffect(() => {
    fetchAllUsers()
    fetchRolesList()
  }, [])

  const fetchAllUsers = page => {
    const { status, orderBy, role } = filters
    const updatedRoles = role?.map(item => item.value) || []
    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      role: updatedRoles,
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllUsers(payload))
    setIsApiCalled(true)

    navigate(`/users?page=${page || pageNumber}`)
  }

  const fetchRolesList = () => {
    dispatch(getRolesList())
  }

  // RECALL API ONCHANGE FILTER
  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllUsers(1)
  }

  const onPageChange = page => {
    fetchAllUsers(page)
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 1 ? "0" : "1",
    }
    dispatch(updateUserStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deleteUser(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const getExportFilters = () => {
    const { status, role } = filters
    const updatedRoles = role?.map(item => item.value) || []

    return {
      status: status,
      role: updatedRoles,
      dateRange: getDateRange(dateRange),
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "Admin",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Users"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "Admin",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Users"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Users")}
            subTitle={t(`Hi, {{name}}. Welcome back to your Admin portal!`, {
              name: name,
            })}
            createBtnText={t("Create User")}
            onCreateBtnClick={() => {
              navigate("/users/create")
            }}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Users List")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={4}
                />
                {loading ? (
                  <Spinners />
                ) : users.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Name")}</th>
                          <th>{t("Email")}</th>
                          {/* <th>{t("Phone Number")}</th> */}
                          <th>{t("Role")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => (
                          <tr
                            key={user._id}
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(`/users/details?id=${user._id}`)
                            }}
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{user.name || "-"}</td>
                            <td className="no_transform">
                              {user.email || "-"}
                            </td>
                            {/* <td>{user.phoneNumber || "-"}</td> */}
                            <td>
                              {language === "sa"
                                ? user?.role?.nameAr
                                : user?.role?.name}
                            </td>
                            <td>
                              {user.status === 1 ? (
                                <p className="rended_status status_tag">
                                  {t("Active")}
                                </p>
                              ) : user.status === 0 ? (
                                <p className="reject_status status_tag">
                                  {t("Deactive")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center">
                                <img
                                  src={EditIcon}
                                  alt="Edit Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(`/users/update?id=${user._id}`)
                                  }
                                />
                                <img
                                  src={TrashIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer mx-3"
                                  onClick={() => {
                                    setItemIdToDelete(user._id)
                                    setShowDeleteModal(true)
                                  }}
                                />
                                <Switch
                                  onClickHandler={() => {
                                    handleStatusClick(user._id, user.status)
                                  }}
                                  isChecked={user.status === 1 ? true : false}
                                  disabled={
                                    isLoading && selectedId === user._id
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && users.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("User"),
        })}
      />
    </React.Fragment>
  )
}

export default Users
