import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import DetailsTitle from "components/Shared/DetailsTitle"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import FormateDate from "components/Shared/FormateDate"
import TopTitle from "components/Shared/TopTitle"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getCustomerSupportDetails } from "store/actions/customerSupportAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { customerSupportStatusOptions } from "components/Common/constants"

const CustomerSupportDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Customer Support Details | Takween")
  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const dispatch = useDispatch()
  const { loading, isLoading, customerSupportDetails } = useSelector(
    state => state.CustomerSupport
  )

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getCustomerSupportDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Customer Support")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Customer service Portal!`,
              {
                name: name,
              }
            )}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Customer Support Details")} />
                  <div>
                    {loading ? (
                      <ShimmerThumbnail
                        height={350}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(customerSupportDetails).length === 0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <>
                        <Row className="g_details">
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Id")}
                              value={customerSupportDetails?._id}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Ticket Number")}
                              value={customerSupportDetails.ticketNumber || "-"}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Customer Name")}
                              value={customerSupportDetails.name || "-"}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Customer Email")}
                              value={customerSupportDetails.email || "-"}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Customer Phone")}
                              value={customerSupportDetails.phone || "-"}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Subject")}
                              value={
                                language === "sa"
                                  ? customerSupportDetails?.subject?.titleAr
                                  : customerSupportDetails?.subject?.title
                              }
                            />
                          </Col>

                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Priority")}
                              value={customerSupportDetails.priority || "-"}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Channel")}
                              value={customerSupportDetails.channel || "-"}
                            />
                          </Col>
                          <Col md={12}>
                            <DetailsInnerDiv
                              label={t("Description")}
                              value={customerSupportDetails.description}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Status")}
                              value={
                                customerSupportStatusOptions.find(
                                  item =>
                                    item.value === customerSupportDetails.status
                                )?.label || "-"
                              }
                              valueClass={
                                customerSupportStatusOptions.find(
                                  item =>
                                    item.value === customerSupportDetails.status
                                )?.textColor
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Date Opened")}
                              value={FormateDate(
                                customerSupportDetails.createdAt
                              )}
                            />
                          </Col>
                          <Col md={3}>
                            <DetailsInnerDiv
                              label={t("Last Updated")}
                              value={FormateDate(
                                customerSupportDetails.updatedAt
                              )}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <ShimmerThumbnail height={250} rounded className={"mb-0"} />
                  ) : customerSupportDetails?.statusHistory?.length === 0 ? (
                    <NoDataAvailable />
                  ) : (
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Description")}</th>
                          <th>{t("Date")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerSupportDetails?.statusHistory?.map(
                          (item, index) => (
                            <tr key={item._id}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                <p
                                  className={`${
                                    customerSupportStatusOptions.find(
                                      option => option.value === item.status
                                    )?.className || "-"
                                  } status_tag`}
                                >
                                  {t(
                                    customerSupportStatusOptions.find(
                                      option => option.value === item.status
                                    )?.label || "-"
                                  )}
                                </p>
                              </td>
                              <td>{item.description || "-"}</td>
                              <td>{FormateDate(item?.date)}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomerSupportDetails
