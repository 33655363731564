import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  del,
  formDataPost,
  formDataPut,
  get,
  patch,
  post,
  put,
} from "helpers/api_helper"
import {
  CREATE_NEWS,
  DELETE_NEWS,
  GET_ALL_NEWS,
  GET_NEWS_DETAILS,
  UPDATE_NEWS,
  UPDATE_NEWS_STATUS,
} from "helpers/url_helper"

export const getAllNews = createAsyncThunk(GET_ALL_NEWS, async payload => {
  const response = await post(GET_ALL_NEWS, payload)
  return response
})

export const updateNewsStatus = createAsyncThunk(
  UPDATE_NEWS_STATUS,
  async payload => {
    const response = await patch(UPDATE_NEWS_STATUS, payload)
    return response
  }
)

export const deleteNews = createAsyncThunk(DELETE_NEWS, async payload => {
  const response = await del(DELETE_NEWS, payload)
  return response
})

export const getNewsDetails = createAsyncThunk(
  GET_NEWS_DETAILS,
  async payload => {
    const response = await post(GET_NEWS_DETAILS, payload)
    return response
  }
)

export const createNews = createAsyncThunk(CREATE_NEWS, async payload => {
  const response = await formDataPost(CREATE_NEWS, payload)
  return response
})

export const updateNews = createAsyncThunk(UPDATE_NEWS, async payload => {
  const response = await formDataPut(UPDATE_NEWS, payload)
  return response
})
