import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import Title from "components/Shared/Title"
import useFilters from "components/Shared/useFilters"
import { useLocation, useNavigate } from "react-router-dom"
import Filters from "components/Shared/Filters"
import {
  baseFilterOptions,
  paymentReportGroupByOptions,
  paymentReportPaymentTypeOptions,
  paymentReportTransTypeOptions,
} from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import FormateDate from "components/Shared/FormateDate"
import Paginations from "components/Common/Pagination"
import { useDispatch, useSelector } from "react-redux"
import { getUnitsList } from "store/actions/unitsAction"
import { getProjectsList } from "store/actions/projectsAction"
import { getCustomersList } from "store/actions/customersAction"
import Spinners from "components/Shared/Spinners"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
} from "components/Common/Utils"
import { getPaymentMethodReport } from "store/actions/commonAction"
import { exportData } from "helpers/api_helper"
import { EXPORT_DATA } from "helpers/url_helper"

const PaymentReports = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Payment Reports | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    paymentMethod,
    currentPage,
    totalPages,
    totalCount,
  } = useSelector(state => state.Common)

  const { projectsList } = useSelector(state => state.Projects)
  const { unitsList } = useSelector(state => state.Units)
  const { customersList } = useSelector(state => state.Customers)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    groupBy: "",
    transType: [],
    paymentType: [],
    customer: [],
    project: [],
    unit: [],
  })

  const filterOptions = [
    {
      name: "groupBy",
      type: "select",
      options: paymentReportGroupByOptions.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Select Group By"),
    },
    {
      name: "transType",
      type: "multiSelect",
      options: paymentReportTransTypeOptions.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Select Transaction Type"),
    },
    {
      name: "paymentType",
      type: "multiSelect",
      options: paymentReportPaymentTypeOptions.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Select Payment Type"),
    },
    {
      name: "project",
      type: "multiSelect",
      options: projectsList.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Select Projects"),
    },
    {
      name: "unit",
      type: "multiSelect",
      options: unitsList.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Select Units"),
    },
    {
      name: "customer",
      type: "multiSelect",
      options: customersList.map(item => ({
        label: `${item.name || ""} ${item.phone || ""}`,
        value: item._id,
      })),
      placeholder: t("Type Customers name"),
    },
  ]

  useEffect(() => {
    fetchPaymentMethodReports()
    fetchProjectsList()
  }, [])

  const fetchPaymentMethodReports = page => {
    const { groupBy, transType, paymentType, customer, project, unit } = filters
    const updatedProjects = project?.map(item => item.value) || []
    const updatedUnits = unit?.map(item => item.value) || []
    const updatedCustomers = customer?.map(item => item.value) || []
    const updatedTransType = transType?.map(item => item.value) || []
    const updatedPaymentType = paymentType?.map(item => item.value) || []

    const payload = {
      groupBy: groupBy, // "", project,unit, user
      pageNo: page || pageNumber,
      perPage: 10,
      filter: {
        transType: updatedTransType, //1:buying, 2 renting
        paymentType: updatedPaymentType, //1:downPayment, 2 fullPayment, 3 taxPayment 4: rentPayment
        customer: updatedCustomers,
        project: updatedProjects,
        unit: updatedUnits,
        dateRange: getDateRange(dateRange),
      },
    }

    dispatch(getPaymentMethodReport(payload))
    setIsApiCalled(true)

    navigate(`/payment-reports?page=${page || pageNumber}`)
  }

  const fetchProjectsList = () => {
    const queryParams = "?status=-1"
    dispatch(getProjectsList(queryParams))
  }

  useEffect(() => {
    fetchUnitsList()
  }, [filters.project])

  const fetchUnitsList = () => {
    const { project } = filters
    const updatedProjects = project?.map(item => item.value) || []

    const payload = {
      project: updatedProjects,
      statusType: [1, 2, 3, 4],
    }
    dispatch(getUnitsList(payload))
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchPaymentMethodReports(1)
  }

  const onPageChange = page => {
    fetchPaymentMethodReports(page)
  }

  const commonColumns = [
    { header: t("Payment Method"), accessor: "_id.paymentMethod" },
    {
      header: t("Total Down Payment Count"),
      accessor: "totalDownPaymentCount",
    },
    {
      header: t("Total Down Payment Amount"),
      accessor: "totalDownPaymentAmount",
    },
    { header: t("Total Full Payment Count"), accessor: "totaFullPaymentCount" },
    {
      header: t("Total Full Payment Amount"),
      accessor: "totalFullPaymentAmount",
    },
    { header: t("Total Tax Payment Count"), accessor: "totaTaxPaymentCount" },
    {
      header: t("Total Tax Payment Amount"),
      accessor: "totalTaxPaymentAmount",
    },
    { header: t("Total Buying Count"), accessor: "totaBuyingCount" },
    { header: t("Total Buying Amount"), accessor: "totalBuyingAmount" },
    { header: t("Total Renting Count"), accessor: "totaRentingCount" },
    { header: t("Total Renting Amount"), accessor: "totalRentingAmount" },
  ]

  const columns = [
    ...(filters.groupBy === ""
      ? [{ header: "#", accessor: "serialNo" }]
      : filters.groupBy === "project"
      ? [
          { header: "#", accessor: "serialNo" },
          { header: t("Project Name"), accessor: "projectName" },
        ]
      : filters.groupBy === "unit"
      ? [
          { header: "#", accessor: "serialNo" },
          { header: t("Unit Name"), accessor: "unitName" },
        ]
      : filters.groupBy === "user"
      ? [
          { header: "#", accessor: "serialNo" },
          { header: t("User Name"), accessor: "userName" },
          { header: t("User Phone Number"), accessor: "userPhone" },
        ]
      : []),
    ...commonColumns,
  ]

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj)
  }

  const renderCell = (item, accessor, index) => {
    if (accessor === "serialNo") {
      return index + 1
    }

    const value = getNestedValue(item, accessor)

    if (
      accessor === "totalDownPaymentAmount" ||
      accessor === "totalFullPaymentAmount" ||
      accessor === "totalTaxPaymentAmount" ||
      accessor === "totalBuyingAmount" ||
      accessor === "totalRentingAmount"
    ) {
      return `${item[accessor]?.toFixed(2)} SAR`
    }

    return value ?? ""
  }

  const onInputChange = value => {
    const payload = {
      keyword: value,
    }
    dispatch(getCustomersList(payload))
  }

  const getExportFilters = () => {
    const { groupBy, transType, paymentType, customer, project, unit } = filters
    const updatedProjects = project?.map(item => item.value) || []
    const updatedUnits = unit?.map(item => item.value) || []
    const updatedCustomers = customer?.map(item => item.value) || []
    const updatedTransType = transType?.map(item => item.value) || []
    const updatedPaymentType = paymentType?.map(item => item.value) || []

    return {
      groupBy,
      filter: {
        transType: updatedTransType,
        paymentType: updatedPaymentType,
        customer: updatedCustomers,
        project: updatedProjects,
        unit: updatedUnits,
        dateRange: getDateRange(dateRange),
      },
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "PaymentMethodReport",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Payment Reports"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "PaymentMethodReport",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Payment Reports"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Payment Reports")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={false}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={3}
                  onInputChange={onInputChange}
                />
                {loading ? (
                  <div className="mt-5">
                    <Spinners />
                  </div>
                ) : paymentMethod.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="mb-0 full_th">
                      <thead>
                        <tr>
                          {columns.map(column => (
                            <th key={column.accessor}>{column.header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {paymentMethod.map((payment, index) => (
                          <tr key={payment._id}>
                            {columns.map(column => (
                              <td key={column.accessor}>
                                {renderCell(payment, column.accessor, index)}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && paymentMethod?.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}{" "}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaymentReports
