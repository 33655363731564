import React from "react"

const CustomLabel = ({ htmlFor = "", label = "", isRequired = null }) => {
  return (
    <label htmlFor="name">
      {label}
      {isRequired && <span className="text-danger px-1">*</span>}
    </label>
  )
}

export default CustomLabel
