import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  CardBody,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { useTranslation } from "react-i18next"

const Title = ({
  title = "",
  isAddBtn,
  addBtnText,
  onAddBtnClick = () => {},
  handleDownloadCSV = () => {},
  handleDownloadXLSX = () => {},
  isExport = false,
  isDeleteBtn = false,
  deleteButtonText = "",
  handleDeleteClick = () => {},
  isLoading = false,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  return (
    <CardBody className="pb-1">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="card_title">{title}</h2>
        <div className="d-flex align-items-center gap-2">
          {isAddBtn && (
            <button
              onClick={onAddBtnClick}
              className="primary_btn"
              disabled={isLoading}
            >
              {addBtnText}
            </button>
          )}
          {isDeleteBtn && (
            <button
              onClick={handleDeleteClick}
              className="primary_btn danger_btn"
            >
              {deleteButtonText}
            </button>
          )}
          {isExport && (
            <UncontrolledDropdown className="dropdown d-inline-block me-1 mb-2">
              <DropdownToggle className="export_btn g_center">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu>
                <li>
                  <DropdownItem onClick={handleDownloadCSV}>
                    {t("Export CSV")}
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem onClick={handleDownloadXLSX}>
                    {t("Export XLSX")}
                  </DropdownItem>
                </li>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>
      </div>
    </CardBody>
  )
}

export default Title

Title.propTypes = {
  title: PropTypes.string,
  isAddBtn: PropTypes.bool,
  addBtnText: PropTypes.string,
  onAddBtnClick: PropTypes.func,
}
