import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router"
import useFilters from "components/Shared/useFilters"
import { Card, Col, Container, Row, Table } from "reactstrap"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import {
  ascDescOptions,
  paymentMethods,
  paymentStatusOptions,
  paymentTypes,
  transactionsTypes,
} from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Paginations from "components/Common/Pagination"
import FormateDate from "components/Shared/FormateDate"
import { useDispatch, useSelector } from "react-redux"
import { getDateRange } from "components/Common/Utils"
import { getAllTransactions } from "store/actions/transactionsAction"
import Spinners from "components/Shared/Spinners"
import { getUnitsList } from "store/actions/unitsAction"

const PaymentHistory = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Payment History | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    transactions,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.Transactions)

  const { unitsList } = useSelector(state => state.Units)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    unit: [],
    paymentType: [],
    paymentMethod: [],
    transType: [],
  })

  useEffect(() => {
    fetchAllTransactions()
    fetchUnitsList()
  }, [])

  const fetchAllTransactions = page => {
    const { status, orderBy, unit, paymentType, paymentMethod, transType } =
      filters

    const updatedUnit = unit?.map(item => item.value) || []
    const updatedPaymentType = paymentType?.map(item => item.value) || []
    const updatedPaymentMethod = paymentMethod?.map(item => item.value) || []
    const updatedTransType = transType?.map(item => item.value) || []

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      keyword,
      dateRange: getDateRange(dateRange),
      unit: updatedUnit,
      customer: [],
      paymentMethod: updatedPaymentMethod, //"sadad", "applepay", "cheque", "bank"
      paymentType: updatedPaymentType, //1: Down payment,2:Full payment,3: Tax and 4: Installment for rent
      transType: updatedTransType, //1:Buying and 2: Renting
    }

    dispatch(getAllTransactions(payload))
    setIsApiCalled(true)

    navigate(`/payment-history?page=${page || pageNumber}`)
  }

  const fetchUnitsList = () => {
    const payload = {
      project: [],
      statusType: [1, 2, 3, 4],
    }
    dispatch(getUnitsList(payload))
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllTransactions(1)
  }

  const onPageChange = page => {
    fetchAllTransactions(page)
  }

  const additionOptions = [
    {
      name: "transType",
      type: "multiSelect",
      options: transactionsTypes?.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Transaction Type"),
    },
    {
      name: "unit",
      type: "multiSelect",
      options: unitsList?.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Select Units"),
    },
    {
      name: "paymentType",
      type: "multiSelect",
      options: paymentTypes?.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Payment Type"),
    },
    {
      name: "paymentMethod",
      type: "multiSelect",
      options: paymentMethods?.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Payment Method"),
    },
  ]

  const filterOptions = [...ascDescOptions, ...additionOptions]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Payment History List")} isExport={true} />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={3}
                />

                {loading ? (
                  <div className="pt-5">
                    <Spinners />
                  </div>
                ) : transactions.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Customer Name")}</th>
                          <th>{t("Customer Phone")}</th>
                          <th>{t("Unit Name")}</th>
                          <th>{t("Transaction Id")}</th>
                          <th>{t("Amount")}</th>
                          <th>{t("Payment Method")}</th>
                          <th>{t("Transaction Type")}</th>
                          <th>{t("Payment Type")}</th>
                          <th>{t("Payment Status")}</th>
                          <th>{t("Created At")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((payment, index) => (
                          <tr
                            key={payment._id}
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(
                                `/payment-history/details?id=${payment._id}`
                              )
                            }}
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{payment?.userData?.name || "-"}</td>
                            <td>{payment?.userData?.phone || "-"}</td>
                            <td>
                              {language === "sa"
                                ? payment?.unitData?.nameAr
                                : payment?.unitData?.name || "-"}
                            </td>
                            <td>{payment.transactionId}</td>
                            <td>{`${payment?.amount ?? "-"} ${
                              payment?.currency
                            }`}</td>
                            <td className="text-capitalize">
                              {t(payment.paymentMethod)}
                            </td>
                            <td>
                              {t(
                                transactionsTypes.find(
                                  item => item.value === payment.transType
                                )?.label
                              ) || "-"}
                            </td>
                            <td className="text-capitalize">
                              {t(
                                paymentTypes.find(
                                  item => item.value === payment.paymentType
                                )?.label
                              )}
                            </td>
                            <td>
                              <p
                                className={`${
                                  payment.paymentStatus === 0
                                    ? "sold_status"
                                    : payment.paymentStatus === 1
                                    ? "1"
                                    : payment.paymentStatus === 2
                                    ? "reject_status"
                                    : ""
                                } status_tag`}
                              >
                                {t(
                                  paymentStatusOptions.find(
                                    item => item.value === payment.paymentStatus
                                  )?.label
                                ) || "-"}
                              </p>
                            </td>
                            <td>{FormateDate(payment.createdAt)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && transactions.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaymentHistory
