import { createSlice } from "@reduxjs/toolkit"
import {
  createCustomerSupportSubject,
  deleteCustomerSupportSubject,
  getAllCustomerSupport,
  getAllCustomerSupportSubject,
  getCustomerSupportDetails,
  getCustomerSupportSubjectDetails,
  getCustomerSupportSubjectList,
  updateCustomerSupportStatus,
  updateCustomerSupportSubject,
  updateCustomerSupportSubjectStatus,
} from "store/actions/customerSupportAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  customerSupport: [],
  customerSupportDetails: {},
  customerSupportSubject: [],
  customerSupportSubjectList: [],
  customerSupportSubjectDetails: {},
}
const customerSupport = createSlice({
  name: "customer support",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllCustomerSupport.pending, state => {
        state.loading = true
        state.totalCount = null
        state.totalPages = null
        state.currentPage = null
      })
      .addCase(getAllCustomerSupport.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.customerSupport = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllCustomerSupport.rejected, (state, action) => {
        state.loading = false
      })

    // GET DETAILS
    builder
      .addCase(getCustomerSupportDetails.pending, state => {
        state.loading = true
        state.customerSupportDetails = {}
      })
      .addCase(getCustomerSupportDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.customerSupportDetails = data
        }
      })
      .addCase(getCustomerSupportDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateCustomerSupportStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateCustomerSupportStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.customerSupport = state.customerSupport.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateCustomerSupportStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // CUSTOMER SUPPORT SUBJECT
    // GET ALL
    builder
      .addCase(getAllCustomerSupportSubject.pending, state => {
        state.loading = true
        state.totalCount = null
        state.totalPages = null
        state.currentPage = null
      })
      .addCase(getAllCustomerSupportSubject.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.customerSupportSubject = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllCustomerSupportSubject.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getCustomerSupportSubjectList.pending, state => {
        state.customerSupportSubjectList = []
      })
      .addCase(getCustomerSupportSubjectList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.customerSupportSubjectList = data
        }
      })
      .addCase(getCustomerSupportSubjectList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getCustomerSupportSubjectDetails.pending, state => {
        state.loading = true
        state.customerSupportSubjectDetails = {}
      })
      .addCase(getCustomerSupportSubjectDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.customerSupportSubjectDetails = data
        }
      })
      .addCase(getCustomerSupportSubjectDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateCustomerSupportSubjectStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(
        updateCustomerSupportSubjectStatus.fulfilled,
        (state, action) => {
          state.isLoading = false
          const { statusCode, success } = action.payload
          if (statusCode === 200 && success) {
            const { id, status } = action.meta.arg
            state.customerSupportSubject = state.customerSupportSubject.map(
              item =>
                item._id === id ? { ...item, status: Number(status) } : item
            )
          }
        }
      )
      .addCase(updateCustomerSupportSubjectStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteCustomerSupportSubject.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteCustomerSupportSubject.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.customerSupportSubject = state.customerSupportSubject.filter(
            item => item._id !== id
          )
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteCustomerSupportSubject.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createCustomerSupportSubject.pending, state => {
        state.isLoading = true
      })
      .addCase(createCustomerSupportSubject.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createCustomerSupportSubject.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateCustomerSupportSubject.pending, state => {
        state.isLoading = true
      })
      .addCase(updateCustomerSupportSubject.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateCustomerSupportSubject.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default customerSupport.reducer
