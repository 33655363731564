import { createSlice } from "@reduxjs/toolkit"
import {
  getDashboardData,
  getPaymentMethodReport,
  getSalesReport,
} from "store/actions/commonAction"

const initialState = {
  loading: true,
  isLoading: false,
  dashboardData: {},
  salesReport: [],
  paymentMethod: [],
  currentPage: null,
  totalCount: null,
  totalPages: null,
}
const commonSlice = createSlice({
  name: "Common",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getDashboardData.pending, state => {
        state.loading = true
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.dashboardData = data
        }
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.loading = false
      })

    // GET SALES REPORTS
    builder
      .addCase(getSalesReport.pending, state => {
        state.loading = true
        state.currentPage = null
        state.totalCount = null
        state.totalPages = null
      })
      .addCase(getSalesReport.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, currentPage, totalCount, totalPages } = action.payload
          state.salesReport = data
          state.currentPage = currentPage
          state.totalCount = totalCount
          state.totalPages = totalPages
        }
      })
      .addCase(getSalesReport.rejected, (state, action) => {
        state.loading = false
      })

    // GET PAYMENT METHOD REPORTS
    builder
      .addCase(getPaymentMethodReport.pending, state => {
        state.loading = true
        state.currentPage = null
        state.totalCount = null
        state.totalPages = null
      })
      .addCase(getPaymentMethodReport.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, currentPage, totalCount, totalPages } = action.payload
          state.paymentMethod = data
          state.currentPage = currentPage
          state.totalCount = totalCount
          state.totalPages = totalPages
        }
      })
      .addCase(getPaymentMethodReport.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export default commonSlice.reducer
