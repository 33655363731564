import React from "react"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { Card, CardBody, Col, Row } from "reactstrap"

const ShimmerEffect = ({ data = [0], height = 450 }) => {
  return (
    <Row>
      {data?.map((item, index) => (
        <Col lg={6} key={index}>
          <Card>
            <CardBody>
              <ShimmerThumbnail height={450} rounded className={"mb-0"} />
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default ShimmerEffect
