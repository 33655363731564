import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, patch, post, put } from "helpers/api_helper"
import {
  GET_ALL_NOTIFICATIONS,
  GET_NOTIFICATION_DETAILS,
  PUSH_NOTIFICATION_BROADCAST,
  SMS_BROADCAST,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_STATUS,
} from "helpers/url_helper"

export const getAllNotifications = createAsyncThunk(
  GET_ALL_NOTIFICATIONS,
  async payload => {
    const response = await post(GET_ALL_NOTIFICATIONS, payload)
    return response
  }
)

export const updateNotificationStatus = createAsyncThunk(
  UPDATE_NOTIFICATION_STATUS,
  async payload => {
    const response = await patch(UPDATE_NOTIFICATION_STATUS, payload)
    return response
  }
)

export const getNotificationDetails = createAsyncThunk(
  GET_NOTIFICATION_DETAILS,
  async payload => {
    const response = await post(GET_NOTIFICATION_DETAILS, payload)
    return response
  }
)

export const updateNotification = createAsyncThunk(
  UPDATE_NOTIFICATION,
  async payload => {
    const response = await put(UPDATE_NOTIFICATION, payload)
    return response
  }
)

export const pushNotificationBroadCast = createAsyncThunk(
  PUSH_NOTIFICATION_BROADCAST,
  async payload => {
    const response = await post(PUSH_NOTIFICATION_BROADCAST, payload)
    return response
  }
)

export const SMSBroadCast = createAsyncThunk(SMS_BROADCAST, async payload => {
  const response = await post(SMS_BROADCAST, payload)
  return response
})
