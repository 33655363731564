import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { useTranslation, withTranslation } from "react-i18next"
// Redux
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [menu, setMenu] = useState(false)

  const name = useSelector(state => state.Login.userProfile?.name) || ""
  const { userProfile } = useSelector(state => state.Login) || ""

  const initials = name
    .split(" ")
    .filter(word => word)
    .map(word => word[0])
    .slice(0, 2)
    .join("")
    .toUpperCase()

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block header_links"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="profile_img rounded-circle g_center">
            {initials}
          </span>
          {/* <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span> */}
          <i
            className={`mdi ${
              menu ? "mdi-chevron-up" : "mdi-chevron-down"
            } fs-2 color-primary`}
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            {t("My Profile")}{" "}
          </Link>
          <Link to="/update-profile" className="dropdown-item">
            {t("Update Profile")}{" "}
          </Link>
          <Link to="/change-password" className="dropdown-item">
            {t("Change Password")}{" "}
          </Link>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileMenu
