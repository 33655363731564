import React from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import FormateDate from "components/Shared/FormateDate"
import { Link } from "react-router-dom"
import DetailsTitle from "components/Shared/DetailsTitle"

const SalesHistoryDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Sales History Details | Takween")

  const paymentHistoryDetails = {
    _id: 1,
    amount: 1500.0,
    unitId: "A101",
    projectName: "Sunrise Apartments",
    customerName: "John Doe",
    status: 1,
    paymentMethod: "Saddad",
    transactionDate: "2023-08-15T14:30:00Z",
    invoiceNumber: "INV-2023-1234",
    paymentReference: "STCP123456789",
    customerPhone: "+966 51 234 5678",
    customerEmail: "john.doe@example.com",
    taxAmount: 225.0, // 15% VAT
    totalAmount: 1725.0, // including tax
    attachments: {
      fileName: "receipt.pdf",
      fileUrl:
        "https://www.smartsheet.com/sites/default/files/IC-Basic-Receipt-Template.png",
    },
    createdAt: "2023-08-15T14:30:00Z",
    updatedAt: "2023-08-15T14:30:00Z",
  }
  return (
    <React.Fragment>
      <div className="page-content details_view">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Sales History Details")} />
                  <div className="table-responsive">
                    {Object.values(paymentHistoryDetails).length === 0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <Table>
                        <tbody>
                          <tr>
                            <th scope="row">{t("Id")}</th>
                            <td>1</td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Project Name")}</th>
                            <td>{paymentHistoryDetails.projectName}</td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Unit Number")}</th>
                            <td>{paymentHistoryDetails.unitId}</td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Amount")}</th>
                            <td>{paymentHistoryDetails.amount}</td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Customer Name")}</th>
                            <td>{paymentHistoryDetails.customerName}</td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Customer Phone")}</th>
                            <td>{paymentHistoryDetails.customerPhone}</td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Customer Email")}</th>
                            <td>{paymentHistoryDetails.customerEmail}</td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Payment Status")}</th>
                            <td>
                              <p
                                className={`${
                                  paymentHistoryDetails.status === 0
                                    ? "text-danger"
                                    : paymentHistoryDetails.status === 1
                                    ? "text-success"
                                    : paymentHistoryDetails.status === 2
                                    ? "text-warning"
                                    : ""
                                }`}
                              >
                                {paymentHistoryDetails.status === 0
                                  ? "Failed"
                                  : paymentHistoryDetails.status === 1
                                  ? "Completed"
                                  : paymentHistoryDetails.status === 2
                                  ? "Pending"
                                  : ""}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Project Status")}</th>
                            <td>Sold</td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Payment Method")}</th>
                            <td>{paymentHistoryDetails.paymentMethod}</td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Transaction Date")}</th>
                            <td>
                              {FormateDate(
                                paymentHistoryDetails.transactionDate
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Invoice Number")}</th>
                            <td>{paymentHistoryDetails.invoiceNumber}</td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Payment Reference")}</th>
                            <td>{paymentHistoryDetails.paymentReference}</td>
                          </tr>

                          {paymentHistoryDetails?.attachments?.fileUrl && (
                            <tr>
                              <th scope="row">{t("Attachments")}</th>
                              <td>
                                <Link
                                  to={
                                    paymentHistoryDetails?.attachments?.fileUrl
                                  }
                                  target="_blank"
                                  className="text-decoration-underline"
                                >
                                  {t("View")}
                                </Link>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th scope="row">{t("Created At")}</th>
                            <td>
                              {FormateDate(paymentHistoryDetails.createdAt)}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">{t("Updated At")}</th>
                            <td>
                              {FormateDate(paymentHistoryDetails.updatedAt)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SalesHistoryDetails
